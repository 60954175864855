import React, { useEffect } from 'react';
import Table from '../../../modules/components/Table'
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddEditCreditNote from './AddEditCreditNote';
import firebase from "firebase";
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentStaff } from '../../../redux/staff/staff.selector'

function Index(props) {

    const [headCells, setHeadCells] = React.useState([]);
    const [headMobileCells, setHeadMobileCells] = React.useState([]);
    const [searchValue, setSearchValue] = React.useState("");
    const [searchCell, setSearchCell] = React.useState([]);
    const [displayInfo, setDisplayInfo] = React.useState([]);
    const [editCells, setEditCells] = React.useState([]);
    const [addCells, setAddCells] = React.useState([]);
    const [addCellsName, setAddCellsName] = React.useState([]);
    const matches = useMediaQuery(theme => theme.breakpoints.up('sm'));

    useEffect(() => {

        if(props.currentStaff && props.currentStaff.role === "cashier")
        {
            setHeadCells([
                { id: 'uuid', label: 'Tracking Number' },
                { id: 'created', label: 'Created Date' },
                { id: 'credit_note_type', label: 'Type' },
                { id: 'credit_note_amount', label: 'Amount' },
                { id: 'description', label: 'Description' }
            ])
        }
        else{
            setHeadCells([
                { id: 'uuid', label: 'Tracking Number' },
                { id: 'created', label: 'Created Date' },
                { id: 'credit_note_type', label: 'Type' },
                { id: 'credit_note_amount', label: 'Amount' },
                { id: 'credit_note_amount_cost', label: 'Cost Amount' },
                { id: 'description', label: 'Description' }
            ])
        }
        

        setHeadMobileCells([
            { id: 'uuid', label: 'Tracking Number' },
        ])
    
        setSearchCell([
            { id: 'uuid', label: 'Tracking Number' },
        ])

        if(props.currentStaff && props.currentStaff.role === "cashier")
        {
            setDisplayInfo([
                { id: 'uuid', label: 'Tracking Number' },
                { id: 'created', label: 'Created Date' },
                { id: 'credit_note_type', label: 'Type' },
                { id: 'credit_note_amount', label: 'Amount' },
                { id: 'description', label: 'Description' },
                { id: 'products', label: 'Products' },
                { id: 'orders_uuid', label: 'Order Tracking Number' }
            ])
        }
        else
        {
            setDisplayInfo([
                { id: 'uuid', label: 'Tracking Number' },
                { id: 'created', label: 'Created Date' },
                { id: 'credit_note_type', label: 'Type' },
                { id: 'credit_note_amount', label: 'Amount' },
                { id: 'credit_note_amount_cost', label: 'Cost Amount' },
                { id: 'description', label: 'Description' },
                { id: 'products', label: 'Products' },
                { id: 'orders_uuid', label: 'Order Tracking Number' }
            ])
        }
       

        setEditCells([
            { id: 'orders', label: 'Search Orders by Tracking Number' },
            { id: 'product', label: 'Products' },
            { id: 'credit_note_type', label: 'Type' },
            { id: 'credit_note_amount', label: 'Amount' },
            { id: 'description', label: 'Description' }
        ])

        setAddCells([
            { id: 'orders', label: 'Search Orders by Tracking Number' },
            { id: 'product', label: 'Products' },
            { id: 'credit_note_type', label: 'Type' },
            { id: 'credit_note_amount', label: 'Amount' },
            { id: 'description', label: 'Description' }
        ])

        setAddCellsName({ 
            created: firebase.database.ServerValue.TIMESTAMP,
            updated: firebase.database.ServerValue.TIMESTAMP,
         })
    
        setSearchValue("uuid")
        
    }, []);

    return (
    <Grid>
        <Table 
            {...props}
            customizeDialog={AddEditCreditNote}
            control={true}
            orderBy="created" 
            data={"pos/" + firebase.auth().currentUser.displayName + "/credit_note"}
            addItemButton={true}
            orderReceiptButton={false}
            addItemText="Add Credit Note"
            addItemIcon={<AddCircleIcon/>}
            addCells={addCells}
            addCellsName={addCellsName}
            folder_data={"pos/" + firebase.auth().currentUser.displayName + "/credit_note_zone"}
            displayInfo={displayInfo}
            editCells={editCells}
            matches={matches}
            headCells={matches ? headCells : headMobileCells} 
            searchValue={searchValue} 
            searchCell={searchCell}/>
    </Grid>
    )

}

const mapStateToProps = createStructuredSelector({
    currentStaff: selectCurrentStaff,
})

export default connect(
    mapStateToProps
)(Index);