import React, { useEffect }  from "react";
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import firebase from "firebase";
import { useHistory } from 'react-router-dom';
import validator from 'validator';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://sp2smalaysia.com/">
      Sp2s Malaysia
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const styles = theme => ({
  root: {
    display:"flex",
    justifyContent: "center",
    padding: 20
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }
});

function SignIn(props) {

  const classes = props.classes;

  const [password, setPassword] = React.useState("");
  const [password2, setPassword2] = React.useState("");
  const [isPassword, setIsPassword] = React.useState(false);
  const [isEmail, setIsEmail] = React.useState(false);
  const [email, setEmail] = React.useState("");

  const [currentUser, setCurrentUser] = React.useState(null);
  const history = useHistory();

  useEffect(() => {

    setCurrentUser(props.currentUser)

  }, [props]);

  const handlePassword = (e) => {
    setPassword(e.target.value)
  }

  const handlePassword2 = (e) => {
    setPassword2(e.target.value)
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value)
  }

  const handleSubmit = () => {
    if(password.length > 7 && password2.length > 7 && password === password2 && validator.isEmail(email)){
      setIsPassword(false)
      setIsEmail(false)

      currentUser.password = password;
      currentUser.email = email;
      currentUser.created = firebase.database.ServerValue.TIMESTAMP;
      currentUser.role = "admin"

      firebase.database().ref("pos/" + currentUser.id + "/users/" + currentUser.id).update(currentUser).then(()=> {
        window.location.href = "/login_cashier";
      })
    }
    else{
      setIsPassword(true)

      if(validator.isEmail(email))
      {
        setIsEmail(false)
      }
      else
      {
        setIsEmail(true)
      }
      
    }
  }

  return (
    <Grid container component="main" className={classes.root}>
      <Grid item component={Paper} elevation={0} style={{ width: 400, marginTop: 50, backgroundColor: "#1c1c1c" }}>
        <Grid style={{ display: 'flex', justifyContent: "space-between", height: 120, backgroundColor: "#212121", borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>
          
          <Grid>
            <Typography color="primary" variant='body1' style={{  marginLeft: 30, marginTop: 40, fontWeight: "bold"}}>
              POS
            </Typography>
            <Typography color="primary" variant='body1' style={{  marginLeft: 30, fontWeight: "bold"}}>
              Admin Registration
            </Typography>
          </Grid>
          
          <img height="100%" src="assets/login/profile-img.png" alt="top-login-bg" />
        </Grid>
        <div className={classes.paper}>
          
        <form className={classes.form}>

              <Typography variant='body2' style={{ marginTop: 20}}>
                Email
              </Typography>
              <TextField
                error={isEmail}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                name="email"
                autoComplete="off"
                size="small"
                style={{ marginTop: 5}}
                value={email}
                onChange={handleEmailChange}
              />

              <Typography variant='body2' style={{ marginTop: 20}}>
                Password*
              </Typography>
              <TextField
              error={isPassword}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                type={"password"}
                autoComplete='off'
                id="password"
                name="password"
                size="small"
                style={{ marginTop: 5}}
                value={password}
                onChange={handlePassword}
              />

              <Typography variant='body2' style={{ marginTop: 10}}>
                Confirm Password*
              </Typography>
              <TextField
              error={isPassword}
                variant="outlined"
                margin="normal"
                type={"password"}
                required
                fullWidth
                autoComplete='off'
                id="password"
                name="password"
                size="small"
                style={{ marginTop: 5}}
                value={password2}
                onChange={handlePassword2}
              />

              <Button
                style={{textTransform: 'none', width: "100%", marginTop: 20 }}
                fullWidth
                color="primary"
                variant="contained"
                disableElevation
                onClick={()=> handleSubmit()}
              >
              Register
              </Button>

              <Button
                style={{textTransform: 'none', width: "100%", marginTop: 20 }}
                fullWidth
                color="primary"
                variant="contained"
                disableElevation
                onClick={()=> firebase.auth().signOut()}
              >
              Sign Out
              </Button>

              <Grid style={{ display: 'flex', justifyContent: "center", marginTop: 20 }}>
              <img height="80" src="assets/logo/logo-white.png" alt="Sp2s logo"/>
              </Grid>
              
              
              <Grid style={{ display: 'flex', justifyContent: "center", marginTop: 20 }}>
                <Copyright />
              </Grid>
          </form>
        </div>
      </Grid>
    </Grid>);
}

SignIn.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SignIn);