import React, { useEffect } from 'react';
import Table from '../../../modules/components/Table'
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddEditWarranty from './AddEditWarranty';
import firebase from "firebase";
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectCurrentUser } from '../../../redux/user/user.selector'

function Index(props) {

    const [headCells, setHeadCells] = React.useState([]);
    const [headMobileCells, setHeadMobileCells] = React.useState([]);
    const [searchValue, setSearchValue] = React.useState("");
    const [searchCell, setSearchCell] = React.useState([]);
    const [displayInfo, setDisplayInfo] = React.useState([]);
    const [editCells, setEditCells] = React.useState([]);
    const [addCells, setAddCells] = React.useState([]);
    const [addCellsName, setAddCellsName] = React.useState([]);
    const matches = useMediaQuery(theme => theme.breakpoints.up('sm'));

    useEffect(() => {

        setHeadCells([
            { id: 'warranty_id', label: 'Warranty ID' },
            { id: 'created', label: 'Register Date' },
            { id: 'expired_date', label: 'Expired Date' },
            { id: 'customer_id', label: 'Customer ID' },
            { id: 'store_id', label: 'Store' },
            { id: 'claimed', label: 'Claimed' },
            { id: 'claimed_date', label: 'Claimed Date' },
        ])

        setHeadMobileCells([
            { id: 'warranty_id', label: 'Warranty ID' },
        ])
    
        setSearchCell([
            { id: 'warranty_id', label: 'Warranty ID' },
            { id: 'customer_id', label: 'Customer ID' },
            { id: 'phone', label: 'Customer Phone' },
            
        ])

        setDisplayInfo([
            { id: 'warranty_id', label: 'Warranty ID' },
            { id: 'created', label: 'Register Date' },
            { id: 'expired_date', label: 'Expired Date' },
            { id: 'customer_id', label: 'Customer ID' },
            { id: 'phone', label: 'Customer Phone' },
            { id: 'claimed', label: 'Claimed' },
            { id: 'claimed_date', label: 'Claimed Date' },
        ])

        setEditCells([
            { id: 'claimed', label: 'Claimed' },
            { id: 'claimed_date', label: 'Claimed' },
        ])

        setAddCells([
            { id: 'customer', label: 'Customer' },
            { id: 'warranty_id', label: 'Warranty ID' },
        ])

        setAddCellsName({ 
            first_name: '',
            last_name: '',
            created: firebase.database.ServerValue.TIMESTAMP,
            updated: firebase.database.ServerValue.TIMESTAMP,
            email: '',
            phone: '',
            status_users: '',
            role: '',
         })
    
        setSearchValue("warranty_id")
        
    }, []);

    return (
    <Grid>
        <Table 
            {...props}
            customizeDialog={AddEditWarranty}
            control={true}
            hideDelete={true}
            hideMove={true}
            orderBy="created" 
            editButton={false}
            data={"warranty"}
            addItemButton={true}
            disabledDelete
            searchWarranty={true}
            disabledMove
            addItemText="Register Warranty"
            addItemIcon={<AddCircleIcon/>}
            addCells={addCells}
            addCellsName={addCellsName}
            folder_data={"warranty_zone"}
            displayInfo={displayInfo}
            editCells={editCells}
            matches={matches}
            headCells={matches ? headCells : headMobileCells} 
            searchValue={searchValue} 
            searchCell={searchCell}/>
    </Grid>
    )

}

const mapStatetoProps = createStructuredSelector({
    currentUser: selectCurrentUser
})
  
export default connect(mapStatetoProps)(Index);