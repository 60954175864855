import React, { useEffect, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Dialog from '../../../modules/components/Dialog';
import Products from '../Products';
import Categories from '../Categories';
import Shipping from '../Shipping';
import Typography from '@material-ui/core/Typography';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Divider from '@material-ui/core/Divider';
import firebase from "firebase";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';

import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';
import Input from '@material-ui/core/Input';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import CircularProgress from '@material-ui/core/CircularProgress';

import { connect } from 'react-redux';
import {
  setProductItem
} from '../../../redux/product/product.actions';
import { createStructuredSelector } from 'reselect';
import { selectProductItems } from '../../../redux/product/product.selectors'

//table head
function EnhancedTableHead(props) {
    const { headCells } = props;

    return (
        <TableHead >
            <TableRow>
                {headCells ? headCells.map((headCell) => (
                    <TableCell
                        style={{ padding: 3 }}
                        key={headCell.id}
                        align={'left'}
                        sortDirection={false}
                    >
                        {headCell.label}
                    </TableCell>
                )) : null}

            </TableRow>
        </TableHead>
    );
}


const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #6e6e6e',
    width: 70,
    height: 70,
    padding: 4,
    boxSizing: 'border-box',
    marginLeft: 8,
    marginRight: 8,
    marginBottom: 8 
};

 
const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};

function Index(props) {

    //Dialog
    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogLoading, setDialogLoading] = React.useState(false);
    const [fullScreenDialog, setFullScreenDialog] = React.useState(false);
    const [dialogTitle, setDialogTitle] = React.useState("");
    const [dialogDes, setDialogDes] = React.useState("");
    const [agreeButton, setAgreeButton] = React.useState(false);
    const [option, setOptions] = React.useState("");

    const [selected, setSelected] = React.useState([]);
    const [selectedQuantity, setSelectedQuantity] = React.useState([]);
    const [mediaOption, setMediaOption] = React.useState("");
    const [values, setValues] = React.useState({});

    const [page2, setPage2] = React.useState(0);
    const [rowsPerPage2, setRowsPerPage2] = React.useState(10);
    const [selectedValue, setSelectedValue] = React.useState('receive');
    
    const [allWarehouseReceive, setAllWarehouseReceive] = React.useState([]);
    const [allWarehouseExport, setAllWarehouseExport] = React.useState([]);
    const [allOrders, setAllOrders] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [loading2, setLoading2] = React.useState(false);

    const handleRadioChange = (event) => {
        setSelectedValue(event.target.value);
    };

    useEffect(() => {
        setValues(props.addCellsName ? props.addCellsName : {})
        
        if(props.option === "edit"){
            setValues(props.selectedItem ? props.selectedItem : {})

            if(props.selectedItem.products){
                let quantityItem = []
                props.selectedItem.products.forEach(val=>{
                    quantityItem.push(Number(val.warehouse_quantity))
                })

                setSelectedQuantity(quantityItem)
            }
            setSelected(props.selectedItem.products ? props.selectedItem.products : [])

            setSelectedValue(props.selectedItem.warehouse_action ? props.selectedItem.warehouse_action : "receive")

            let warehouseRef = firebase.database().ref("pos/" + firebase.auth().currentUser.displayName + "/warehouse");
  
            warehouseRef.once('value', snapshot => {
  
                if(snapshot.exists()){
                  let _warehouse = []
                  let _warehouseExport = []
                  snapshot.forEach(val => {
                    if(val.val().id !== props.selectedItem.id && val.val().warehouse_action === "receive"){
                        _warehouse.push(val.val().products?val.val().products: [])
                    }

                    if(val.val().id !== props.selectedItem.id && val.val().warehouse_action === "export"){
                        _warehouseExport.push(val.val().products?val.val().products: [])
                    }
                    
                  })

                  setAllWarehouseReceive(_warehouse)
                  setAllWarehouseExport(_warehouseExport)
                }
                
                let ordersRef = firebase.database().ref("pos/" + firebase.auth().currentUser.displayName + "/orders");

                ordersRef.once('value', ordersnapshot => {

                    if(ordersnapshot.exists()){
                        let _orders = []
                        ordersnapshot.forEach(val => {
                            _orders.push(val.val().products?val.val().products: [])
                        })
      
                        setAllOrders(_orders)
                        
                    }

                    setLoading(false)
                })
            })
        }
        else{
            setLoading(false)
        }

    }, [props]);

    const handleChangeRowsPerPage2 = (event) => {
        setRowsPerPage2(parseInt(event.target.value, 10));
        setPage2(0);
    };

    const handleChangePage2 = (event, newPage) => {
        setPage2(newPage);
    };

    const addQuantity = (index) => {
        const newQuantityItem = [...selectedQuantity]
        newQuantityItem[index] = Number(newQuantityItem[index]) + 1;
        setSelectedQuantity(newQuantityItem);
    }

    const minusQuantity = (index) => {
        const newQuantityItem = [...selectedQuantity]

        if(Number(newQuantityItem[index]) > 1){
            newQuantityItem[index] = Number(newQuantityItem[index]) - 1;
            setSelectedQuantity(newQuantityItem);
        }
    
    }

    const handleClose = () => {
        setOpenDialog(false)
    };


    const handleAgree = () => {

        setLoading2(true)

        var updates = {};

        if(props.option === "edit"){
            values.id = props.selectedItem.id;
            values.warehouse_action = selectedValue;

            let count_complete = 0;

            var newSeletect = [];
            selected.forEach((val, index)=>{

                if(!val.removed){
                    newSeletect.push(val)
                }

                var currentStockReceive = 0;

                allWarehouseReceive.forEach((item, index)=>{
                    var currentIndexProduct = item.map(itemVal => itemVal.id).indexOf(val.id);
                    if(currentIndexProduct !== -1){
                        currentStockReceive += item[currentIndexProduct].warehouse_quantity;
                    }
                })

                var currentStockExport = 0;

                allWarehouseExport.forEach((item, index)=>{
                    var currentIndexProduct = item.map(itemVal => itemVal.id).indexOf(val.id);
                    if(currentIndexProduct !== -1){
                        currentStockExport += item[currentIndexProduct].warehouse_quantity;
                    }
                })

                var currentOrderStock = 0;

                allOrders.forEach((item, index)=>{
                    var currentIndexProduct = item.map(itemVal => itemVal.id).indexOf(val.id);
                    if(currentIndexProduct !== -1){
                        currentOrderStock += item[currentIndexProduct].quantity;
                    }
                })

                

                val.warehouse_quantity = selectedQuantity[index];

                var currentIndex = props.productItems.map(val => val.id).indexOf(val.id);

                if(currentIndex !== -1){
                    if(selectedValue === "receive"){

                       
                        currentStockReceive += selectedQuantity[index];
                        
                        props.productItems[currentIndex].in_stock = Number(currentStockReceive) - Number(currentOrderStock) - Number(currentStockExport);


                        firebase.database().ref("pos/" + firebase.auth().currentUser.displayName + "/products/" + props.productItems[currentIndex].id)
                        .update({ in_stock: props.productItems[currentIndex].in_stock }).then(()=>{
                            count_complete++;

                            if(count_complete === selected.length){
                                values.products = newSeletect;

                                props.setProductItem(props.productItems)

                                updates[props.data + '/' + props.selectedItem.id] = values;
                        
                                firebase.database().ref().update(updates).then(()=>{

                                    props.handleClose()
                                })
                            }
                        })
                    }
                    else{
                        currentStockExport += selectedQuantity[index];
                        props.productItems[currentIndex].in_stock = Number(currentStockReceive) - Number(currentOrderStock) - Number(currentStockExport);

                        firebase.database().ref("pos/" + firebase.auth().currentUser.displayName + "/products/" + props.productItems[currentIndex].id)
                        .update({ in_stock: props.productItems[currentIndex].in_stock }).then(()=>{
                            count_complete++;

                            if(count_complete === selected.length){
                                values.products = newSeletect;

                                props.setProductItem(props.productItems)

                                updates[props.data + '/' + props.selectedItem.id] = values;
                        
                                firebase.database().ref().update(updates).then(()=>{

                                    props.handleClose()
                                })
                            }
                        })
                    }
                    
                }else
                {
                    count_complete++;
                    if(count_complete === selected.length){
                        values.products = newSeletect;

                        props.setProductItem(props.productItems)

                        updates[props.data + '/' + props.selectedItem.id] = values;
                
                        firebase.database().ref().update(updates).then(()=>{
   
                            props.handleClose()
                        })
                    }
                }

            })

            
        }
        else{
            var newPostKey = firebase.database().ref().child(props.data).push().key;

            values.id = newPostKey;
            values.user = firebase.auth().currentUser.email;
            values.warehouse_action = selectedValue;

            let count_complete = 0;

            selected.forEach((val, index)=>{
                val.warehouse_quantity = selectedQuantity[index];

                var currentIndex = props.productItems.map(val => val.id).indexOf(val.id);

                if(currentIndex !== -1){
                    if(selectedValue === "receive"){
                        props.productItems[currentIndex].in_stock = Number(props.productItems[currentIndex].in_stock) + selectedQuantity[index];

                        firebase.database().ref("pos/" + firebase.auth().currentUser.displayName + "/products/" + props.productItems[currentIndex].id)
                        .update({ in_stock: props.productItems[currentIndex].in_stock }).then(()=>{
                            count_complete++;

                            if(count_complete === selected.length){
                                values.products = selected;

                                props.setProductItem(props.productItems)
                        
                                updates[props.data + '/' + newPostKey] = values;
                        
                                firebase.database().ref().update(updates).then(()=>{

                                    props.handleClose()
                                })
                            }
                        })
                    }
                    else{
                        props.productItems[currentIndex].in_stock = Number(props.productItems[currentIndex].in_stock) - selectedQuantity[index];

                        firebase.database().ref("pos/" + firebase.auth().currentUser.displayName + "/products/" + props.productItems[currentIndex].id)
                        .update({ in_stock: props.productItems[currentIndex].in_stock }).then(()=>{
                            count_complete++;
                            if(count_complete === selected.length){
                                values.products = selected;

                                props.setProductItem(props.productItems)
                        
                                updates[props.data + '/' + newPostKey] = values;
                        
                                firebase.database().ref().update(updates).then(()=>{

                                    props.handleClose()
                                })
                            }
                        })
                    }
                    
                }else
                {
                    count_complete++;
                    if(count_complete === selected.length){
                        values.products = selected;

                        props.setProductItem(props.productItems)
                
                        updates[props.data + '/' + newPostKey] = values;
                
                        firebase.database().ref().update(updates).then(()=>{

                            props.handleClose()
                        })
                    }
                }
            })

            
        }
        

        
    }

    const handleSetRows = (row) => {
        if(mediaOption === "product")
        {

            let newRow = [];
            let newQuantity = []
            row.forEach(val => {
                let item = val;
                item.warehouse_quantity = 1;
                newRow.push(item)
                newQuantity.push(1)
            })

            setSelected(prev => [...prev,...newRow])
            setSelectedQuantity(prev => [...prev,...newQuantity])
        }
        
        setOpenDialog(false)
    }

    const renderComponent = () => {
        if(mediaOption === "product"){
            return(<Products mediaOption={mediaOption} handleSetRows={(rows) => handleSetRows(rows)}/>)
        }
    }

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const addProduct = () => {
        setMediaOption("product")
        setDialogLoading(false)
        setAgreeButton(false)
        setDialogTitle("Receive Products")
        setOptions("addItem")
        setDialogDes("")
        setFullScreenDialog(true)
        setOpenDialog(true)
    }

    const handleDelete = () => {
        setMediaOption("delete")
        setDialogLoading(false)
        setAgreeButton(true)
        setDialogTitle("Delete")
        setOptions("deleteItem")
        setDialogDes("Are you sure you want to delete?")
        setFullScreenDialog(false)
        setOpenDialog(true)
    }

    const handleDeleteWarehouse = () => {

        setLoading2(true)

        values.id = props.selectedItem.id;
        values.warehouse_action = selectedValue;

        let count_complete = 0;

        selected.forEach((val, index)=>{

            var currentStockReceive = 0;

            allWarehouseReceive.forEach((item, index)=>{
                var currentIndexProduct = item.map(itemVal => itemVal.id).indexOf(val.id);
                if(currentIndexProduct !== -1){
                    currentStockReceive += item[currentIndexProduct].warehouse_quantity;
                }
            })

            var currentStockExport = 0;

            allWarehouseExport.forEach((item, index)=>{
                var currentIndexProduct = item.map(itemVal => itemVal.id).indexOf(val.id);
                if(currentIndexProduct !== -1){
                    currentStockExport += item[currentIndexProduct].warehouse_quantity;
                }
            })

            var currentOrderStock = 0;

            allOrders.forEach((item, index)=>{
                var currentIndexProduct = item.map(itemVal => itemVal.id).indexOf(val.id);
                if(currentIndexProduct !== -1){
                    currentOrderStock += item[currentIndexProduct].quantity;
                }
            })

            val.warehouse_quantity = 0;

            var currentIndex = props.productItems.map(val => val.id).indexOf(val.id);

            if(currentIndex !== -1){
                if(selectedValue === "receive"){
                    props.productItems[currentIndex].in_stock = currentStockReceive - currentOrderStock - currentStockExport;

                    firebase.database().ref("pos/" + firebase.auth().currentUser.displayName + "/products/" + props.productItems[currentIndex].id)
                    .update({ in_stock: props.productItems[currentIndex].in_stock }).then(()=>{
                        count_complete++;
                        if(count_complete === selected.length){
                            values.products = selected;

                            props.setProductItem(props.productItems)

                            firebase.database().ref(props.data + '/' + props.selectedItem.id).remove().then(()=>{
                                setOpenDialog(false)
                                props.handleClose()
                            })

                        }
                    })
                }
                else{
                    
                    props.productItems[currentIndex].in_stock = currentStockReceive - currentOrderStock - currentStockExport;

                    firebase.database().ref("pos/" + firebase.auth().currentUser.displayName + "/products/" + props.productItems[currentIndex].id)
                    .update({ in_stock: props.productItems[currentIndex].in_stock }).then(()=>{
                        count_complete++;
                        if(count_complete === selected.length){
                            values.products = selected;

                            props.setProductItem(props.productItems)

                            firebase.database().ref(props.data + '/' + props.selectedItem.id).remove().then(()=>{
                                setOpenDialog(false)
                                props.handleClose()
                            })

                        }
                    })
                }
                
            }
            else
            {
                count_complete++;
                if(count_complete === selected.length){
                    values.products = selected;

                    props.setProductItem(props.productItems)

                    firebase.database().ref(props.data + '/' + props.selectedItem.id).remove().then(()=>{
                        setOpenDialog(false)
                        props.handleClose()
                    })

                }
            }

        })

        
    }

    const removeProduct = (index) => {

        /*var newSelectedQuantity = [...selectedQuantity]
        newSelectedQuantity[index] = 0;
        setSelectedQuantity(newSelectedQuantity);

        var newSelected = [...selected]
        newSelected[index].removed = true;
        setSelected(newSelected);*/

        var newSelectedQuantity = [...selectedQuantity]
        newSelectedQuantity.splice(index, 1)
        setSelectedQuantity(newSelectedQuantity);

        var newSelected = [...selected]
        newSelected.splice(index, 1);
        setSelected(newSelected);
    }

    if(loading2){
        return (
        <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center', marginTop: 70  }}>
          <CircularProgress 
            disableShrink
            color="primary" />
        </div>);
    }

    if(loading){
        return (
        <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center', marginTop: 70  }}>
          <CircularProgress 
            disableShrink
            color="primary" />
        </div>);
    }

    let product_count = 0;

    return (
        <Grid style={{ maxWidth: 625  }}>
            <Dialog 
                agreeTxt={"Yes"}
                disagreeTxt={"No"}
                description={dialogDes}
                title={dialogTitle}
                open={openDialog} 
                agreeButton={agreeButton}
                fullScreenDialog={fullScreenDialog}
                option={option}
                dialogLoading={dialogLoading}
                handleAgree={handleDeleteWarehouse}
                component={renderComponent()}
                handleClose={handleClose}/>

            <Grid style={{ paddingBottom: 10, width: "100%", display: "flex", flexDirection: "column" }}>
                    {props.option === "edit" ? null: <Grid  style={{ width: "100%", paddingTop: 10 }} >
                        <Button onClick={addProduct} color="primary" style={{ width: "100%" }} 
                        startIcon={<AddCircleIcon/>} 
                        disableElevation  variant="contained">{"Products"}</Button>
                    </Grid>}
                    <Grid style={{ marginTop: 10, display: "flex", justifyContent: "center", alignItems: "center"}} >
                    <TextField
                        label={"Name"}
                        style={{ width: "100%"}}
                        value={values["name"] || ''}
                        onChange={handleChange("name")}
                        variant="outlined"/></Grid>
                    <Grid style={{ marginTop: 10, display: "flex", justifyContent: "center", alignItems: "center"}} >
                    <TextField
                        label={"Description"}
                        multiline
                        minRows={2}
                        style={{ width: "100%"}}
                        value={values["description"] || ''}
                        onChange={handleChange("description")}
                        variant="outlined"/></Grid>
                    <Grid style={{ marginTop: 10, display: "flex", justifyContent: "center", alignItems: "center"}} >
                    <RadioGroup row aria-label="position" name="position" defaultValue="top">
                    <FormControlLabel disabled={props.option === "edit" ? true:false } value="end" control={<Radio
                            checked={selectedValue === 'receive'}
                            onChange={handleRadioChange}
                            value="receive"
                            color='primary'
                            name="radio-button-demo"
                            inputProps={{ 'aria-label': 'A' }}
                        />} label="Receive" />
                    <FormControlLabel disabled={props.option === "edit" ? true:false } value="end" control={<Radio
                            checked={selectedValue === 'export'}
                            onChange={handleRadioChange}
                            value="export"
                            color='primary'
                            name="radio-button-demo"
                            inputProps={{ 'aria-label': 'B' }}
                        />} label="Export" />
                    </RadioGroup>
                    
                        </Grid>
                    <Grid style={{ width: "100%", marginTop: 10 }} >
                        <TableContainer>
                            <Table style={{ width: "100%" }} aria-label="custom pagination table">
                                <EnhancedTableHead
                                    headCells={[
                                        { id: 'no', numeric: false, disablePadding: false, label: 'No.' },
                                        { id: 'image', numeric: false, disablePadding: false, label: 'img' },
                                        { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
                                        { id: 'quantity', numeric: false, disablePadding: false, label: 'Qty' },
                                        { id: 'action', numeric: false, disablePadding: false, label: '' }]}
                                />

                                <TableBody>
                                    {(rowsPerPage2 > 0
                                        ? selected.slice(page2 * rowsPerPage2, page2 * rowsPerPage2 + rowsPerPage2)
                                        : selected
                                    ).map((row, index) => {
                                        
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        if(!row.removed){
                                            product_count++;
                                        }
                                       
                                        var newIndex = index + (page2 * rowsPerPage2)
                                        return (
                                            <TableRow hover
                                                key={labelId}
                                                role="checkbox"
                                                style={{ display: row.removed ? "none": "normal"}}
                                                tabIndex={-1} >
                                                
                                                <TableCell style={{ padding: 10, width: 10 }} align="left">
                                                    <Typography variant="body2">{product_count + (page2 * 10) + "."}</Typography>
                                                </TableCell>
                                                <TableCell style={{ padding: 10, width: 10 }} align="left">
                                                <Avatar alt={"image"+newIndex} src={row.image ? row.image[0].url : ""} />
                                                </TableCell>
                                                <TableCell style={{ padding: 3, overflow: "hidden", textOverflow: "ellipsis", maxWidth: 150 }} align="left">
                                                    {row.name}
                                                </TableCell>
                                                {props.option === "edit" ? <TableCell style={{ padding: 3, overflow: "hidden", textOverflow: "ellipsis", maxWidth: 150, paddingRight: 10 }} align="right">
                                                    {selectedQuantity[newIndex]}
                                                </TableCell> :null}
                                                {props.option === "edit" ? null : <TableCell style={{ padding: 3, overflow: "hidden", textOverflow: "ellipsis", maxWidth: 150 }} align="left">
                                                    <Grid style={{ justifyContent: "center", display: "flex", flexDirection: "row", alignItems: "center" }} >
                                                        <IconButton
                                                        style={{ opacity: .7, width: 20, height: 20 }}
                                                        size={"small"}
                                                        onClick={()=> addQuantity(newIndex)}
                                                        >
                                                        <Add style={{ fontSize: 18 }} />
                                                        </IconButton>
                                                        <Grid style={{ height: 30, display: "flex", alignItems: "center" }} >
                                                        <Input 
                                                        type="text" 
                                                        inputProps={{min:1, style: { textAlign: 'center' }}}
                                                        value={selectedQuantity[newIndex]}
                                                        onChange={(e)=>{

                                                            var isNumber = /^\d+$/.test(e.target.value)
                                                            const newQuantityItem = [...selectedQuantity]
                                                            if(isNumber){
                                                                if(Number(e.target.value) > 1){
                                                                    newQuantityItem[newIndex] = Number(e.target.value);
                                                                    setSelectedQuantity(newQuantityItem)
                                                                }
                                                                else{
                                                                    newQuantityItem[newIndex] = 1;
                                                                    setSelectedQuantity(newQuantityItem)
                                                                }
                                                                
                                                            }
                                                        }}
                                                        style={{ fontSize: 14, width: 40, padding: 0, textAlign: "center"  }} />
                                                        </Grid>

                                                        <IconButton
                                                        style={{ opacity: .7, width: 20, height: 20 }}
                                                        size={"small"}
                                                        onClick={()=> minusQuantity(newIndex)}
                                                        >
                                                        <Remove style={{ fontSize: 18 }} />
                                                        </IconButton>
                                                    </Grid>
                                                </TableCell>}
                                                {props.option === "edit" ? 
                                                <TableCell style={{ padding: 3, overflow: "hidden", textOverflow: "ellipsis", maxWidth: 150 }} align="left">
                                                   
                                                </TableCell> : <TableCell style={{ padding: 3, overflow: "hidden", textOverflow: "ellipsis", maxWidth: 150 }} align="left">
                                                    <IconButton onClick={()=> removeProduct(newIndex)}>
                                                        <DeleteIcon fontSize="small"/>
                                                    </IconButton>
                                                </TableCell>}
                                            </TableRow>
                                        )

                                        
                                    })}

                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            labelRowsPerPage={""}
                                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                            colSpan={6}
                                            count={selected.length}
                                            rowsPerPage={rowsPerPage2}
                                            page={page2}
                                            SelectProps={{
                                                inputProps: { 'aria-label': 'rows per page' },
                                                native: true,
                                            }}
                                            onPageChange={handleChangePage2}
                                            onRowsPerPageChange={handleChangeRowsPerPage2}
                                            //ActionsComponent={TablePaginationActions}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                        
                    </Grid>
            </Grid>
            
            <Grid style={{ padding: 10, marginBottom: 30, display: 'flex', justifyContent: "flex-end" }}>
                {props.option === "edit" ? <Button variant="outlined" style={{ fontSize: 14, fontWeight: 'bold'}} onClick={handleDelete} color="primary">
                {"Delete"}
                </Button> : null }
                <Button variant="outlined" style={{  marginLeft: 60, fontSize: 14, fontWeight: 'bold'}} onClick={props.handleClose} color="primary">
                {"No"}
                </Button>
                <Button disabled={selected.length > 0 ? false:true} disableElevation variant="contained" style={{ marginLeft: 10, fontSize: 14, fontWeight: 'bold'}} onClick={handleAgree} color="primary" autoFocus>
                {"Yes"}
                </Button>
            </Grid>
        </Grid>
    )

}


const mapDispatchToProps = dispatch => ({
    setProductItem: item => dispatch(setProductItem(item)),
});

const mapStateToProps = createStructuredSelector({
    productItems: selectProductItems,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Index);