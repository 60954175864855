import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import Error from "./views/pages/Error";
import withRoot from "./modules/withRoot";
import app from "./firebase/base";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Grid from '@material-ui/core/Grid';

//Admin
import Navigation from "./views/navigation/Navigation";

import Users from "./views/pages/Users";
import Salesman from "./views/pages/Salesman";
import Warranty from "./views/pages/Warranty";
import Customers from "./views/pages/Customers";
import Warehouse from "./views/pages/Warehouse";
import Report from "./views/pages/Report";
import Products from "./views/pages/Products";
import Orders from "./views/pages/Orders";
import CreditNote from "./views/pages/CreditNote";
import Retails from "./views/pages/Retails";
import Receipts from "./views/pages/Retail/OrdersDetails";
import ProductWarranty from "./views/pages/Retail/ProductWarranty";
import LoginCashier from "./views/authentication/LoginCashier";

import RegisterUser from "./views/pages/RegisterUser";
import ApprovalUser from "./views/pages/ApprovalUser";
import AdminSetPassword from "./views/pages/AdminSetPassword";
import SalesReport from "./views/pages/SalesReport";

import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectCurrentUser } from './redux/user/user.selector'
import { selectCurrentStaff } from './redux/staff/staff.selector'
import {
  setCurrentUser
} from './redux/user/user.actions';

import {
  setCurrentStaff
} from './redux/staff/staff.actions';

import { useDispatch } from 'react-redux';
import {
  setItem
} from './redux/cart/cart.actions';

import {
  setProductItem
} from './redux/product/product.actions';

import {
  setCategoryItem
} from './redux/category/category.actions';

import ClientLogin from "./views/authentication/ClientLogin";
import Register from "./views/authentication/Register";
import ForgotPassword from "./views/authentication/ForgotPassword";

function App(props) {

  const [authenticated, setAuthenticated] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [adminRegistered, setAdminRegistered] = React.useState(false);
  const dispatch = useDispatch();

  useEffect(() => {

    const readCartDatabase = (user) => {


      if(user){
        let cartRef = app.database().ref("pos/" + user.id + "/carts/"+ user.id);

        cartRef.once('value', snapshot => {
          if(snapshot.exists()){
            dispatch(setItem(snapshot.val().items ? snapshot.val().items : [], user))
          }
          else
          {
            dispatch(setItem([], user))
          }

          let orderRef = app.database().ref("pos/" + user.id + "/orders");

          orderRef.once('value', snapshot => {

            let _orders = []
                
            if(snapshot.exists()){

              snapshot.forEach(val => {
                _orders.push(val.val())
              })

            }

            let warehouseRef = app.database().ref("pos/" + user.id + "/warehouse");

            warehouseRef.once('value', snapshot => {
              
              let _warehouse = []
                
              if(snapshot.exists()){
  
                snapshot.forEach(val => {
                  _warehouse.push(val.val())
                })
  
              }

              let cnRef = app.database().ref("pos/" + user.id + "/credit_note");

              cnRef.once('value', snapshot => {
              
                let _credit_note = []
                  
                if(snapshot.exists()){
    
                  snapshot.forEach(val => {
                    _credit_note.push(val.val())
                  })
    
                }

                var allproducts = []
                _orders.forEach(val => {
                  if(val.products)
                  {
                    val.products.forEach(value => {
                      value.quantity = Number(value.quantity)
                      allproducts.push(value)
                    })
                  }
                  
                })

                const productOrderedList = allproducts.reduce((items, item) => {
                  
                    const {id, quantity} = item;
                    
                  const itemIndex = items.findIndex(item => item.id === id)
                  
                  if(itemIndex === -1){
                    items.push(item);
                  } else {
                    items[itemIndex].quantity += quantity;
                  }
                  
                  return items;
                }, []);


                var warehouseproducts_import = []
                var warehouseproducts_export = []

                _warehouse.forEach(val => {
                  if(val.warehouse_action === "receive"){
                    if(val.products)
                    {
                      val.products.forEach(value => {
                        value.warehouse_quantity = Number(value.warehouse_quantity)
                        warehouseproducts_import.push(value)
                      })
                    }
                    
                  }
                  else{
                    if(val.products)
                    {
                      val.products.forEach(value => {
                        value.warehouse_quantity = Number(value.warehouse_quantity)
                        warehouseproducts_export.push(value)
                      })
                    }
                    
                  }
                })
                

                const warehouseImportList = warehouseproducts_import.reduce((items, item) => {
                  
                  const {id, warehouse_quantity} = item;
                  
                  const itemIndex = items.findIndex(item => item.id === id)
                  
                  if(itemIndex === -1){
                    items.push(item);
                  } else {
                    items[itemIndex].warehouse_quantity += warehouse_quantity;
                  }
                  
                  return items;
                }, []);


                const warehouseExportList = warehouseproducts_export.reduce((items, item) => {
                  
                  const {id, warehouse_quantity} = item;
                  
                  const itemIndex = items.findIndex(item => item.id === id)
                  
                  if(itemIndex === -1){
                    items.push(item);
                  } else {
                    items[itemIndex].warehouse_quantity += warehouse_quantity;
                  }
                  
                  return items;
                }, []);


                var allCNproducts = []
                _credit_note.forEach(val => {
                  if(val.products)
                  {
                    val.products.forEach(value => {
                      value.products_quantity = Number(value.products_quantity)
                      allCNproducts.push(value)
                    })
                  }
                  
                })

                const cnProductList = allCNproducts.reduce((items, item) => {
                  
                  const {id, products_quantity} = item;
                  
                  const itemIndex = items.findIndex(item => item.id === id)
                  
                  if(itemIndex === -1){
                    items.push(item);
                  } else {
                    items[itemIndex].products_quantity += products_quantity;
                  }
                  
                  return items;
                }, []);


                let allproductRef = app.database().ref("products");

                allproductRef.once('value', snapshot => {

                  let _all_product = [];

                  if(snapshot.exists()){

                    snapshot.forEach(val => {
                      var _productGlobal = val.val();
                      _all_product.push(_productGlobal);
                    })

                  }

                  let productRef = app.database().ref("pos/" + user.id + "/products");
    
                  productRef.once('value', snapshot => {
                    
                    if(snapshot.exists()){

                      let _products = []
                      
                      snapshot.forEach(val => {
                        var _product = val.val();
                        _product.in_stock = 0;

                        let itemIndex = productOrderedList.map(value => value.id).indexOf(_product.id);

                        if(itemIndex != -1){
                          _product.in_stock -= productOrderedList[itemIndex].quantity;
                        }

                        let itemIndex2 = warehouseImportList.map(value => value.id).indexOf(_product.id);

                        if(itemIndex2 != -1){
                          _product.in_stock += warehouseImportList[itemIndex2].warehouse_quantity;
                        }

                        let itemIndex3 = warehouseExportList.map(value => value.id).indexOf(_product.id);

                        if(itemIndex3 != -1){
                          _product.in_stock -= warehouseExportList[itemIndex3].warehouse_quantity;
                        }

                        let itemIndex4 = cnProductList.map(value => value.id).indexOf(_product.id);

                        if(itemIndex4 != -1){
                          _product.in_stock += cnProductList[itemIndex4].products_quantity;
                        }

                        let itemIndex5 = _all_product.map(value => value.id).indexOf(_product.id);

                        if(itemIndex5 != -1){
                          _product.status = _all_product[itemIndex5].status;
                        }
                        
                        _products.push(_product)
                      })
                      
                      dispatch(setProductItem(_products))
                    }
                    else
                    {
                      dispatch(setProductItem([]))
                    }
        
                    let itemCategoriesRef = app.database().ref("pos/" + user.id + "/categories");
        
                    itemCategoriesRef.once('value', snapshot => {
        
                      if(snapshot.exists()){
                        let _categories = []
                        snapshot.forEach(val => {
                          _categories.push(val.val())
                        })
        
                        dispatch(setCategoryItem(_categories))
                      }
                      else
                      {
                        dispatch(setCategoryItem([]))
                      }
                      
                      const items = JSON.parse(localStorage.getItem('staff'));
                      
                      if(items){
                        dispatch(setCurrentStaff(items))
                      }

                      let userRef = app.database().ref("pos/" + user.id + "/users");

                        userRef.once('value', snapshot => {

                          if(snapshot.exists()){
                            
                            var _adminRegistered =false;
                            snapshot.forEach(val => {
                              if(val.val().id === user.id){

                                _adminRegistered = true;
                                
                              }
                            })
                            setAdminRegistered(_adminRegistered)
                          }
                          else{
                            setAdminRegistered(false)
                          }

                          setAuthenticated(true)
                          setLoading(false)
                      })
      
                  
                    
                  });
      
                })

                })

                


              })

              

            })

            
  
          })
  
           
        })
      }
      
    }

    let userAllRef = app.database().ref("concept_store");

    userAllRef.once('value', snapshot => {

      let userRef = null;
    
      app.auth().onAuthStateChanged(user => {

        if (user && snapshot.exists()) {
          
          let foundPhone = null;

          snapshot.forEach(child=>{
            if(child.val().phone === user.phoneNumber){
              foundPhone = child.val().id;
            }
          })


          if(foundPhone)
          {
            //foundPhone = "SP2SCT120001"
            //foundPhone = "SP2SCT100006"
            userRef = app.database().ref("concept_store/"+ foundPhone);

            userRef.on('value', snapshot => {
              
              if(snapshot.exists()){

                const appRef = app.auth().currentUser;

                appRef.updateProfile({
                  displayName: snapshot.val().id
                })

                dispatch(setCurrentUser(snapshot.val()))
                
                readCartDatabase(snapshot.val())

              }
              else{

                dispatch(setCurrentUser(null))
                readCartDatabase(null)

                setAuthenticated(true)
                setLoading(false)
              }

            })
          }
          else
          {
            dispatch(setCurrentUser(null))
            readCartDatabase(null)

            setAuthenticated(true)
            setLoading(false)
          }

          
        
        } else {

          if(userRef){
            userRef.off();
            userRef = null;
          }

          setAuthenticated(false)
          setLoading(false)
        }
      });
      
      
    })

    

    
  }, [dispatch]);

  const renderAdminRoute = () => {

    const items = JSON.parse(localStorage.getItem('staff'));

    if(items && items.role ==="admin"){
      return(
        <Switch>
          <Route exact path="/" component={Retails} />
          <Route path="/warehouse" component={Warehouse} />
          <Route path="/warranty" component={Warranty} />
          <Route path="/report" component={Report} />
          <Route path="/products" component={Products} />
          <Route path="/orders" component={Orders} />
          <Route path="/creditnote" component={CreditNote} />
          <Route path="/receipt/:id" component={Receipts} />
          <Route path="/product_warranty/:id" component={ProductWarranty} />
          <Route path="/users" component={Users} />
          <Route path="/salesman" component={Salesman} />
          <Route path="/customers" component={Customers} />
          <Route path="/login_cashier" component={LoginCashier} />
          <Route path="/sales_report" component={SalesReport} />
          <Route path="*" component={Error} />
        </Switch>
      )
    }
    else
    {
      return(
        <Switch>
          <Route exact path="/" component={Retails} />
          <Route path="/orders" component={Orders} />
          <Route path="/creditnote" component={CreditNote} />
          <Route path="/receipt/:id" component={Receipts} />
          <Route path="/product_warranty/:id" component={ProductWarranty} />
          <Route path="/customers" component={Customers} />
          <Route path="/login_cashier" component={LoginCashier} />
          <Route path="*" component={Error} />
        </Switch>
      )
    }
    
  }
  

  const renderUserApprovel = () =>{
    if(props.currentUser.status_users === "active" && props.currentUser.role === "concept_store"){

      if(adminRegistered)
      {
        return(
          <Navigation component={renderAdminRoute()} />
        )
      }
      else{
        return(
          <AdminSetPassword currentUser={props.currentUser}/>
        )
      }
      
    }
    else{
      return(
        <Grid style={{ display: "flex", justifyContent: "center" }}>
          <ApprovalUser currentUser={props.currentUser}/>
        </Grid>
      )
    }
  }

  if(loading){
    return (
    <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center', marginTop: 70  }}>
      <CircularProgress 
        disableShrink
        color="primary" />
    </div>);
  }

  if(authenticated){
    return (
      <BrowserRouter>
          {props.currentUser ? renderUserApprovel() : <RegisterUser/> }
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
        <Switch>
          <Route exact path="/" component={ClientLogin} />
          <Route path="/register" component={Register} />
          <Route path="/forgotpassword" component={ForgotPassword} />
          <Route path="*" component={Error} />
        </Switch>
    </BrowserRouter>
  );
}

const mapDispatchToProps = dispatch => ({
  setCurrentUser: item => dispatch(setCurrentUser(item)),
  setCurrentStaff: item => dispatch(setCurrentStaff(item)),
  setItem: (item, user) => dispatch(setItem(item, user)),
  setProductItem: item => dispatch(setProductItem(item)),
  setCategoryItem: item => dispatch(setCategoryItem(item))
});

const mapStatetoProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  currentStaff: selectCurrentStaff,
})

export default withRoot(connect(mapStatetoProps, mapDispatchToProps)(App)
);
