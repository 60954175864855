import React, { useEffect } from "react";
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/Lock';
import CircularProgress from '@material-ui/core/CircularProgress'
import app from "../../firebase/base";
import { useHistory } from "react-router-dom"
import firebase from "firebase";

import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectCurrentStaff } from '../../redux/staff/staff.selector'
import { selectCurrentUser } from '../../redux/user/user.selector'
import {
  setCurrentStaff
} from '../../redux/staff/staff.actions';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://sp2smalaysia.com/">
      Sp2s Malaysia
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const styles = theme => ({
  root: {
    display:"flex",
    justifyContent: "center",
    padding: 20
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 3),
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }
});

function SignIn(props) {

  const classes = props.classes;
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [isChecked, setIsChecked] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  const [isEmail, setIsEmail] = React.useState(true);
  const [isPassword, setIsPassword] = React.useState(true);
  const [errorMsg, setErrorMsg] = React.useState("Please try logging again");
  const [errorMsgEnaled, setErrorMsgEnaled] = React.useState(false);
  let history = useHistory();

  useEffect(() => {

    setLoading(false)

  }, []);

  const handleSignIn = async (event) => {

    event.preventDefault();

    setErrorMsg("Please try logging again")
    setErrorMsgEnaled(false)
    let email_result = true;
    let password_result = false;

    if(password.length > 6)
    {
      password_result = true;
      setIsPassword(true)
    }
    else
    {
      setIsPassword(false)
    }

    if(email_result)
    {
      setIsEmail(true)
    }
    else
    {
      setIsEmail(false)
    }
    
    if(email_result === true && password_result === true){
      let userRef = firebase.database().ref("pos/" + props.currentUser.id + "/users");

      userRef.on('value', snapshot => {
      
        if(snapshot.exists()){

          var loginSuccess = false
          snapshot.forEach(child => {
            var itemsVal = child.val();

            if(itemsVal.email && itemsVal.password){
              if(itemsVal.email.toLocaleLowerCase() === email.toLocaleLowerCase() && itemsVal.password === password)
              {
                
                loginSuccess = itemsVal;
              }
            }
            

          })

          if(loginSuccess){
            loginSuccess.concept_store_id = props.currentUser.id;
            props.setCurrentStaff(loginSuccess)
            //localStorage.staff = loginSuccess;
            history.push("/")
          }
          else
          {
            setIsEmail(false)
            setIsPassword(false)
          }

        }
        else
        {
          setIsEmail(false)
          setIsPassword(false)
        }

      })

    }

  }

  const passwordhandleChange = (event) => {
    setPassword(event.target.value)
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value)
  }

  return (
    <Grid container component="main" className={classes.root}>
      <Grid item component={Paper} elevation={0} style={{ marginTop: 50, backgroundColor: "#1c1c1c" }}>
        <Grid style={{ display: 'flex', justifyContent: "space-between", height: 150, backgroundColor: "#212121", borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>
          
          <Grid>
            <Typography color="primary" variant='body1' style={{  marginLeft: 30, marginTop: 20, fontWeight: "bold"}}>
              Cashier Login
            </Typography>
          </Grid>
          
          <Grid style={{ height: "100%", display: "flex", alignItems: "flex-end"}}>
            <img style={{ maxHeight: 100 }} height="100%" src="assets/login/profile-img.png" alt="top-login-bg" />
            </Grid>
        </Grid>
        <Grid style={{ display: 'flex', justifyContent: "center", alignItems: 'center', position: "absolute", backgroundColor: "#3d3d3d", width: 80, height: 80, marginLeft: 30, marginTop: -40, borderRadius: 50 }}>
          <LockIcon fontSize="large"/>
        </Grid>
        <div className={classes.paper}>
          
          {loading ? 
          
          <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center', marginTop: 70, marginBottom: 70  }}>
              <CircularProgress 
                disableShrink
                color="primary" />
          </div>
          
          : 
          
          props.currentStaff ? 
          <Grid style={{ marginTop: 15, display: 'flex', flexDirection: "column", width: "100%"}} >
            <Typography variant='body2' style={{ marginTop: 20}}>
              Name
            </Typography>
            <Typography variant='h6' >
              {props.currentStaff.last_name && props.currentStaff.first_name ? props.currentStaff.last_name + " " + props.currentStaff.first_name : "Unnamed" }
            </Typography>
            <Typography variant='body2' style={{ marginTop: 10}}>
              Email
            </Typography>
            <Typography variant='h6' >
              {props.currentStaff.email}
            </Typography>

            <Typography variant='body2' style={{ marginTop: 10}}>
              Role
            </Typography>
            <Typography variant='h6' >
              {props.currentStaff.role}
            </Typography>
            <Button
                style={{textTransform: 'none'}}
                fullWidth
                color="primary"
                variant="contained"
                className={classes.submit}
                disableElevation
                onClick={()=> {
                  props.setCurrentStaff({ concept_store_id: props.currentUser.id, logout: true })
                  //localStorage.setItem('staff', null);
                 // history.push("/")
                }}
              >
              Log out
              </Button>
          </Grid>
          : <form className={classes.form} onSubmit={handleSignIn}>
              {errorMsgEnaled ? <Grid style={{ marginTop: 15, display: 'flex', justifyContent: 'center', alignItems: "center"}} >
                <Typography style={{ textAlign: "center"}} align="center" color="error" variant='body2'>
                  {errorMsg}
                </Typography> 
              </Grid>:null}
               
              <Typography variant='body2' style={{ marginTop: 20}}>
                Email
              </Typography>
              <TextField
                error={isEmail? false: true}
                variant="outlined"
                margin="normal"
                required
                autoComplete='off'
                fullWidth
                id="email"
                name="email"
                size="small"
                style={{ marginTop: 5}}
                value={email}
                onChange={handleEmailChange}
              />
              <Typography variant='body2' style={{ marginTop: 10 }}>
                Password
              </Typography>
              <TextField
                error={isPassword? false: true}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                type="password"
                id="password"
                size="small"
                style={{ marginTop: 5}}
                value={password}
                onChange={passwordhandleChange}
              />
            
              <Button
                style={{textTransform: 'none'}}
                fullWidth
                color="primary"
                variant="contained"
                className={classes.submit}
                disableElevation
                type="submit"
              >
              Log In
              </Button>
          </form>}
        </div>
      </Grid>
    </Grid>);
}

SignIn.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => ({
  setCurrentStaff: item => dispatch(setCurrentStaff(item)),
});


const mapStatetoProps = createStructuredSelector({
  currentStaff: selectCurrentStaff,
  currentUser: selectCurrentUser
})

export default withStyles(styles)(connect(mapStatetoProps, mapDispatchToProps)(SignIn));