import React, { useMemo, useEffect } from "react";
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress'
import app from "../../firebase/base";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import firebase from "firebase";
import { useHistory } from "react-router-dom"

import {useDropzone} from 'react-dropzone'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';


import { connect } from 'react-redux'
import {
  setCurrentUser
} from '../../redux/user/user.actions';

//styles
const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 1,
  borderRadius: 2,
  borderColor: '#d8d8d8',
  borderStyle: 'dashed',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #d8d8d8',
  marginBottom: 8,
  marginRight: 8,
  width: 70,
  height: 70,
  padding: 4,
  boxSizing: 'border-box'
};


const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};

var upload_count = 0;
var files_uploaded = [];

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://sp2smalaysia.com/">
      Sp2s Malaysia
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const styles = theme => ({
  root: {
    display:"flex",
    justifyContent: "center",
    padding: 20
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 3),
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }
});

function SignIn(props) {

  const classes = props.classes;
  const [userFill, setUserFill] = React.useState({
    store_name: "",
    status_users: "new_user",
    email: "",
    point_users: "0",
    role:"store", 
    document: [],
    salesman_in_charge: ""
  });
  const [loading, setLoading] = React.useState(true);

  const [isFile, setIsFile] = React.useState(true);
  const [isSalesman, setIsSalesman] = React.useState(true);
  const [activeSalesman, setActiveSalesman] = React.useState(false);
  const [role, setRole] = React.useState("concept_store");
  const [area, setArea] = React.useState("1");
  const [salesman, setSalesman] = React.useState([]);
  const [salesmanID, setSalesmanID] = React.useState("");

  const [files, setFiles] = React.useState([]);

  let history = useHistory();


  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
      accept: 'image/*,application/pdf',
      multiple: true,
      maxFiles:5,
      onDrop: acceptedFiles => {
          setFiles(acceptedFiles.map(file => Object.assign(file, {
              preview: URL.createObjectURL(file)
          })));
      }
  });

  useEffect(() => {
    let salesRef = app.database().ref("salesman");

    salesRef.once('value', snapshot => {
      
      if(snapshot.exists()){
        let _salesman = [];

        snapshot.forEach(child => {
          var itemsVal = child.val();
          if(itemsVal.role === "salesman"){
            _salesman.push(itemsVal)
          }
        })

        setSalesman(_salesman)
      }

      setLoading(false)
    })

  }, []);

  const thumbs = files.map((file, index) => {

    if(file.type === "application/pdf"){
        
          return(
              <Grid key={file.name+index} style={{ display:"flex", flexDirection: "column"}}>
                  <Grid style={{overflow: "hidden", textOverflow: "ellipsis", width: 70, marginBottom: 10}}>
                      <Typography variant="caption" noWrap={true}>{file.name}</Typography>
                  </Grid>
                  
                  <Grid style={thumb} key={file.name}>
                      <Grid style={{ display: "flex", justifyContent:'center', alignItems: 'center', width: "100%"}}>
                          <PictureAsPdfIcon color="primary" style={{ fontSize: 36}}/>
                      </Grid>
                  </Grid>
              </Grid>
            
          )
      }
      else{
          return(
              <Grid key={file.name+index} style={{ display:"flex", flexDirection: "column"}}>
                  <Grid style={{overflow: "hidden", textOverflow: "ellipsis", width: 70, marginBottom: 10}}>
                      <Typography variant="caption" noWrap={true}>{file.name}</Typography>
                  </Grid>
                  
                  <Grid style={thumb} key={file.name}>
                      <Grid style={{ display: "flex", justifyContent:'center', alignItems: 'center', width: "100%", overflow: 'hidden'}}>
                          <img
                          src={file.preview}
                          style={img}
                          alt={file.name}
                          />
                      </Grid>
                  </Grid>
              </Grid>
          )
      }
  });

  const style = useMemo(() => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
  }), [
      isDragActive,
      isDragReject,
      isDragAccept,
  ]);

  const onClickClear = () => {
    acceptedFiles.length = 0;
    setFiles([])
  }

  const completedUpload = () =>{
    upload_count++;
    uploadFiles();
  };

  const handleUploadSuccess = (metadata, filename) => {

    firebase.storage().ref("user_register")
    .child(metadata.name)
    .getDownloadURL().then((url) => {

        var postData = {
            name:filename,
            file_name: metadata.name,
            url: url,
            size: metadata.size,
            created: firebase.database.ServerValue.TIMESTAMP,
            type: metadata.contentType,
            full_path: metadata.fullPath,
            user: firebase.auth().currentUser.displayName
        };

        files_uploaded.push(postData)
        completedUpload()
    })
    
  }

  const saveData = () =>{
    let file_result = false;
    let salesman_result = false;

    if(acceptedFiles.length > 0)
    {
      file_result = true;
      setIsFile(true)
    }
    else
    {
      file_result = false;
      setIsFile(false)
    }

    if(activeSalesman){
      var currentIndex = salesman.map(val=> val.id).indexOf(salesmanID.toUpperCase())

      if(currentIndex !== -1)
      {
        salesman_result = true;
        setIsSalesman(true)
      }
      else
      {
        salesman_result = false;
        setIsSalesman(false)
      }
    }
    
    if(role === "salesman"){
      if(file_result === true){
      
        upload_count = 0;
        setLoading(true)
        uploadFiles();
      }
    }
    else
    {

      
      if(activeSalesman){
        if(file_result === true && salesman_result ===  true){
      
          upload_count = 0;
          setLoading(true)
          uploadFiles();
        }
      }
      else{
        if(file_result === true){
      
          upload_count = 0;
          setLoading(true)
          uploadFiles();
        }
      }
    }
  }

  const saveOrder = (uuid) => {

    userFill.id = "SP2SCT"+ area + parseInt(uuid).toLocaleString('en-US', {minimumIntegerDigits: 4, useGrouping:false});
    userFill.phone = app.auth().currentUser.phoneNumber;
    userFill.created = firebase.database.ServerValue.TIMESTAMP;
    userFill.role = role;
    userFill.document = files_uploaded;

    if(activeSalesman){
      var currentIndex = salesman.map(val=> val.id).indexOf(salesmanID.toUpperCase())
      userFill.salesman_in_charge = salesman[currentIndex].id;
    }
    

    var updates = {};
    updates['concept_store/' + userFill.id] = userFill;
    
    firebase.database().ref().update(updates).then(()=>{
      acceptedFiles.length = 0;
      setFiles([])
      //setLoading(false)
      window.location.reload(false);
    });
      
  }

  const uploadFiles = () => {
    if(Array.from(acceptedFiles).length === upload_count){

      let areaUUIDRef = firebase.database().ref("concept_area/" + area )

      areaUUIDRef.once('value', snapshot => {
        if(snapshot.exists()){
          let val = snapshot.val()

          let newUUID = Number(val.uuid)+1
          saveOrder(newUUID)
          firebase.database().ref("concept_area/" + area).update({ uuid: newUUID })
        }
        else{
          saveOrder(1)
          firebase.database().ref("concept_area/" + area).update({ uuid: 1 })
        }

      })

        
    }else{
        
        var filename = Array.from(acceptedFiles)[upload_count].name;
        var upload_task = firebase.storage().ref("user_register").child(new Date().getTime() +"_"+Array.from(acceptedFiles)[upload_count].name).put(acceptedFiles[upload_count]);
        upload_task.then((snapshot) => {
            handleUploadSuccess(snapshot.metadata, filename);
        }).catch((error)=>{
            console.log(error.message)
        })

    }
  }

  const handleSignUp = async (event) => {
    
    event.preventDefault();

    let storeRef = app.database().ref("concept_store");

    storeRef.once('value', snapshot => {
      
      if(snapshot.exists()){
        var storeRegistered = false;

        snapshot.forEach(child => {
          var itemsVal = child.val();

          if(itemsVal.phone === app.auth().currentUser.phoneNumber){
            storeRegistered = true
          }
        })

        if(storeRegistered){
          window.location.reload(false);
        }
        else
        {
          saveData();
        }

      }
      else
      {
        saveData();
      }

    })

  }

  const handleUserFillChange = (prop) => (event) => {
    setUserFill({ ...userFill, [prop]: event.target.value });
  };

  const handleChangeRole = (event) => {
    setRole(event.target.value)
  }

  const handleChangeArea = (event) => {
    setArea(event.target.value)
  }

  const handleChangeSalesmanID = (event) => {
    setSalesmanID(event.target.value)
  }

  const handleChangeActiveSalesman = (event) => {
    setActiveSalesman(event.target.checked)
  }


  return (
    <Grid container component="main" className={classes.root}>
      <Grid item component={Paper} elevation={0} style={{ width: 400, marginTop: 50, backgroundColor: "#1c1c1c" }}>
        <Grid style={{ display: 'flex', justifyContent: "space-between", height: 120, backgroundColor: "#212121", borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>
          
          <Grid>
            <Typography color="primary" variant='body1' style={{  marginLeft: 30, marginTop: 40, fontWeight: "bold"}}>
              New User
            </Typography>
            <Typography color="primary" variant='body2' style={{  marginLeft: 30 }}>
              Sign up to continue
            </Typography>
          </Grid>
          
          <img height="100%" src="assets/login/profile-img.png" alt="top-login-bg" />
        </Grid>
        <div className={classes.paper}>
          
          {loading ? 
          
          <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center', marginTop: 70, marginBottom: 70  }}>
              <CircularProgress 
                disableShrink
                color="primary" />
          </div>
          
          : 
          
          <form className={classes.form} onSubmit={handleSignUp}>
              <Grid spacing={2} container >
                <Grid item md={12} xs={12}>
                  <Typography variant='body2'>
                  Store Name*
                  </Typography>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    autoComplete='off'
                    fullWidth
                    id="storeName"
                    name="Store Name"
                    size="small"
                    style={{ marginTop: 5}}
                    value={userFill["store_name"]}
                    onChange={handleUserFillChange("store_name")}
                  />
                </Grid>
              </Grid>

              <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Role</InputLabel>
                  <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={role}
                  required
                  label="Status"
                  onChange={handleChangeRole}
                  >
                  <MenuItem value={"concept_store"}>Concept Store</MenuItem>
                  </Select>
              </FormControl>

              <FormControl style={{ marginTop: 10}} fullWidth>
                  <InputLabel id="demo-simple-select-label">Area</InputLabel>
                  <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={area}
                  required
                  label="Status"
                  onChange={handleChangeArea}
                  >
                  <MenuItem value={"1"}>Johor</MenuItem>
                  <MenuItem value={"2"}>Kedah</MenuItem>
                  <MenuItem value={"3"}>Kelantan</MenuItem>
                  <MenuItem value={"4"}>Melaka</MenuItem>
                  <MenuItem value={"5"}>Negeri Sembilan</MenuItem>
                  <MenuItem value={"6"}>Pahang</MenuItem>
                  <MenuItem value={"7"}>Pulau Pinang</MenuItem>
                  <MenuItem value={"8"}>Perak</MenuItem>
                  <MenuItem value={"9"}>Perlis</MenuItem>
                  <MenuItem value={"10"}>Selangor</MenuItem>
                  <MenuItem value={"11"}>Terangganu</MenuItem>
                  <MenuItem value={"12"}>Sabah</MenuItem>
                  <MenuItem value={"13"}>Sarawak</MenuItem>
                  </Select>
              </FormControl>

              <Grid style={{ marginTop: 20 }}>
                <Grid>
                    <Grid {...getRootProps({style})}>
                        <input {...getInputProps()} />
                        { role ==="store" ? <Typography variant="body2">Upload SSM Document Here</Typography>: <Typography variant="body2">Upload Your IC Here</Typography>}
                    </Grid>
                    <aside style={thumbsContainer}>
                        {thumbs}
                    </aside>
                </Grid>
                <Grid style={{ paddingBottom: 20  }}>
                    <Typography color="initial" variant='caption'>
                    Image/Jpeg/jpg/pdf - Size below 1MB 
                    </Typography>
                </Grid>
                <Grid>
                    <Button disabled={acceptedFiles.length > 0 ? false : true } onClick={onClickClear} variant="outlined" color="primary">
                        Clear
                    </Button>
                </Grid>
                <Grid  style={{ marginTop: 10 }}>
                {isFile ? null : <Typography color="error" variant='caption'>
                {"Please upload at least one file"}
                </Typography>}
                </Grid>
              </Grid>

              {role === "salesman" ? null:<FormControlLabel
                control={
                  <Checkbox
                    checked={activeSalesman}
                    onChange={handleChangeActiveSalesman}
                    name="Salesman"
                    color="primary"
                  />
                }
                label="Salesman"
              />}

              {role === "salesman" ? null : activeSalesman ? <Grid>
                <Typography variant='body2' style={{ marginTop: 10}}>
                  Salesman ID
                </Typography>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  autoComplete='off'
                  id="saleid"
                  name="saleid"
                  size="small"
                  style={{ marginTop: 5}}
                  value={salesmanID}
                  onChange={handleChangeSalesmanID}
                />
                {isSalesman ? null : <Typography color="error" variant='caption'>
                {"No Found Salesman ID"}
                </Typography>}
              </Grid> : null}
              
            
              <Button
                style={{textTransform: 'none'}}
                fullWidth
                color="primary"
                variant="contained"
                className={classes.submit}
                disableElevation
                type="submit"
              >
              Register
              </Button>

              <Button
                style={{textTransform: 'none', width: "100%"}}
                fullWidth
                color="primary"
                disableElevation
                onClick={()=> app.auth().signOut().then(()=> { history.push("/") })}
              >
              Login with Another Phone Number
              </Button>

              <Grid style={{ display: 'flex', justifyContent: "center", marginTop: 20 }}>
              <img height="80" src="assets/logo/logo-white.png" alt="Sp2s logo"/>
              </Grid>
              
              
              <Grid style={{ display: 'flex', justifyContent: "center", marginTop: 20 }}>
                <Copyright />
              </Grid>
          </form>}
        </div>
      </Grid>
    </Grid>);
}

SignIn.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => ({
  setCurrentUser: item => dispatch(setCurrentUser(item)),
});

export default withStyles(styles)(connect(null, mapDispatchToProps)(SignIn));