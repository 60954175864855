import React, { useEffect } from 'react';
import Table from '../../../modules/components/Table'
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddEditUsers from './AddEditUsers';
import firebase from "firebase";
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectCurrentUser } from '../../../redux/user/user.selector'

function Index(props) {

    const [headCells, setHeadCells] = React.useState([]);
    const [headMobileCells, setHeadMobileCells] = React.useState([]);
    const [searchValue, setSearchValue] = React.useState("");
    const [searchCell, setSearchCell] = React.useState([]);
    const [displayInfo, setDisplayInfo] = React.useState([]);
    const [editCells, setEditCells] = React.useState([]);
    const [addCells, setAddCells] = React.useState([]);
    const [addCellsName, setAddCellsName] = React.useState([]);
    const matches = useMediaQuery(theme => theme.breakpoints.up('sm'));

    useEffect(() => {

        setHeadCells([
            { id: 'email', label: 'Email' },
            { id: 'role', label: 'Role' },
            { id: 'status_users', label: 'Status' },
        ])

        setHeadMobileCells([
            { id: 'email', label: 'Email' },
        ])
    
        setSearchCell([
            { id: 'email', label: 'Email' },
        ])

        setDisplayInfo([
            { id: 'id', label: 'ID' },
            { id: 'role', label: 'Role' },
            { id: 'created', label: 'Registered' },
            { id: 'email', label: 'Email' },
            { id: 'status_users', label: 'Status' },
        ])

        setEditCells([
            { id: 'email', label: 'Email' },
            { id: 'password', label: 'Password' },
            { id: 'status_users', label: 'Status' },
            { id: 'role', label: 'Role' },
        ])

        setAddCells([
            { id: 'email', label: 'Email' },
            { id: 'password', label: 'Password' },
            { id: 'role', label: 'Role' },
        ])

        setAddCellsName({ 
            first_name: '',
            last_name: '',
            created: firebase.database.ServerValue.TIMESTAMP,
            updated: firebase.database.ServerValue.TIMESTAMP,
            email: '',
            phone: '',
            status_users: '',
            role: '',
         })
    
        setSearchValue("email")
        
    }, []);

    return (
    <Grid>
        <Table 
            {...props}
            customizeDialog={AddEditUsers}
            control={true}
            orderBy="created" 
            hideMove={true}
            hideAllMove={true}
            hideAllDelete={true}
            hideCheckBox={true}
            editButton={true}
            data={"pos/" + props.currentUser.id + "/users"}
            addItemButton={true}
            addItemText="Add Users"
            addItemIcon={<AddCircleIcon/>}
            addCells={addCells}
            addCellsName={addCellsName}
            folder_data={"pos/" + props.currentUser.id + "/users_zone"}
            displayInfo={displayInfo}
            editCells={editCells}
            matches={matches}
            headCells={matches ? headCells : headMobileCells} 
            searchValue={searchValue} 
            searchCell={searchCell}/>
    </Grid>
    )

}

const mapStatetoProps = createStructuredSelector({
    currentUser: selectCurrentUser
})
  
export default connect(mapStatetoProps)(Index);