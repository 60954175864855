import { createTheme } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';

const rawTheme = createTheme({
  palette: {
    type: 'light',
    },
    typography: {
      fontFamily: "'Poppins', sans-serif",
      fontSize: 12,
      color: "black",
      fontWeight: 500
    },
    props: {
      MuiTab: {
        disableRipple: true,
      },
    }
  });

const theme = {
  ...rawTheme,
  overrides: {
  },
};

export default theme;
