import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import firebase from "firebase";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
    root: {
      display:"flex",
      justifyContent: "center",
      padding: 20
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 3),
    },
    paper: {
      margin: theme.spacing(4, 4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }
});

function Index(props) {        

  const [allProduct, setAllProduct] = React.useState([]);
  const [loading, setLoading] = React.useState(true);


    useEffect(() => {
      let productRef = firebase.database().ref("products")
  
      productRef.once('value', snapshot => {
        if(snapshot.exists()){

          let items = [];

          snapshot.forEach(child => {
            var itemsVal = child.val();

           if(!itemsVal.product_point){
            console.log(itemsVal.id)
           }
            

            items.push(itemsVal)

          })

          setAllProduct(items)
          setLoading(false)
        }
        else{
          setLoading(false)
        }
      })
      
    }, [props]);

    if(loading){
      return (
      <Grid style={{ display: 'flex',  justifyContent:'center', alignItems:'center',padding: 70 }}>
        <CircularProgress 
          disableShrink
          color="primary" />
      </Grid>);
    }

    return (
        <Grid style={{ padding: 30 , maxWidth: 1000 }}>
            {props.cartPointList(allProduct)}
        </Grid>
    )

}

Index.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Index);