import React, { useEffect } from 'react';
import Table from '../../../modules/components/Table'
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddEditShipping from './AddEditShipping';
import firebase from "firebase";

export default function Index(props) {

    const [headCells, setHeadCells] = React.useState([]);
    const [headMobileCells, setHeadMobileCells] = React.useState([]);
    const [searchValue, setSearchValue] = React.useState("");
    const [searchCell, setSearchCell] = React.useState([]);
    const [displayInfo, setDisplayInfo] = React.useState([]);
    const [editCells, setEditCells] = React.useState([]);
    const [addCells, setAddCells] = React.useState([]);
    const [addCellsName, setAddCellsName] = React.useState([]);
    const matches = useMediaQuery(theme => theme.breakpoints.up('sm'));

    useEffect(() => {

        setHeadCells([
            { id: 'name', label: 'Name' },
            { id: 'amount', label: 'Amount' }
        ])

        setHeadMobileCells([
            { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
        ])
    
        setSearchCell([
            { id: 'id', label: 'ID' },
            { id: 'name', label: 'Name' },
        ])

        setDisplayInfo([
            { id: 'id', label: 'ID' },
            { id: 'name', label: 'Name' },
            { id: 'created', label: 'Created' },
            { id: 'updated', label: 'Updated' },
            { id: 'amount', label: 'Amount' }
        ])

        setEditCells([
            { id: 'name', label: 'Name' },
            { id: 'amount', label: 'Amount' }
        ])

        setAddCells([
            { id: 'name', label: 'Name' },
            { id: 'amount', label: 'Amount' }
        ])

        setAddCellsName({ 
            name: '', 
            created: firebase.database.ServerValue.TIMESTAMP,
            updated: firebase.database.ServerValue.TIMESTAMP,
            amount: ''
         })
    
        setSearchValue("name")
        
    }, []);

    return (
    <Grid>
        <Table 
             {...props}
            control={true}
            disabledMove={true}
            customizeDialog={AddEditShipping}
            orderBy="created" 
            data="shipping" 
            addItemButton={true}
            addItemText="Add shipping"
            addItemIcon={<AddCircleIcon/>}
            addCells={addCells}
            addCellsName={addCellsName}
            folder_data="shipping_zone"
            displayInfo={displayInfo}
            editCells={editCells}
            matches={matches}
            headCells={matches ? headCells : headMobileCells} 
            searchValue={searchValue} 
            searchCell={searchCell}/>
    </Grid>
    )

}
  