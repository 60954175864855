import React, { useEffect, useRef }  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import firebase from "firebase";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Receipt from './Receipt'
import { useReactToPrint } from 'react-to-print';


const useStyles = makeStyles((theme) => ({
  root: {
    display:"flex",
    flexGrow: 1,
    flexDirection: "column",
    alignItems: "center",
  },
}));



function Index (props){

  const classes = useStyles();
  const [ordersDetails, setOrdersDetails] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const Resume = React.forwardRef((props, ref) => {

    return (
      <Grid style={{ padding: 20  }} ref={ref}>
          <Receipt ordersDetails={ordersDetails}/>
      </Grid>
    )
  })

  useEffect(() => {

    if(props.match.params.id){
      let ordersRef = firebase.database().ref("pos/" + firebase.auth().currentUser.displayName + "/orders/"+ props.match.params.id);

      ordersRef.on('value', snapshot => {
        
        if(snapshot.exists()){
          setOrdersDetails(snapshot.val())
        }
        else{
          setOrdersDetails([])
        }
  
        setLoading(false)
      })
    }
    
    
  }, [props]);

  if(loading){
    return (
    <Grid className={classes.root}>
      <Grid style={{ marginTop: 70 }}>
        <CircularProgress 
          disableShrink
          color="primary" />
      </Grid>
    </Grid>);
  }


  return (
    <Grid className={classes.root}>
      <Button onClick={handlePrint} color="primary" variant="contained" style={{ marginTop : 20 }}>Print </Button>
      <Resume ref={componentRef}  />
      
    </Grid>
    
  );
  
}

export default Index;