import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import firebase from "firebase";

import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';

import QrReader from 'react-qr-reader'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { PatternFormat  } from 'react-number-format';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

const styles = theme => ({
    root: {
      display:"flex",
      justifyContent: "center",
      padding: 20
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 3),
    },
    paper: {
      margin: theme.spacing(4, 4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }
});

function Index(props) {

    const [paid, setPaid] = React.useState("");

    const classes = props.classes;
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [isChecked, setIsChecked] = React.useState("");
    const [loading, setLoading] = React.useState(true);

    const [isEmail, setIsEmail] = React.useState(true);
    const [isPassword, setIsPassword] = React.useState(true);
    const [errorMsg, setErrorMsg] = React.useState("Please try logging again");
    const [errorMsgEnaled, setErrorMsgEnaled] = React.useState(false);

    const [loginSuccess, setLoginSuccess] = React.useState(false);

    const [isWarranty, setIsWarranty] = React.useState(false);

    const [isCamera, setIsCamera] = React.useState(null);
    const [isDuplicate, setIsDuplicate] = React.useState(false);
    const [isWarrantyIDInvalid, setIsWarrantyIDInvalid] = React.useState(false);
    const [isFoundId, setIsFoundId] = React.useState(false);
    const [selectedCamera, setSelectedCamera] = React.useState("environment");

    const [warrantyArray, setWarrantyArray] = React.useState([]);
    const [salesman, setSalesman] = React.useState([]);
    const [selectedSale, setSelectedSale] = React.useState('');

    useEffect(() => {

      if(props.selectedClient){

        let _warrantyArray = []

        props.cartItems.forEach(val=>{
   
          for (let i = 0; i < val.quantity; i++) {
            _warrantyArray.push({
              product_id: val.id,
              customer_id: props.selectedClient[0].customer_id,
              phone: props.selectedClient[0].phone,
              store_id: firebase.auth().currentUser.displayName,
              created: firebase.database.ServerValue.TIMESTAMP
            })
          }

        })

        setWarrantyArray(_warrantyArray)


      }

      let salesmanRef = firebase.database().ref("pos/" + props.currentUser.id + "/salesman");
    
      salesmanRef.once('value', snapshot => {
        
        if(snapshot.exists()){

          let _salesmans = []
          
          snapshot.forEach(val => {
            var _sales = val.val();
            _salesmans.push(_sales)
          })

          setSalesman(_salesmans)
        }

      })
      
    }, []);

    const handleChangePaid = (e) => {

        props.handleSetPaid(e.target.value)
        setPaid(e.target.value)
    }

    const handleChangeCardNumber = (e) => {

      props.handleSetCardNumber(e.value)
    }

    const handleSetApprovalCode = (e) => {

      props.handleSetApprovalCode(e.value)
    }

    const handleChangeEwalletRef = (e) =>{
      props.handleSetEwalletRef(e.target.value)
    }

    const handleChangeCamera = (e) => {
      setSelectedCamera(e.target.value)
    }

    const handleChangeWarrantyId = (index, value) => {
      
      let _warrantyArray = [...warrantyArray]
      _warrantyArray[index].warranty_id = value;
      _warrantyArray[index].id = value;

      setWarrantyArray(_warrantyArray)
    }

    const handleSignIn = async (event) => {

        event.preventDefault();
    
        setErrorMsg("Please try logging again")
        setErrorMsgEnaled(false)
        let email_result = true;
        let password_result = false;
    
        if(password.length > 6)
        {
          password_result = true;
          setIsPassword(true)
        }
        else
        {
          setIsPassword(false)
        }
    
        if(email_result)
        {
          setIsEmail(true)
        }
        else
        {
          setIsEmail(false)
        }
        
        if(email_result === true && password_result === true){
          let userRef = firebase.database().ref("pos/" + firebase.auth().currentUser.displayName + "/users");
    
          userRef.on('value', snapshot => {
          
            if(snapshot.exists()){
    
              var loginSuccess = false
              snapshot.forEach(child => {
                var itemsVal = child.val();
    
                if(itemsVal.email === email && itemsVal.password === password && (itemsVal.role === "manager" || itemsVal.role === "admin"))
                {
                  
                  loginSuccess = itemsVal;
                }
    
              })
    
              if(loginSuccess){
                props.enableAgreeButton();
                setLoginSuccess(true)
              }
              else
              {
                setIsEmail(false)
                setIsPassword(false)
              }
    
            }
            else
            {
              setIsEmail(false)
              setIsPassword(false)
            }
    
          })
    
        }
    
      }

    const passwordhandleChange = (event) => {
        setPassword(event.target.value)
    }

    const handleChangeSales = (event) => {
      setSelectedSale(event.target.value)
      props.handleSetSalesman(event.target.value)
    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value)
    }

    const scanQR = (row) => {
      setIsCamera(row)
    }

    const handleScan = (data) => {
      if(data){
          if(data.startsWith("http://sbr.fangcuanhuoxitong.com/Home/Index/"))
          {
            const resultArray = data.split("/");
            let _warrantyArray = [...warrantyArray]
            _warrantyArray[isCamera].warranty_id = resultArray[5];
            _warrantyArray[isCamera].id = resultArray[5];
            setWarrantyArray(_warrantyArray)
            setIsCamera(null)
          }
      }
    }

    const handleError = (err) =>{
        console.error(err)
    }


    const handleAgree = () =>{

      if(props.selectedClient){

        let _warranty_id = []
        let _warranty_id_invalid = false;

        warrantyArray.forEach(val=>{

          if(val.warranty_id)
          {
            if(val.warranty_id.length < 9){
              _warranty_id_invalid = true
            }
            _warranty_id.push(val.warranty_id)
          }
          
          
        })
        let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) != index)

        if(findDuplicates(_warranty_id).length > 0){
          setIsDuplicate(true)
        }
        else{
          setIsDuplicate(false)
        }

        if(_warranty_id_invalid){
          setIsWarrantyIDInvalid(true)
        }
        else
        {
          setIsWarrantyIDInvalid(false)
        }

        firebase.database().ref("warranty").once('value', snapshot => {
          var warrantyRegistered = false;
                    
          snapshot.forEach(child => {
              var itemsVal = child.val();
  
              var currentIndex = warrantyArray.map(val => val.id).indexOf(itemsVal.id);
              
              if(currentIndex != -1)
              {
                  warrantyRegistered =true;
              }
  
          })

          if(!warrantyRegistered){
            setIsFoundId(false)
          }
          else
          {
            setIsFoundId(true)
          }
          
          if(!warrantyRegistered && !_warranty_id_invalid && findDuplicates(_warranty_id).length === 0)
          {
            props.handleAgree(warrantyArray)
          }
        })
        
      }
      else
      {
        props.handleAgree(null)
      }

      
    }

    if(isCamera){
      return(
        <Grid style={{ width: "100%", heigth: 300, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
          <Grid style={{ display: "flex", justifyContent: "space-between", marginTop: 20 }}>
            <Button
                  style={{textTransform: 'none', marginRight: 20}}
                  color="primary"
                  variant="contained"
                  disableElevation
                  onClick={()=> setIsCamera(null)}
              >
              Back
              </Button>
           <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedCamera}
                      onChange={handleChangeCamera}
                  >
                  <MenuItem value={"environment"}>Back Camera</MenuItem>
                  <MenuItem value={"user"}>Front Camera</MenuItem>
                  </Select>
          </Grid>
          
          <Grid style={{ width: "100%" }}>
          <QrReader
                        delay={50}
                        onError={handleError}
                        onScan={handleScan}
                    />
          
          </Grid>
      </Grid>
      )
    }

    if(props.waiverPrice && Number(props.waiverPrice) !== 0 && !loginSuccess){
        return (
            <Grid style={{ padding: 30 , maxWidth: 1000  }}>
                <form className={classes.form} onSubmit={handleSignIn}>
                    {errorMsgEnaled ? <Grid style={{ marginTop: 15, display: 'flex', justifyContent: 'center', alignItems: "center"}} >
                        <Typography style={{ textAlign: "center"}} align="center" color="error" variant='body2'>
                        {errorMsg}
                        </Typography> 
                    </Grid>:null}
                    
                    <Typography variant='body2' style={{ marginTop: 20}}>
                        Email
                    </Typography>
                    <TextField
                        error={isEmail? false: true}
                        variant="outlined"
                        margin="normal"
                        required
                        autoComplete='off'
                        fullWidth
                        id="email"
                        name="email"
                        size="small"
                        style={{ marginTop: 5}}
                        value={email}
                        onChange={handleEmailChange}
                    />
                    <Typography variant='body2' style={{ marginTop: 10 }}>
                        Password
                    </Typography>
                    <TextField
                        error={isPassword? false: true}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        type="password"
                        id="password"
                        size="small"
                        style={{ marginTop: 5}}
                        value={password}
                        onChange={passwordhandleChange}
                    />
                    
                    <Button
                        style={{textTransform: 'none'}}
                        fullWidth
                        color="primary"
                        variant="contained"
                        className={classes.submit}
                        disableElevation
                        type="submit"
                    >
                    Log In
                    </Button>
                </form>
            </Grid>
        )
    }
    

    let deduce_amount = Number(props.amount) - Number(paid).toFixed(2);
    let warrantyCount = 0;

    return (
        <Grid style={{ padding: 30 , maxWidth: 1000 }}>
            <Grid>
                    {props.cartItems.map((val, index)=>{

                      let warranty_array = []
                      
                      for (let i = 0; i < val.quantity; i++) {
                        
                        warranty_array.push(warrantyCount)
                        warrantyCount++;
                      }
                    

                      return(
                      <Grid container key={"product-card-"+index} >

                      

                      <Grid item xs={12} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}} >
                        <Grid style={{ display: "flex", flexDirection: "row" }} >
                             <Typography style={{ fontSize: 14, marginTop: 10, width: 20  }} align="left" variant="body2" color="textSecondary" component="p">
                                {index+1 +". "}
                            </Typography>
                            <Typography style={{ fontSize: 14, marginTop: 10 }} align="left" variant="body2" color="textSecondary" component="p">
                                {val.name + " x " + val.quantity}
                            </Typography>
                        </Grid>
                        <Grid>
                            <Typography style={{ fontSize: 14, marginTop: 10 }} align="right" variant="body2" color="textSecondary" component="p">
                                {"RM " +(val.quantity * val.retail_price).toFixed(2)}
                            </Typography>
                        </Grid>
                      </Grid>
                      {props.selectedClient ? <Grid item xs={12} >
                      {warranty_array.map(val => 
                        {
                          return <Grid style={{ display: "flex", flexDirection: "row", width: "100%" }} >
                          <Grid  style={{ padding: 10 }}>
                              <IconButton onClick={()=> scanQR(val.toString())} color="primary" 
                              aria-label="scan qr" component="span">
                                  <PhotoCamera />
                              </IconButton>
                          </Grid>
                          
                              <TextField
                              error={isWarranty}
                                  label={"Warranty ID (Optional)"}
                                  type="number"
                                  value={warrantyArray[val] ? warrantyArray[val].warranty_id : ""}
                                  onChange={(e) => handleChangeWarrantyId(val, e.target.value)}
                                  style={{ width: "100%" }}
                                  variant="standard"/> 
                          </Grid>}) }
                      </Grid>: null}
                      </Grid>)
                    })}

                      <FormControl fullWidth style={{ marginTop: 10 }} >
                          <InputLabel id="demo-simple-select-label">Salesman</InputLabel>
                          <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectedSale}
                          label="Status"
                          onChange={handleChangeSales}
                          >
                            <MenuItem value={""}>Cashier Only</MenuItem>
                            {salesman.map(val=>{
                              return  <MenuItem value={val.id}>{val.name}</MenuItem>
                            })}
                         
                          </Select>
                      </FormControl>

                    {isDuplicate ? <Grid style={{ marginTop: 10}} >
                      <Typography style={{ fontSize: 14, marginTop: 10 }} align="left" variant="body2" color="secondary" component="p">
                          {"Warranty ID duplicated"}
                      </Typography>
                    </Grid> : null}

                    {isWarrantyIDInvalid ? <Grid style={{ marginTop: 10}} >
                      <Typography style={{ fontSize: 14, marginTop: 10 }} align="left" variant="body2" color="secondary" component="p">
                          {"Warranty ID Invalid"}
                      </Typography>
                    </Grid> : null}

                    {isFoundId ? <Grid style={{ marginTop: 10}} >
                      <Typography style={{ fontSize: 14, marginTop: 10 }} align="left" variant="body2" color="secondary" component="p">
                          {"Found Registered ID"}
                      </Typography>
                    </Grid> : null}

                    {props.paymentMethod === "debit_card" || props.paymentMethod === "credit_card"  ? <Grid style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", marginTop: 10}} >
                    <Typography style={{ fontSize: 14, marginTop: 10 }} align="left" variant="body2" color="textSecondary" component="p">
                                {"Last 4 Digit Credit / Debit Card Number"}
                            </Typography>
                      <PatternFormat   
                            style={{ marginTop: 10, fontSize: 24, width: "100%", backgroundColor: "#1a1a1a", color: "white", padding: 10 }} 
                            format="####" mask="_" 
                            onValueChange={(e)=>{ handleChangeCardNumber(e) }}
                            />
                      <Typography style={{ fontSize: 14, marginTop: 10 }} align="left" variant="body2" color="textSecondary" component="p">
                                {"Approval Code"}
                            </Typography>
                      <PatternFormat   
                            style={{ marginTop: 10, fontSize: 24, width: "100%", backgroundColor: "#1a1a1a", color: "white", padding: 10 }} 
                            format="######" mask="_" 
                            onValueChange={(e)=>{ handleSetApprovalCode(e) }}
                            />
                    </Grid> : null}

                    {props.paymentMethod === "ewallet" ? <Grid style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", marginTop: 10}} >
                    <Typography style={{ fontSize: 14, marginTop: 10 }} align="left" variant="body2" color="textSecondary" component="p">
                                {"E-Wallet Ref"}
                            </Typography>
                  <TextField
                    label={"E-Wallet Ref"}
                    style={{ width: "100%", marginTop: 20 }}
                    type="number"
                    onChange={(e)=>{ handleChangeEwalletRef(e) }}
                    variant="outlined"/>
                            
                    </Grid> : null}


                    <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 10}} >
                        <Grid>
                            <Typography style={{ fontSize: 14, marginTop: 10 }} align="left" variant="body2" color="textSecondary" component="p">
                                {"Balance Due"}
                            </Typography>
                            <Typography variant="h6" align="left" color="textSecondary" component="p">
                                {"RM " + props.amount.toFixed(2)}
                            </Typography>
                        </Grid>
                        <Grid>
                            <Typography style={{ fontSize: 14, marginTop: 10 }} align="right" variant="body2" color="textSecondary" component="p">
                                {"Change"}
                            </Typography>
                            <Typography variant="h6" align="right" color="textSecondary" component="p">
                                {"RM " + (-deduce_amount).toFixed(2)}
                            </Typography>
                        </Grid>
                    </Grid>
                    
                <TextField
                    label={"Amound Tendered"}
                    style={{ width: "100%", marginTop: 20 }}
                    
                    value={paid}
                    InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            RM
                          </InputAdornment>
                        ),
                    }}
                    type="number"
                    onChange={handleChangePaid}
                    variant="outlined"/></Grid>

              <Grid style={{ padding: 10, marginBottom: 30, display: 'flex', justifyContent: "flex-end" }}>
                  <Button variant="outlined" style={{ fontSize: 14, fontWeight: 'bold'}} onClick={props.handleClose} color="primary">
                  {"No"}
                  </Button>
                  <Button disabled={props.paid >= props.amount ? false : true} disableElevation variant="contained" style={{ marginLeft: 10, fontSize: 14, fontWeight: 'bold'}} onClick={()=> handleAgree()} color="primary" autoFocus>
                  {"Confirm"}
                  </Button>
              </Grid>
                    
            </Grid>
    )

}

Index.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Index);