import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Customers from '../Customers';
import Fab from '@material-ui/core/Fab';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import { connect } from 'react-redux';
import {
  clearItemFromCart,
  addItem,
  removeItem,
  changeItemQuantity,
  setItem
} from '../../../redux/cart/cart.actions';
import {
  setProductItem
} from '../../../redux/product/product.actions';
import { createStructuredSelector } from 'reselect';
import { selectCartItems, selectCartTotal, selectCartTotalCost, selectCartTotalDiscount } from '../../../redux/cart/cart.selectors'
import { selectCurrentUser } from '../../../redux/user/user.selector'
import { selectCurrentStaff } from '../../../redux/staff/staff.selector'
import { selectProductItems } from '../../../redux/product/product.selectors'

import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import CloseIcon from '@material-ui/icons/Close';
import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';

import ProductList from './Products'
import Dialog from '../../../modules/components/Dialog';
import firebase from "firebase";

import { useHistory } from "react-router-dom"
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import LoginCashier from '../../authentication/LoginCashier'
import AddEditCashier from './AddEditCashier'
import SearchCustomer from './SearchCustomer';
import PayByPoint from './PayByPoint';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt'
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import { set } from 'date-fns';


function Index(props) {
  
  //Dialog
  const [openDialog, setOpenDialog] = React.useState(false);
  const [dialogLoading, setDialogLoading] = React.useState(false);
  const [fullScreenDialog, setFullScreenDialog] = React.useState(false);
  const [dialogTitle, setDialogTitle] = React.useState("");
  const [dialogDes, setDialogDes] = React.useState("");
  const [agreeButton, setAgreeButton] = React.useState(false);
  const [option, setOptions] = React.useState("");
  const [dialogFullLoading, setDialogFullLoading ] = React.useState(false);

  const [selectedCustomer, setSelectedCustomer] = React.useState(false);
  const [selectedClient, setSelectedClient] = React.useState(null);
  const [mediaOption, setMediaOption] = React.useState("");
  const [paymentMethod, setPaymentMethod] = React.useState("cash");
  const [waiverPrice, setWaiverPrice] = React.useState("0");
  const [paid, setPaid] = React.useState("");
  const [showCard, setShowCard] = React.useState(true);
  let history = useHistory();

  const [loading, setLoading] = React.useState(false);

  const [usePoint, setUsePoint] = React.useState(0);

  const [checkbox, setCheckBox] = React.useState([]);
  const [useAllPoint, setUseAllPoint] = React.useState(false);

  const [cardNumber, setCardNumber] = React.useState("");
  const [ewalletRef, setEwalletRef] = React.useState("");
  const [approvalCode, setApprovalCode] = React.useState("");
  const [salesman, setSalesman] = React.useState("");

  useEffect(() => {

    renderCheckBox();

    
  }, [props]);

  const renderCheckBox = () =>{
    if(props.cartItems)
    {
      var newArray = []

      props.cartItems.forEach((val, index)=>{

        Array(val.quantity).fill(val.name + "-" + index).forEach((val2, index2) => {
          
          newArray.push(false)
        })
      })

      setCheckBox(newArray)
      setUseAllPoint(false)
      setUsePoint(0)
    }
  }

  const handleSetRows = (row) => {
    if(mediaOption === "customer"){
      setSelectedClient(row)
    }

    setUseAllPoint(false)
    setUsePoint(0)
    renderCheckBox()
    setOpenDialog(false)
  }

  const handleSubmitPayByPoint = () => {
    setOpenDialog(false)
  }

  const handleSetPaid = (row) => {
    if(mediaOption === "checkout"){
      setPaid(row)
    }
  }

  const handleSetCardNumber = (row) => {
    setCardNumber(row)
  }

  const handleSetEwalletRef = (row) => {
    setEwalletRef(row)
  }

  const handleSetApprovalCode = (row) => {
    setApprovalCode(row)
  }

  const handleSetSalesman = (row) => {
    setSalesman(row)
  }

  const handleClose = () => {
    setOpenDialog(false)
  };

  const addCustomers = () => {
    setMediaOption("customer")
    setDialogLoading(false)
    setAgreeButton(false)
    setDialogTitle("Customer")
    setOptions("addItem")
    setDialogDes("")
    setFullScreenDialog(false)
    setOpenDialog(true)
    
  }

  const paybypoint = () => {
    setMediaOption("paybypoint")
    setDialogLoading(false)
    setAgreeButton(false)
    setDialogTitle("paybypoint")
    setOptions("addItem")
    setDialogDes("")
    setFullScreenDialog(false)
    setOpenDialog(true)
   
  }

  const onClickClearCustomers = (event) => {
    setSelectedClient(null)
    setUseAllPoint(false)
    setUsePoint(0)
    renderCheckBox()
  }

  const enableAgreeButton = () => {
    setAgreeButton(false)
  }

  const renderComponent = () => {
    if(mediaOption === "customer"){
        return(<SearchCustomer mediaOption={mediaOption} handleSetRows={(rows) => handleSetRows(rows)}/>)
    }
    else if(mediaOption === "checkout"){
      return(<AddEditCashier selectedClient={selectedClient} 
        paid={Number(paid)}
        currentUser={props.currentUser}
        productItems={props.productItems} 
        cartItems={props.cartItems} 
        handleAgree={handleAgree}
        handleClose={handleClose}
        paymentMethod={paymentMethod}
        enableAgreeButton={enableAgreeButton} 
        waiverPrice={waiverPrice} mediaOption={mediaOption} 
        amount={selectedCustomer ?  (props.totalDiscount - waiverPrice) : (props.total - waiverPrice- ( usePoint !== 0 ? usePoint/10 : 0))} 
        handleSetPaid={(rows) => handleSetPaid(rows)}
        handleSetCardNumber={(rows) => handleSetCardNumber(rows)}
        handleSetEwalletRef={(rows) => handleSetEwalletRef(rows)}
        handleSetApprovalCode={(rows) => handleSetApprovalCode(rows)}
        handleSetSalesman={(rows) => handleSetSalesman(rows)}
        />)
    }
    if(mediaOption === "paybypoint"){
      return(<PayByPoint mediaOption={mediaOption} cartPointList={(allproduct) => cartPointList(allproduct)} {...props} />)
    }
  }

  const checkOut = () => {

    setAgreeButton(false)
    setMediaOption("checkout")
    setDialogLoading(false)
    
    setDialogTitle("Check Out")
    setOptions("checkout")
    setDialogDes("")
    setFullScreenDialog(false)
    setOpenDialog(true)
    
  }

  const saveOrder = (uuid) => {
    var updates = {};

    var addItem = {};
    var newPostKey = firebase.database().ref().child("pos/" + firebase.auth().currentUser.displayName + "/orders").push().key;

    var totalAmount = selectedCustomer ?  (props.totalDiscount - waiverPrice) : (props.total - waiverPrice - ( usePoint !== 0 ? usePoint/10 : 0));

    addItem['created'] = firebase.database.ServerValue.TIMESTAMP;
    addItem['id'] = newPostKey;
    addItem['uuid'] = parseInt(uuid).toLocaleString('en-US', {minimumIntegerDigits: 8, useGrouping:false});
    addItem['user'] = props.currentUser;
    addItem['user_uid'] = props.currentUser.id;
    addItem['products'] = props.cartItems;
    addItem['amount'] = totalAmount;
    addItem['cost_amount'] = props.totalCost;
    addItem['subtotal'] = selectedCustomer ?  props.totalDiscount: props.total;
    addItem['waiver'] = waiverPrice;
    addItem['payment_method'] = paymentMethod;
    addItem['orders_customer'] = selectedClient;
    addItem['cashier'] = props.currentStaff;
    addItem['store_address'] = props.currentUser.store_address ? props.currentUser.store_address : null;
    addItem['ssm_id'] = props.currentUser.ssm_id ? props.currentUser.ssm_id : null;
    addItem['store_name'] = props.currentUser.store_name ? props.currentUser.store_name : null;
    addItem['company_name'] = props.currentUser.company_name ? props.currentUser.company_name : null;
    addItem['company_phone'] = props.currentUser.company_phone ? props.currentUser.company_phone : null;
    addItem["amound_tendered"] = paid !== "" ? Number(paid) : 0;
    addItem["card_number"] = cardNumber;
    addItem["ewallet_ref"] = ewalletRef;
    addItem["approval_code"] = approvalCode;
    addItem["salesman"] = salesman;
    
    if(selectedClient)
    {
      addItem['product_use_point'] = parseInt(usePoint);
      var _customer_point = (selectedClient[0].customer_point ? (selectedClient[0].customer_point - 
        (selectedClient[0].customer_use_point ? selectedClient[0].customer_use_point : 0) - usePoint) : 0)
      addItem["customer_point"] = _customer_point + parseInt(totalAmount);
    }
    
    
    
    updates["pos/" + firebase.auth().currentUser.displayName + "/orders/" + newPostKey] = addItem;
    firebase.database().ref().update(updates).then(()=>{

      if(selectedClient)
      {
        

        firebase.database().ref("customer/" + selectedClient[0].id + "/customer_point").once('value', snapshot => {

          var usePointAmount = ((selectedClient[0].customer_use_point ? parseInt(selectedClient[0].customer_use_point) : 0) + 
          ( usePoint !== 0 ? usePoint : 0));

        var customer_point = parseInt(snapshot.val()) + parseInt(totalAmount);
          if(snapshot.exists()){
            firebase.database().ref("customer/" + selectedClient[0].id)
            .update({ customer_point:  customer_point,
              customer_use_point: usePointAmount })
            .then(()=>{

              let count_complete = 0;
              props.cartItems.forEach((val,index)=>{
                var currentProductIndex = props.productItems.map(val => val.id).indexOf(val.id)
                if(currentProductIndex !== -1){
                  
                  var inStockCount = Number(props.productItems[currentProductIndex].in_stock) - Number(val.quantity);
                  props.productItems[currentProductIndex].in_stock = inStockCount;
                  props.setProductItem(props.productItems)
          
                  firebase.database().ref("pos/" + firebase.auth().currentUser.displayName + "/products/" + props.productItems[currentProductIndex].id)
                  .update({ in_stock: inStockCount }).then(()=>{
                    count_complete++;
                    if(props.cartItems.length === count_complete){
                      setOpenDialog(false)
                      setSelectedClient(null)
                      setPaymentMethod("cash")
                      props.setItem([], props.currentUser)
                      history.push("/receipt/"+ newPostKey)
                    }
                  })
                }
                else{
                  count_complete++;
                  if(props.cartItems.length === count_complete){
                    setOpenDialog(false)
                    setSelectedClient(null)
                    setPaymentMethod("cash")
                    props.setItem([], props.currentUser)
                    history.push("/receipt/"+ newPostKey)
                  }
                }
              })

            })

          }
          else
          {
            firebase.database().ref("customer/" + selectedClient[0].id)
            .update({ customer_point: parseInt(totalAmount) })
            .then(()=>{

              let count_complete = 0;
              props.cartItems.forEach((val,index)=>{
                var currentProductIndex = props.productItems.map(val => val.id).indexOf(val.id)
                if(currentProductIndex !== -1){
                  
                  var inStockCount = Number(props.productItems[currentProductIndex].in_stock) - Number(val.quantity);
                  props.productItems[currentProductIndex].in_stock = inStockCount;
                  props.setProductItem(props.productItems)
          
                  firebase.database().ref("pos/" + firebase.auth().currentUser.displayName + "/products/" + props.productItems[currentProductIndex].id)
                  .update({ in_stock: inStockCount }).then(()=>{
                    count_complete++;
                    if(props.cartItems.length === count_complete){
                      setOpenDialog(false)
                      setSelectedClient(null)
                      setPaymentMethod("cash")
                      props.setItem([], props.currentUser)
                      history.push("/receipt/"+ newPostKey)
                    }
                  })
                }
                else{
                  count_complete++;
                  if(props.cartItems.length === count_complete){
                    setOpenDialog(false)
                    setSelectedClient(null)
                    setPaymentMethod("cash")
                    props.setItem([], props.currentUser)
                    history.push("/receipt/"+ newPostKey)
                  }
                }
              })

            })

          }

        })
        
      }
      else
      {
        let count_complete = 0;
        props.cartItems.forEach((val,index)=>{
          var currentProductIndex = props.productItems.map(val => val.id).indexOf(val.id)
          if(currentProductIndex !== -1){
            
            var inStockCount = Number(props.productItems[currentProductIndex].in_stock) - Number(val.quantity);
            props.productItems[currentProductIndex].in_stock = inStockCount;
            props.setProductItem(props.productItems)
    
            firebase.database().ref("pos/" + firebase.auth().currentUser.displayName + "/products/" + props.productItems[currentProductIndex].id)
            .update({ in_stock: inStockCount }).then(()=>{
              count_complete++;
              if(props.cartItems.length === count_complete){
                setOpenDialog(false)
                setSelectedClient(null)
                setPaymentMethod("cash")
                props.setItem([], props.currentUser)
                history.push("/receipt/"+ newPostKey)
              }
            })
          }
          else{
            count_complete++;
            if(props.cartItems.length === count_complete){
              setOpenDialog(false)
              setSelectedClient(null)
              setPaymentMethod("cash")
              props.setItem([], props.currentUser)
              history.push("/receipt/"+ newPostKey)
            }
          }
        })

        
      }
      
      
    })

    
    

    
  }

  const handleAgree = (warrantyArray) => {
    
    setLoading(true)
    if(mediaOption === "checkout"){

      if(warrantyArray){
        
        var totalAmount = selectedCustomer ?  (props.totalDiscount - waiverPrice) : (props.total - waiverPrice - ( usePoint !== 0 ? usePoint/10 : 0));

        if(Number(paid) >= totalAmount){
  
          let ordersUUIDRef = firebase.database().ref("pos/" + firebase.auth().currentUser.displayName + "/orders_uuid")
  
          ordersUUIDRef.once('value', snapshot => {
            if(snapshot.exists()){
              let val = snapshot.val()
    
              let newUUID = Number(val.uuid)+1
              saveOrder(newUUID)
              firebase.database().ref("pos/" + firebase.auth().currentUser.displayName + "/orders_uuid").update({ uuid: newUUID })
              .then(()=>{

                

                warrantyArray.forEach(val=>{
                  if(val.id){
                    var updates = {};
                    updates['warranty/' + val.id] = val;
                    firebase.database().ref().update(updates);
                  }
                })

              })
            }
            else
            {
              saveOrder(1)
              firebase.database().ref("pos/" + firebase.auth().currentUser.displayName + "/orders_uuid").update({ uuid: 1 })
              .then(()=>{

                warrantyArray.forEach(val=>{
                  if(val.id){
                    var updates = {};
                    updates['warranty/' + val.id] = val;
                    firebase.database().ref().update(updates);
                  }
                })

                
              })
            }

            
          })
          //setOpenDialog(false)
          
        }
      }
      else
      {
        var totalAmount = selectedCustomer ?  (props.totalDiscount - waiverPrice) : (props.total - waiverPrice - ( usePoint !== 0 ? usePoint/10 : 0));

        if(Number(paid) >= totalAmount){
  
          let ordersUUIDRef = firebase.database().ref("pos/" + firebase.auth().currentUser.displayName + "/orders_uuid")
  
          ordersUUIDRef.once('value', snapshot => {
            if(snapshot.exists()){
              let val = snapshot.val()
    
              let newUUID = Number(val.uuid)+1
              saveOrder(newUUID)
              firebase.database().ref("pos/" + firebase.auth().currentUser.displayName + "/orders_uuid").update({ uuid: newUUID })
            }
            else{
              saveOrder(1)
              firebase.database().ref("pos/" + firebase.auth().currentUser.displayName + "/orders_uuid").update({ uuid: 1 })
            }
    
          })
  
          //setOpenDialog(false)
        }
      }
      
    }

    
  }

  const handleCheckBoxChange = (e, val, index, _productPoint) => {

    var totalPoint = selectedClient[0].customer_point ? (parseInt(selectedClient[0].customer_point) - 
    (selectedClient[0].customer_use_point ? 
      parseInt(selectedClient[0].customer_use_point) : 0)) : 0 ;

      console.log(totalPoint)
    if(e.target.checked)
    {
      var newPoint = usePoint + parseInt(_productPoint);
      if(newPoint > totalPoint){
        //setUseAllPoint(true)
      }
      else{
        setUseAllPoint(false)

        var newCheckBox = [...checkbox]
        newCheckBox[index] = e.target.checked;
        setCheckBox(newCheckBox)
        setUsePoint(newPoint)
      }

    }
    else
    {
      var newPoint = usePoint - parseInt(_productPoint);
      if(newPoint > totalPoint){
        //setUseAllPoint(true)
      }
      else{
        setUseAllPoint(false)
        var newCheckBox = [...checkbox]
        newCheckBox[index] = e.target.checked;
        setCheckBox(newCheckBox)
        setUsePoint(newPoint)
      }

    }


  }
  
  
  const cartPointList = (allproduct) => {

    var newArray = []
    props.cartItems.forEach((val, index)=>{
      Array(val.quantity).fill(val).map((val2, index2) => {
        newArray.push(val2)
      })
    })


    return <Grid style={{ width: "100%", flexDirection: "row",}}>
      
      <Grid>
        <Grid container>       
        {newArray.map((val, index)=>{

              var currentIndex = allproduct.map(val2=> val2.id).indexOf(val.id)
              var _productPoint = Number(val.retail_price) * 10;

              if(currentIndex != -1)
              {
                if(allproduct[currentIndex].product_point){
                  _productPoint = parseInt(allproduct[currentIndex].product_point);
                }
              }

              return(
                <Grid xs={12} key={"product-card-"+index} item style={{ borderBottom: '1px solid #414141', height: "100%" }}>
                  <Grid container style={{ display: "flex", flexDirection: "row", paddingTop: 10, paddingBottom: 10 }}>
                    
                    <Grid item xs={9}  style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", paddingLeft: 20 }} >
    
                      <Grid >
                        <CardMedia
                            component="div"
                            style={{ width: 40, height: 40 }}
                            image={val.image && val.image[0] ? val.image[0].url : null}
                            title={val.name}
                          />
                      </Grid>
                      <Grid style={{ paddingLeft: 10 }}>
                          <Grid style={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
                            <Typography style={{ fontSize: 14 }} variant="body2" color="textSecondary" component="p">
                              {_productPoint + " Point"}
                            </Typography>
                          </Grid>
                          
                          <Typography style={{ opacity: .5, fontSize: 12, 
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 2,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis' }} variant="body2" color="textSecondary" component="p">
                            {val.name}
                          </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={3} style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "flex-end", paddingRight: 20 }} >
                      <Checkbox
                        disabled={useAllPoint ? checkbox[index] ? false : true : false}
                        checked={checkbox[index]}
                        onChange={(e)=> handleCheckBoxChange(e, val, index, _productPoint)}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </Grid>
                  </Grid>
                </Grid>)
        })}


        </Grid>

        {selectedClient ? <Grid style={{ display: "flex",justifyContent: "center", alignItems: "center", paddingTop: 20 }}>
          <Typography variant="h5" color="textSecondary" component="p" align="left" >
            { "Left Point " + (selectedClient[0].customer_point ? (selectedClient[0].customer_point - 
              (selectedClient[0].customer_use_point ? 
                selectedClient[0].customer_use_point : 0) - usePoint) : 0 + " Point")}
          </Typography> 
        </Grid> : null}
      </Grid>
    </Grid>
  };

  const cartList = () => (
    <Grid style={{ width: "100%", flexDirection: "row",}}>
      
      <Grid>
        <Grid container>       
        {props.cartItems.map((val, index)=>{

          var currentProductIndex = props.productItems.map(val => val.id).indexOf(val.id)
          
          return(
            <Grid xs={12} key={"product-card-"+index} item style={{ borderBottom: '1px solid #414141', height: "100%" }}>
              <Grid container style={{ display: "flex", flexDirection: "row", paddingTop: 10, paddingBottom: 10 }}>
                
                <Grid item xs={9}  style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", paddingLeft: 20 }} >
                  
                  <Grid style={{ paddingRight: 10 }}>
                    <Grid style={{  justifyContent: "center", display: "flex", flexDirection: "column", alignItems: "center" }} >
                        <IconButton
                        disabled={val.quantity >=  props.productItems[currentProductIndex].in_stock ? true :false}
                          style={{ opacity: .7, width: 20, height: 20 }}
                          size={"small"}
                          onClick={()=> props.addItem(val, props.currentUser)}
                        >
                          <Add style={{ fontSize: 18 }} />
                        </IconButton>
                        <Grid style={{ height: 30, display: "flex", alignItems: "center" }} >
                          <Input 
                          type="text" 
                            inputProps={{min:1, style: { textAlign: 'center' }}}
                          style={{ fontSize: 14, width: 40, padding: 0, textAlign: "center"  }} onChange={(e)=> { 

                            var isNumber = /^\d+$/.test(e.target.value)

                            if(currentProductIndex !== -1){
                              if(Number(e.target.value) > Number(props.productItems[currentProductIndex].in_stock)){
                                return props.changeItemQuantity(Number(props.productItems[currentProductIndex].in_stock), val, props.currentUser)
                              }
                            }

                            if(!isNumber){
                              return props.changeItemQuantity(e.target.value.replace(/\D/g,''), val, props.currentUser)
                            }
                            else
                            {
                              return props.changeItemQuantity(e.target.value, val, props.currentUser)
                            }
                            }} value={val.quantity} />
                        </Grid>
                        <IconButton
                          style={{ opacity: .7, width: 20, height: 20 }}
                          size={"small"}
                          onClick={()=> props.removeItem(val)}
                        >
                          <Remove style={{ fontSize: 18 }} />
                        </IconButton>
                    </Grid>
                  </Grid>

                  <Grid >
                    <CardMedia
                        component="div"
                        style={{ width: 70, height: 70 }}
                        image={val.image && val.image[0] ? val.image[0].url : null}
                        title={val.name}
                      />
                  </Grid>
                  <Grid style={{ paddingLeft: 10 }}>
                      <Grid style={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
                        <Typography style={{ fontSize: 14 }} variant="body2" color="textSecondary" component="p">
                          {selectedCustomer ? "RM " + Number(val.discount_price).toFixed(2): "RM " + Number(val.retail_price).toFixed(2)}
                        </Typography>
                      </Grid>
                      
                      <Typography style={{ opacity: .5, fontSize: 12, 
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 2,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis' }} variant="body2" color="textSecondary" component="p">
                        {val.name}
                      </Typography>

                      <Typography style={{ opacity: .5, fontSize: 12 }} variant="body2" color="textSecondary" component="p">
                        { "x " +val.quantity}
                      </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={3} style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "flex-end", paddingRight: 20 }} >
                  <IconButton
                    style={{ opacity: .7 }}
                    size={"small"}
                    onClick={()=> props.clearItem(val, props.currentUser)}
                  >
                    <CloseIcon style={{ fontSize: 20 }} />
                  </IconButton>
                  <Typography style={{ fontSize: 18 }} variant="body2" color="textSecondary" component="p">
                    { selectedCustomer ? "RM " +(val.quantity * val.discount_price).toFixed(2) : "RM " +(val.quantity * val.retail_price).toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )
        })}


        </Grid>
        
      </Grid>
     
      {renderCheckOut()}
    </Grid>
  );

  const renderTotal = () =>{
    return(
<Grid container >
            <Grid item xs={12}  style={{ marginBottom: 10 }}>
              <Grid container style={{ width: "100%" , height: "100%" }}>
                          
                <Grid item md={9}>
                <Button onClick={addCustomers} color="primary" style={{ width: "100%" , height: "100%", fontSize: 18 }} 
                disableElevation variant="contained">{"Customer"}</Button>
                </Grid>

                <Grid item md={3} style={{ paddingLeft: 10 }} >
                <Button style={{ width: "100%" , height: "100%", fontSize: 18 }} disabled={selectedClient ? false : true } 
                onClick={onClickClearCustomers} variant="outlined" color="primary">
                    Clear
                </Button>
                </Grid>
                
              </Grid>
            </Grid>
            {selectedClient ? <Grid item xs={12} style={{ marginBottom: 10  }}> 
              <Grid container>
                <Grid item xs={6} style={{ border: '1px solid #505050', 
                borderTopLeftRadius: 5, borderBottomLeftRadius: 5, display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Typography variant="body2" color="textSecondary" component="p" align="left" 
                  style={{ fontSize: 14 }}>
                    {selectedClient[0].first_name + " " + selectedClient[0].last_name}
                  </Typography> 
                </Grid>
                <Grid item xs={2} style={{ display: "flex", justifyContent: "center", alignItems: "center", border: '1px solid #505050' }}>
                  <Typography variant="body2" color="textSecondary" component="p" align="left" >
                    { selectedClient[0].customer_point ? selectedClient[0].customer_point - (selectedClient[0].customer_use_point ? 
                    selectedClient[0].customer_use_point : 0) - usePoint : 0 + " Point"}
                  </Typography> 
                </Grid>
                <Grid item xs={4}>
                  <Button 
                  disabled={selectedClient[0].customer_point ? parseInt(selectedClient[0].customer_point) - 
                    (selectedClient[0].customer_use_point ? 
                    selectedClient[0].customer_use_point : 0) >= 0 ? false : true : true}
                  onClick={paybypoint} style={{ width: "100%",fontSize: 14, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} color="primary" disableElevation variant="contained">
                      Pay by point
                  </Button>
                </Grid>
                
              </Grid>
              
              </Grid>: null}
            <Grid item xs={6}>
              <Typography variant="body2" color="textSecondary" component="p" align="left" style={{ fontSize: 16 }} >
                { "Subtotal"}
              </Typography> 
              <Typography variant="body2" color="textSecondary" component="p" align="left" style={{ fontSize: 16, marginTop: 10, opacity: .6 }}>
                { "Tax"}
              </Typography> 
              <Typography variant="body2" color="textSecondary" component="p" align="left" style={{ fontSize: 16, marginTop: 10, opacity: .6, height: 40, paddingTop: 5 }}>
                { "Discount/Waiver"}
              </Typography> 
              <Typography variant="h4" color="textSecondary" component="p" align="left" style={{ marginTop: 20 }}>
                { "Total"}
              </Typography> 
            </Grid>
            

            <Grid item xs={6} >
              <Typography variant="body2" color="textSecondary" component="p" align="right" style={{ fontSize: 16 }}>
                { selectedCustomer ? "RM " + props.totalDiscount.toFixed(2) : "RM " + props.total.toFixed(2)}
              </Typography> 
              <Typography variant="body2" color="textSecondary" component="p" align="right" style={{marginTop: 10, opacity: .6, fontSize: 16 }}>
                { "RM 0.00"}
              </Typography> 
              <Grid style={{marginTop: 10, opacity: .6, height: 40 }}>
                  <TextField
                                style={{ width: "100%", padding: 0, fontSize: 16}}
                                type="number"
                                InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        RM
                                      </InputAdornment>
                                    ),
                                }}
                                value={waiverPrice}
                                onChange={(e)=> 
                                {
                                  if(Number(e.target.value) <= (props.total - ( usePoint !== 0 ? usePoint/10 : 0)).toFixed(2))
                                  {
                                    setWaiverPrice(e.target.value)
                                  }
                                  else{
                                    setWaiverPrice((props.total - ( usePoint !== 0 ? usePoint/10 : 0)).toFixed(2))
                                  }
                                }}/>
              </Grid> 
              <Typography variant="h4" color="textSecondary" component="p" align="right" style={{marginTop: 20 }}>
              { selectedCustomer ? "RM " + 
              (props.totalDiscount - waiverPrice).toFixed(2) : "RM " + (props.total - waiverPrice - ( usePoint !== 0 ? usePoint/10 : 0)).toFixed(2)}
              </Typography> 
            </Grid>
            
            {renderPaymentMethod()}

          </Grid>
    )
  }

  const renderCheckOut = () => {
    return(
      <Grid style={{ padding: 20, backgroundColor: "#1c1c1c", position: showCard? "sticky": "unset", top: 0, bottom: 0 }} >
        
      {props.cartItems.length === 0 ? <Grid 
        style={{ display: "flex", justifyContent: "center", padding: 20, flexDirection: "column", borderBottom: '1px solid #414141' }}>
          <Grid style={{ height: 100, display: "flex", justifyContent: "center"  }}>
          <img height="100%" src="assets/carticon.png" alt="carticon" />
          </Grid>
          <Grid style={{ display: "flex", justifyContent: "center" }}>
            <Typography variant="h5"  color="textSecondary" component="p" style={{ fontWeight: 500, fontSize: 30 }} >
                {"Your cart is empty"}
            </Typography>
          </Grid>
          <Grid style={{ display: "flex", justifyContent: "center" }}>
            <Typography variant="caption"  color="textSecondary" component="p">
                {"Your have no items in your cart."}
            </Typography>
          </Grid>
          
        </Grid> : null}
            {renderTotal()}

            <Button onClick={()=> checkOut()} disabled={props.cartItems.length > 0 ? false: true} style={{ marginTop: 20, width: "100%", paddingTop: 20, paddingBottom:20, fontSize: 24 }} color="primary" disableElevation variant="contained">
                      Pay
            </Button>

          
      </Grid>
    )
  }

  const renderPaymentMethod = () => {
    return(
      <Grid item xs={12} style={{ marginTop: 10 }}>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <Button onClick={() => setPaymentMethod("cash")} color={paymentMethod === "cash" ? 'primary' : 'default'} style={{ width: "100%" , height: "100%", fontSize: 14, opacity: .8, padding: 10 }} 
                disableElevation  variant="contained">{"Cash"}</Button>
                </Grid>
                <Grid item xs={4}>
                <Button onClick={() => setPaymentMethod("debit_card")} color={paymentMethod === "debit_card" ? 'primary' : 'default'}style={{ width: "100%" , height: "100%", fontSize: 14, opacity: .8, padding: 10 }} 
                disableElevation  variant="contained">{"Debit Card"}</Button>
                </Grid>
                <Grid item xs={4}>
                <Button onClick={() => setPaymentMethod("credit_card")} color={paymentMethod === "credit_card" ? 'primary' : 'default'}style={{ width: "100%" , height: "100%", fontSize: 14, opacity: .8, padding: 10}} 
                disableElevation  variant="contained">{"Credit Card"}</Button>
                </Grid>
                <Grid item xs={4}>
                <Button onClick={() => setPaymentMethod("ewallet")} color={paymentMethod === "ewallet" ? 'primary' : 'default'}style={{ width: "100%" , height: "100%", fontSize: 14, opacity: .8, padding: 10 }} 
                disableElevation  variant="contained">{"E-Wallet"}</Button>
                </Grid>
                <Grid item xs={4}>
                <Button onClick={() => setPaymentMethod("online_transfer")} color={paymentMethod === "online_transfer" ? 'primary' : 'default'} style={{ width: "100%" , height: "100%", fontSize: 14, opacity: .8, padding: 10 }} 
                disableElevation  variant="contained">{"Online Transfer"}</Button>
                </Grid>
                <Grid item xs={4}>
                <Button onClick={() => setPaymentMethod("gift")} color={paymentMethod === "gift" ? 'primary' : 'default'}style={{ width: "100%" , height: "100%", fontSize: 14, opacity: .8, padding: 10 }} 
                disableElevation  variant="contained">{"Gift Card"}</Button>
                </Grid>
                <Grid item xs={4}>
                <Button onClick={() => setPaymentMethod("foodpanda")} color={paymentMethod === "foodpanda" ? 'primary' : 'default'}style={{ width: "100%" , height: "100%", fontSize: 14, opacity: .8, padding: 10 }} 
                disableElevation  variant="contained">{"Food Panda"}</Button>
                </Grid>
              </Grid>
            </Grid>
    )
  }

  if(loading){
    return (
    <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center', marginTop: 70  }}>
      <CircularProgress 
        disableShrink
        color="primary" />
    </div>);
  }

  return(
    <Grid>
      <Dialog 
        agreeTxt={"Yes"}
        disagreeTxt={"No"}
        description={dialogDes}
        title={dialogTitle}
        open={openDialog} 
        agreeButton={agreeButton}
        fullScreenDialog={fullScreenDialog}
        option={option}
        dialogFullLoading={dialogFullLoading}
        dialogLoading={dialogLoading}
        component={renderComponent()}
        
        handleAgree={handleAgree}
        handleClose={handleClose}/>
      {props.currentStaff ? <Grid container>

        <Hidden mdDown>
        <Grid item xs={12} lg={4} style={{ overflowY: "scroll", backgroundColor: "#161616", height:"80vh" }}>
        {cartList()}
        </Grid>
        </Hidden>
        
        <Hidden mdDown>
        <Grid item xs={12} lg={8} style={{ overflowY: "scroll", height:"80vh" }}>

            <ProductList {...props} selectedCustomer={selectedCustomer}/>

        </Grid>
        </Hidden>

        <Hidden lgUp>
          <Grid item xs={12} lg={8} style={{ overflowY: "scroll", height:"100%", display: showCard? "flex": "none" }}>

              <ProductList {...props} selectedCustomer={selectedCustomer}/>

          </Grid>
        </Hidden>

        <Hidden lgUp>
          <Grid item xs={12} lg={4} style={{ overflowY: "scroll", backgroundColor: "#161616", height:"100%", display: showCard? "none": "flex" }}>
          {cartList()}
          </Grid>
        </Hidden>
        

        <Hidden lgUp>
          <Fab style={{ position: "fixed", right: 30, bottom: 20, zIndex: 999}} onClick={()=> setShowCard(!showCard)}>
            {showCard ? <ShoppingBasketIcon />: <OfflineBoltIcon/>}
          </Fab>
          </Hidden>
      </Grid> : 
      <LoginCashier/>
      }
    </Grid>
  )
    
}

const mapDispatchToProps = dispatch => ({
  clearItem: (item, user) => dispatch(clearItemFromCart(item, user)),
  addItem: (item, user) => dispatch(addItem(item, user)),
  setItem: (item, user) => dispatch(setItem(item, user)),
  changeItemQuantity: (quantity, item, user) => dispatch(changeItemQuantity(quantity, item, user)),
  removeItem: (item, user) => dispatch(removeItem(item, user)),
  setProductItem: item => dispatch(setProductItem(item)),
});

const mapStateToProps = createStructuredSelector({
  cartItems: selectCartItems,
  total: selectCartTotal,
  totalCost: selectCartTotalCost,
  totalDiscount: selectCartTotalDiscount,
  currentUser: selectCurrentUser,
  currentStaff: selectCurrentStaff,
  productItems: selectProductItems,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Index);
