import React, { useEffect } from 'react';
import Table from '../../../modules/components/Table'
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import firebase from "firebase";
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentStaff } from '../../../redux/staff/staff.selector'

function Index(props) {

    const [headCells, setHeadCells] = React.useState([]);
    const [headMobileCells, setHeadMobileCells] = React.useState([]);
    const [searchValue, setSearchValue] = React.useState("");
    const [searchCell, setSearchCell] = React.useState([]);
    const [displayInfo, setDisplayInfo] = React.useState([]);
    const [editCells, setEditCells] = React.useState([]);
    const [addCells, setAddCells] = React.useState([]);
    const [addCellsName, setAddCellsName] = React.useState([]);
    const matches = useMediaQuery(theme => theme.breakpoints.up('sm'));

    useEffect(() => {

        console.log(props.currentStaff)
        if(props.currentStaff && props.currentStaff.role === "cashier"){
            setHeadCells([
                { id: 'checkout_created', label: 'Date' },
                { id: 'total_invoice', label: 'No. INV' },
                { id: 'checkout_sub_total', label: 'Total Sales' },
                { id: 'checkout_discount', label: 'Total Discount' },
                { id: 'total_credit_note', label: 'Total Credit Note' },
                { id: 'net_turnover', label: 'Net TurnOver' },
                { id: 'total_point', label: 'Redeemed Point' },
            ])
        }
        else{
            setHeadCells([
                { id: 'checkout_created', label: 'Date' },
                { id: 'total_invoice', label: 'No. INV' },
                { id: 'checkout_sub_total', label: 'Total Sales' },
                { id: 'checkout_discount', label: 'Total Discount' },
                { id: 'total_credit_note', label: 'Total Credit Note' },
                { id: 'net_turnover', label: 'Net TurnOver' },
                { id: 'total_cost_of_goods_sold', label: 'Total Cost of Goods Sold' },
                { id: 'total_credit_note_cost', label: 'Total Credit Note Cost' },
                { id: 'gross_profit', label: 'Gross Profit' },
                { id: 'total_point', label: 'Redeemed Point' },
            ])
        }
        

        setHeadMobileCells([
            { id: 'checkout_sub_total', label: 'Total' },
        ])
    
        setSearchCell([
            { id: 'checkout_created', label: 'Date' },
        ])

        if(props.currentStaff && props.currentStaff.role === "cashier"){

            setDisplayInfo([
                { id: 'checkout_created', label: 'Created Date' },
                { id: 'total_invoice', label: 'No. INV' },
                { id: 'checkout_sub_total', label: 'Total Sales' },
                { id: 'checkout_discount', label: 'Total Discount' },
                { id: 'total_credit_note', label: 'Total Credit Note' },
                { id: 'net_turnover', label: 'Net TurnOver' },
                { id: 'total_point', label: 'Redeemed Point' },
            ])

        }else{

            setDisplayInfo([
                { id: 'checkout_created', label: 'Created Date' },
                { id: 'total_invoice', label: 'No. INV' },
                { id: 'checkout_sub_total', label: 'Total Sales' },
                { id: 'checkout_discount', label: 'Total Discount' },
                { id: 'total_credit_note', label: 'Total Credit Note' },
                { id: 'net_turnover', label: 'Net TurnOver' },
                { id: 'total_cost_of_goods_sold', label: 'Total Cost of Goods Sold' },
                { id: 'total_credit_note_cost', label: 'Total Credit Note Cost' },
                { id: 'gross_profit', label: 'Gross Profit' },
                { id: 'total_point', label: 'Redeemed Point' },
            ])

        }

        

        setEditCells([
            { id: 'checkout_created', label: 'Created Date' },
            { id: 'checkout_sub_total', label: 'Total' },
        ])

        setAddCells([
            { id: 'checkout_created', label: 'Created Date' },
            { id: 'checkout_sub_total', label: 'Total' },
        ])

        setAddCellsName({ 
            checkout_created: "",
            total_amount: ""
         })
    
        setSearchValue("checkout_created")
        
    }, []);

    return (
    <Grid>
        <Table 
            {...props}
            control={true}
            hideDelete={true}
            hideMove={true}
            hideCheckBox={true}
            hideSearch={true}
            orderCheckOutReceiptButton={true}
            orderBy="checkout_created" 
            data={"checkoutdaily"}
            addItemButton={false}
            addCells={addCells}
            addCellsName={addCellsName}
            displayInfo={displayInfo}
            editCells={editCells}
            matches={matches}
            headCells={matches ? headCells : headMobileCells} 
            searchValue={searchValue} 
            searchCell={searchCell}/>
    </Grid>
    )

}
  
const mapStateToProps = createStructuredSelector({
    currentStaff: selectCurrentStaff,
})

export default connect(
    mapStateToProps
)(Index);
  
  