import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from '@material-ui/core';
import app from "../../firebase/base";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import PersonIcon from '@material-ui/icons/Person';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';
import PeopleIcon from '@material-ui/icons/People';
import Assignment from '@material-ui/icons/Assignment';
import StoreIcon from '@material-ui/icons/Store';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { selectCurrentStaff } from '../../redux/staff/staff.selector'
import { selectCurrentUser } from '../../redux/user/user.selector'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

import {
  setCurrentUser
} from '../../redux/user/user.actions';

import {
  setCurrentStaff
} from '../../redux/staff/staff.actions';

import { useDispatch } from 'react-redux';
import {
  setItem
} from '../../redux/cart/cart.actions';

import {
  setProductItem
} from '../../redux/product/product.actions';

import {
  setCategoryItem
} from '../../redux/category/category.actions';

const styles = (theme) => ({
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  categoryHeader2: {
    paddingTop: theme.spacing(2),
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  item: {
    paddingTop: 1,
    paddingBottom: 1,
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover,&:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  itemCategory: {
    boxShadow: '0 -1px 0 #404854 inset',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  firebase: {
    fontSize: 24,
    color: theme.palette.common.white,
  },
  itemActiveItem: {
    color: theme.palette.primary.main,
  },
  itemPrimary: {
    fontSize: 'inherit',
  },
  itemIcon: {
    minWidth: 'auto',
    marginRight: theme.spacing(2),
  },
  notificationIcon: {
    minWidth: 'auto',
    marginLeft: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});

function Navigator(props) {
  const { classes, staticContext,  ...other } = props;
  const path = props.location.pathname.slice(1);
  const [open, setOpen] = React.useState(false);
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [categories ] = React.useState([
    {
      id: 'Manage',
      children: [
        { id: 'Retails', icon: <ShoppingCartIcon />, url: '', notification: 0 },
        { id: 'Users', icon: <PersonIcon />, url: 'users', notification: 0 },
        { id: 'Salesman', icon: <PersonIcon />, url: 'salesman', notification: 0 },
        { id: 'Customers', icon: <PeopleIcon />, url: 'customers', notification: 0 },
        { id: 'Product', icon: <OfflineBoltIcon />, url: 'products', notification: 0 },
        { id: 'Warehouse', icon: <StoreIcon />, url: 'warehouse', notification: 0 },
        //{ id: 'Warranty', icon: <VerifiedUserIcon />, url: 'warranty', notification: 0 },
        { id: 'Orders', icon: <AssignmentTurnedInIcon />, url: 'orders', notification: 0 },
        { id: 'Credit Note', icon: <AttachMoneyIcon />, url: 'creditnote', notification: 0 },
        { id: 'Reports', icon: <AssessmentIcon />, url: 'report', notification: 0 },
        { id: 'Sales Report', icon: <AssessmentIcon />, url: 'sales_report', notification: 0 },
      ],
    }
  ]);

  const [categoriesStaff ] = React.useState([
    {
      id: 'Manage',
      children: [
        { id: 'Retails', icon: <ShoppingCartIcon />, url: '', notification: 0 },
        { id: 'Customers', icon: <PeopleIcon />, url: 'customers', notification: 0 },
        //{ id: 'Warranty', icon: <StoreIcon />, url: 'warranty', notification: 0 },
        { id: 'Orders', icon: <AssignmentTurnedInIcon />, url: 'orders', notification: 0 },
        { id: 'Credit Note', icon: <AttachMoneyIcon />, url: 'creditnote', notification: 0 },
      ],
    }
  ]);

  const [categories2 ] = React.useState([
    {
      id: 'Manage',
      children: [
        { id: 'Cashier Login', icon: <VpnKeyIcon />, url: 'login_cashier', notification: 0 },
      ],
    }
  ]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSignout = () => {

    setBackdropOpen(true)

    app.auth().signOut().then(() => {
      setBackdropOpen(false)
    }).catch((error) => {
      setBackdropOpen(false)
    });

    setOpen(false);
  };
  
  return (
    <div>
    <Backdrop className={classes.backdrop} open={backdropOpen}>
      <CircularProgress 
        disableShrink
        color="inherit" />
    </Backdrop>
    <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: '#212121',
            boxShadow: 'none',
          },
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography style={{ fontSize: 24, fontWeight: 'bold'}}>Logout</Typography>
        </DialogTitle>
        <DialogContent >
          <DialogContentText id="alert-dialog-description">
            You will be returned to the login screen
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" style={{ fontSize: 14, fontWeight: 'bold'}} onClick={handleClose} color="primary">
            No 
          </Button>
          <Button disableElevation variant="contained" style={{ fontSize: 14, fontWeight: 'bold'}} onClick={handleSignout} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
    </Dialog>
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem style={{ display:'flex', justifyContent: 'center'}}  className={clsx(classes.firebase, classes.itemCategory)}>
          <img height={80} src="/assets/logo/logo-white.png" alt="Sp2s Logo"/>
        </ListItem>

        <ListItem style={{ paddingBottom: 0}}>
          <ListItemText >
            {"Store ID"}
          </ListItemText>
        </ListItem>

        <ListItem  style={{ paddingBottom: 0, paddingTop: 0}}>
          <ListItemText
             className={clsx(classes.item, true && classes.itemActiveItem)}
          >
            {props.currentUser &&  props.currentUser.id ? "" + props.currentUser.id +"": "Store ID No Found"}
          </ListItemText>
        </ListItem>
        
        {(props.currentStaff ? props.currentStaff.role ==="admin" ? categories : categoriesStaff : categories2).map(({ id, children }) => (
          <React.Fragment key={id}>
            <ListItem className={classes.categoryHeader}>
              <ListItemText
                classes={{
                  primary: classes.categoryHeaderPrimary,
                }}
              >
                {id}
              </ListItemText>
            </ListItem>
            {children.map(({ id: childId, icon, notification, url }) => (
              <ListItem
                key={childId}
                button
                className={path === url ? clsx(classes.item, true && classes.itemActiveItem) : clsx(classes.item, false && classes.itemActiveItem)}
                component={Link} to={'/' + url.toLowerCase()} 
                onClick={props.onClose}
              >
                
                <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                <ListItemText
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                >
                  {childId}
                </ListItemText>

                <ListItemIcon className={classes.notificationIcon}>{notification > 0 ? notification : ''}</ListItemIcon>
              </ListItem>
            ))}

            <Divider className={classes.divider} />
          </React.Fragment>
        ))}

        <ListItem
          button
          style={{ marginTop: 10 }}
          className={clsx(classes.item, false && classes.itemActiveItem)}
          onClick={handleClickOpen}
        >
          <ListItemIcon className={classes.itemIcon}><ExitToAppIcon /></ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.itemPrimary,
            }}
          >
            {'Logout'}
          </ListItemText>
        </ListItem>
          </List>
    </Drawer>
    </div>
  );
}

Navigator.propTypes = {
  classes: PropTypes.object.isRequired,
};

const routeNavigator = withRouter(Navigator);

const stylerouteNavigator = withStyles(styles)(routeNavigator);

const mapDispatchToProps = dispatch => ({
  setCurrentUser: item => dispatch(setCurrentUser(item)),
  setCurrentStaff: item => dispatch(setCurrentStaff(item)),
  setItem: (item, user) => dispatch(setItem(item, user)),
  setProductItem: item => dispatch(setProductItem(item)),
  setCategoryItem: item => dispatch(setCategoryItem(item))
});

const mapStatetoProps = createStructuredSelector({
  currentStaff: selectCurrentStaff,
  currentUser: selectCurrentUser,
})

export default connect(mapStatetoProps, mapDispatchToProps)(stylerouteNavigator);