import React, { useEffect } from 'react';
import Table from '../../../modules/components/Table'
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddEditWarehouse from './AddEditWarehouse';
import firebase from "firebase";

export default function Index(props) {

    const [headCells, setHeadCells] = React.useState([]);
    const [headMobileCells, setHeadMobileCells] = React.useState([]);
    const [searchValue, setSearchValue] = React.useState("");
    const [searchCell, setSearchCell] = React.useState([]);
    const [displayInfo, setDisplayInfo] = React.useState([]);
    const [editCells, setEditCells] = React.useState([]);
    const [addCells, setAddCells] = React.useState([]);
    const [addCellsName, setAddCellsName] = React.useState([]);
    const matches = useMediaQuery(theme => theme.breakpoints.up('sm'));

    useEffect(() => {

        setHeadCells([
            { id: 'name', label: 'Name' },
            { id: 'created', label: 'Created' },
            { id: 'warehouse_action', label: 'Action' },
            { id: 'description', label: 'Description' }
        ])

        setHeadMobileCells([
            { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
        ])
    
        setSearchCell([
            { id: 'id', label: 'ID' },
            { id: 'name', label: 'Name' },
        ])

        setDisplayInfo([
            { id: 'id', label: 'ID' },
            { id: 'name', label: 'Name' },
            { id: 'warehouse_action', label: 'Action' },
            { id: 'created', label: 'Created' },
            { id: 'updated', label: 'Updated' },
            { id: 'description', label: 'Description' }
        ])

        setEditCells([
            { id: 'name', label: 'Name' },
            { id: 'description', label: 'Description' }
        ])

        setAddCells([
            { id: 'name', label: 'Name' },
            { id: 'description', label: 'Description' }
        ])

        setAddCellsName({ 
            name: '', 
            created: firebase.database.ServerValue.TIMESTAMP,
            updated: firebase.database.ServerValue.TIMESTAMP,
            description: ''
         })
    
        setSearchValue("name")
        
    }, []);

    return (
    <Grid>
        <Table 
            {...props}
            customizeDialog={AddEditWarehouse}
            control={true}
            hideMove={true}
            hideCheckBox={true}
            hideDelete={true}
            orderBy="created" 
            disabledDelete={true}
            data={"pos/" + firebase.auth().currentUser.displayName + "/warehouse"}
            addItemButton={true}
            addItemText="Export / Receive"
            addCells={addCells}
            addCellsName={addCellsName}
            folder_data={"pos/" + firebase.auth().currentUser.displayName + "/warehouse_zone"}
            displayInfo={displayInfo}
            editCells={editCells}
            matches={matches}
            editButton={true}
            headCells={matches ? headCells : headMobileCells} 
            searchValue={searchValue} 
            searchCell={searchCell}/>
    </Grid>
    )

}
