

import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Dialog from '../../../modules/components/Dialog';
import Users from '../Users';
import Products from '../Products';
import Invoices from '../Invoices';
import Receipts from '../Receipts';
import DeliveryOrder from '../DeliveryOrder';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import firebase from "firebase";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';
import Input from '@material-ui/core/Input';

//table head
function EnhancedTableHead(props) {
    const { headCells } = props;

    return (
        <TableHead >
            <TableRow>
                {headCells ? headCells.map((headCell) => (
                    <TableCell
                        style={{ padding: 3 }}
                        key={headCell.id}
                        align={'left'}
                        sortDirection={false}
                    >
                        {headCell.label}
                    </TableCell>
                )) : null}

            </TableRow>
        </TableHead>
    );
}

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

export default function Index(props) {

    //Dialog
    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogLoading, setDialogLoading] = React.useState(false);
    const [fullScreenDialog, setFullScreenDialog] = React.useState(false);
    const [dialogTitle, setDialogTitle] = React.useState("");
    const [dialogDes, setDialogDes] = React.useState("");
    const [agreeButton, setAgreeButton] = React.useState(false);
    const [option, setOptions] = React.useState("");

    const [selectedCustomer, setSelectedCustomer] = React.useState([]);
    const [selectedInvoice, setSelectedInvoice] = React.useState([]);
    const [selectedReceipt, setSelectedReceipt] = React.useState([]);
    const [selectedDeliveryOrder, setSelectedDeliveryOrder] = React.useState([]);
    const [mediaOption, setMediaOption] = React.useState("customer");
    const [values, setValues] = React.useState({});

    const [orderSerial, setOrderSerial] = React.useState(1);
    const [orderStatus, setOrderStatus] = React.useState([]);
    const [orderHandleBy, setOrderHandleBy] = React.useState("");
    const [selectedShipping, setSelectedShipping] = React.useState("");
    const [allShipping, setAllShipping] = React.useState([]);
    const [orderCourier, setOrderCourier] = React.useState("");
    const [loading, setLoading] = React.useState(true);

    const [selected, setSelected] = React.useState([]);
    const [selectedQuantity, setSelectedQuantity] = React.useState([]);
    const [page2, setPage2] = React.useState(0);
    const [rowsPerPage2, setRowsPerPage2] = React.useState(10);
    const [type, setType] = React.useState("refund");

    const [orderExist, setOrderExist] = React.useState("");

    useEffect(() => {
        setValues(props.addCellsName ? props.addCellsName : {})
        
        if(props.option === "edit"){
            setValues(props.selectedItem ? props.selectedItem : {})
        }

        setLoading(false)

    }, [props]);

    const handleClose = () => {
        setOpenDialog(false)
    };

    const saveCreditNote = (uuid) => {
        var updates = {};

        if(orderExist && selected && selected.length > 0)
        {
            if(props.option === "edit"){
                values.id = props.selectedItem.id;
                values.user = firebase.auth().currentUser.email;
                values.products = selected;
                values.products_quantity = selectedQuantity;
                values.customer = selectedCustomer;
                values.credit_note_type = type;
                values.uuid = parseInt(uuid).toLocaleString('en-US', {minimumIntegerDigits: 8, useGrouping:false});
                values.orders = orderExist;
                values.orders_uuid = orderExist.uuid;
                values.orders_id = orderExist.id;
                
                var total_amount = 0;
                var total_amount_cost = 0;
    
                selected.forEach((val,index)=> {
                    total_amount += (Number(val.retail_price)*selectedQuantity[index])
                    total_amount_cost += (Number(val.cost_price)*selectedQuantity[index])
                })
                values.credit_note_amount = total_amount;
                values.credit_note_amount_cost = total_amount_cost;
    
                updates[props.data + '/' + props.selectedItem.id] = values;
        
                firebase.database().ref().update(updates);
            }
            else{
                var newPostKey = firebase.database().ref().child(props.data).push().key;
    
                values.id = newPostKey;
                values.user = firebase.auth().currentUser.email;
                values.products = selected;
                values.products_quantity = selectedQuantity;
                values.customer = selectedCustomer;
                values.credit_note_type = type;
                values.uuid = parseInt(uuid).toLocaleString('en-US', {minimumIntegerDigits: 8, useGrouping:false});
                values.orders = orderExist;
                values.orders_uuid = orderExist.uuid;
                values.orders_id = orderExist.id;
    
                var total_amount = 0;
                var total_amount_cost = 0;
    
                selected.forEach((val,index)=> {
                    total_amount += (Number(val.retail_price)*selectedQuantity[index])
                    total_amount_cost += (Number(val.cost_price)*selectedQuantity[index])
                    val.products_quantity = selectedQuantity[index]
                })
                values.credit_note_amount = total_amount;
                values.credit_note_amount_cost = total_amount_cost;
        
                updates[props.data + '/' + newPostKey] = values;
        
                firebase.database().ref().update(updates);
    
            }
            
            
            
    
            props.handleClose()
        }

        
    }

    const handleAgree = () => {

        let creditNoteUUIDRef = firebase.database().ref("pos/" + firebase.auth().currentUser.displayName + "/credit_note_uuid")

        creditNoteUUIDRef.once('value', snapshot => {
            if(snapshot.exists()){
            let val = snapshot.val()

            let newUUID = Number(val.uuid)+1
            saveCreditNote(newUUID)
            firebase.database().ref("pos/" + firebase.auth().currentUser.displayName + "/credit_note_uuid").update({ uuid: newUUID })
            }
            else{
            saveCreditNote(1)
            firebase.database().ref("pos/" + firebase.auth().currentUser.displayName + "/credit_note_uuid").update({ uuid: 1 })
            }

        })

        
    }

    const handleChangeType = (event) => {
        setType(event.target.value)
    }

    const handleChangeRowsPerPage2 = (event) => {
        setRowsPerPage2(parseInt(event.target.value, 10));
        setPage2(0);
    };

    const handleChangePage2 = (event, newPage) => {
        setPage2(newPage);
    };

    const addQuantity = (index) => {
        const newQuantityItem = [...selectedQuantity]
        newQuantityItem[index] = Number(newQuantityItem[index]) + 1;

        if(newQuantityItem[index] > selected[index].ordered_quantity )
        {
            newQuantityItem[index] = Number(selected[index].ordered_quantity);
        }

        setSelectedQuantity(newQuantityItem)

    }

    const minusQuantity = (index) => {
        const newQuantityItem = [...selectedQuantity]

        if(Number(newQuantityItem[index]) > 1){
            newQuantityItem[index] = Number(newQuantityItem[index]) - 1;
            setSelectedQuantity(newQuantityItem);
        }
    
    }

    const handleSetRows = (row) => {
        if(mediaOption === "customer"){
            setSelectedCustomer(row)
        }
        else if(mediaOption === "invoice"){
            setSelectedInvoice(row)
        }
        else if(mediaOption === "receipt"){
            setSelectedReceipt(row)
        }
        else if(mediaOption === "delivery_order"){
            setSelectedDeliveryOrder(row)
        }
        else if(mediaOption === "product")
        {

            let newRow = [];
            let newQuantity = []
            row.forEach(val => {
                let item = val;
                item.warehouse_quantity = 1;
                newRow.push(item)
                newQuantity.push(1)
            })

            setSelected(newRow)
            setSelectedQuantity(newQuantity)
        }

        setOpenDialog(false)
    }

    const renderComponent = () => {
        if(mediaOption === "customer"){
            return(<Users mediaOption={mediaOption} handleSetRows={(rows) => handleSetRows(rows)}/>)
        }
        else if(mediaOption === "product"){
            return(<Products orderExist={orderExist} mediaOption={mediaOption} handleSetRows={(rows) => handleSetRows(rows)}/>)
        }
        else if(mediaOption === "invoice"){
            return(<Invoices mediaOption={mediaOption} handleSetRows={(rows) => handleSetRows(rows)}/>)
        }
        else if(mediaOption === "receipt"){
            return(<Receipts mediaOption={mediaOption} handleSetRows={(rows) => handleSetRows(rows)}/>)
        }
        else if(mediaOption === "delivery_order"){
            return(<DeliveryOrder mediaOption={mediaOption} handleSetRows={(rows) => handleSetRows(rows)}/>)
        }
    }

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleChangeStatusOrder = (event) => {
        setOrderSerial(event.target.value)
    }
    

    const handleChangeOrderhandleBy = (event) => {
        setOrderHandleBy(event.target.value)
    }

    const handleChangeShipping = (event) => {
        setSelectedShipping(event.target.value)
    }

    const handleChangeOrderCourier = (event) => {
        setOrderCourier(event.target.value)
    }

    const addCustomer = () => {
        setMediaOption("customer")
        setDialogLoading(false)
        setAgreeButton(false)
        setDialogTitle("Add Customer")
        setOptions("addItem")
        setDialogDes("")
        setFullScreenDialog(true)
        setOpenDialog(true)
    }

    const addProduct = () => {
        setMediaOption("product")
        setDialogLoading(false)
        setAgreeButton(false)
        setDialogTitle("Receive Products")
        setOptions("addItem")
        setDialogDes("")
        setFullScreenDialog(true)
        setOpenDialog(true)
    }

    const addInvoice = () => {
        setMediaOption("invoice")
        setDialogLoading(false)
        setAgreeButton(false)
        setDialogTitle("Add Invoice")
        setOptions("addItem")
        setDialogDes("")
        setFullScreenDialog(true)
        setOpenDialog(true)
    }

    const addReceipt = () => {
        setMediaOption("receipt")
        setDialogLoading(false)
        setAgreeButton(false)
        setDialogTitle("Add Receipt")
        setOptions("addItem")
        setDialogDes("")
        setFullScreenDialog(true)
        setOpenDialog(true)
    }

    const addDeliveryOrder = () => {
        setMediaOption("delivery_order")
        setDialogLoading(false)
        setAgreeButton(false)
        setDialogTitle("Add Delivery Order")
        setOptions("addItem")
        setDialogDes("")
        setFullScreenDialog(true)
        setOpenDialog(true)
    }

    const onClickClearCustomer = (event) => {
        setSelectedCustomer([])
    }

    const onClickClearProduct = (event) => {
        setSelected([])
        setSelectedQuantity([])
    }

    const onClickSearchOrders = () => {
        if(values["orders"]){

            if(values["orders"].length < 8)
            {
                let total = 8 - values["orders"].length;
                for (let i = 0; i < total; i++) {
                    values["orders"] = "0" + values["orders"];
                }
            }

            
            let itemRef = firebase.database().ref("pos/" + firebase.auth().currentUser.displayName + "/orders");

            itemRef.on('value', snapshot => {
                if(snapshot.exists()){
                    let items = null;
          
                    snapshot.forEach(child => {
                        var itemsVal = child.val();

                    
                        if(itemsVal.uuid === values["orders"])
                        {
                            items = itemsVal;
                        }
                    })

                    setOrderExist(items)
                }
                else{
                    setOrderExist(null)
                }
            })

        }
        else
        {
            setOrderExist(null)
        }
    }

    const onClickClearInvoice = (event) => {
        setSelectedInvoice([])
    }

    const onClickClearReceipt = (event) => {
        setSelectedReceipt([])
    }

    const onClickClearDeliveryOrder = (event) => {
        setSelectedDeliveryOrder([])
    }

    const removeProduct = (index) => {
        setSelected([
            ...selected.slice(0, index),
            ...selected.slice(index + 1)
          ]);
        setSelectedQuantity([
            ...selectedQuantity.slice(0, index),
            ...selectedQuantity.slice(index + 1)
          ]);
    }

    const thumbsCustomer = selectedCustomer.map((file, index) => {

        return(
            <Grid key={file.name+index} style={{ display:"flex", flexDirection: "column"}}>
                <Grid style={{overflow: "hidden", padding: 8}}>
                    <Typography rows={2} style={{ color : "white"}} variant="caption" noWrap={true}>{file.last_name + " " + file.first_name}</Typography>
                </Grid>
            </Grid>
           
        )
    });

    const thumbsInvoice = selectedInvoice.map((file, index) => {

        return(
            <Grid key={file.name+index} style={{ display:"flex", flexDirection: "column"}}>
                <Grid style={{overflow: "hidden", padding: 8}}>
                    <Typography rows={2} style={{ color : "white"}} variant="caption" noWrap={true}>{file.name}</Typography>
                </Grid>
            </Grid>
           
        )
    });

    const thumbsReceipt = selectedReceipt.map((file, index) => {

        return(
            <Grid key={file.name+index} style={{ display:"flex", flexDirection: "column"}}>
                <Grid style={{overflow: "hidden", padding: 8}}>
                    <Typography rows={2} style={{ color : "white"}} variant="caption" noWrap={true}>{file.name}</Typography>
                </Grid>
            </Grid>
           
        )
    });

    const thumbsDeliveryOrder = selectedDeliveryOrder.map((file, index) => {

        return(
            <Grid key={file.name+index} style={{ display:"flex", flexDirection: "column"}}>
                <Grid style={{overflow: "hidden", padding: 8}}>
                    <Typography rows={2} style={{ color : "white"}} variant="caption" noWrap={true}>{file.name}</Typography>
                </Grid>
            </Grid>
           
        )
    });

    if(loading){
        return(
            <Grid style={{ padding: 40, display: "flex", justifyContent: "center", alignItems: "center" }}>
                <CircularProgress 
                disableShrink
                color="primary" />
            </Grid>
        )
    }

    return (
        <Grid style={{ padding: 30 , maxWidth: 1000 }}>
            <Dialog 
                agreeTxt={"Yes"}
                disagreeTxt={"No"}
                description={dialogDes}
                title={dialogTitle}
                open={openDialog} 
                agreeButton={agreeButton}
                fullScreenDialog={fullScreenDialog}
                option={option}
                dialogLoading={dialogLoading}
                component={renderComponent()}
                handleClose={handleClose}/>

            <Grid container >
                
                <Grid item md={12} xs={12}>
                    <Grid container style={{ minWidth: 500, paddingBottom: 10, display: "flex", flexDirection: "row" }}>
                        {props[props.option === "edit" ? "editCells" : "addCells"] ? props[props.option === "edit" ? "editCells" : "addCells"].map((cell, index)=>{ 
                        const labelCellId = `add-cell-${index}`;
                        var sizeMd = 6;

                        if(index === props.addCells.length - 1){
                            if((index+1) % 2 !== 0){
                            sizeMd = 12;
                            } 
                        }

                        if(cell.id === "orders"){

                            return(
                                <Grid key={labelCellId} xs={12} md={12} style={{flexDirection: "column", padding: 10, display: "flex", justifyContent: "flex-start", alignItems: "center"}} item >
                                    <Grid container style={{ width: "100%" , height: "100%"}}>
                                    
                                        <Grid item md={9}>
                                            <TextField
                                                error={orderExist  === null ? true : false }
                                                key={"textfield-input-"+index}
                                                label={cell.label}
                                                style={{ width: "100%"}}
                                                value={values[cell.id] || ''}
                                                onChange={handleChange(cell.id)}
                                                variant="outlined"/>
                                        </Grid>

                                        <Grid item md={3} style={{ paddingLeft: 10 }} >
                                        <Button style={{ width: "100%" , height: "100%" }} onClick={onClickSearchOrders} color="primary" disableElevation  variant="contained">
                                            Search
                                        </Button>
                                        </Grid>
                                    
                                    </Grid>
                                </Grid>
                            )
                        }

                        
                        if(cell.id === "product"){
                            return(<Grid key={labelCellId} xs={12} md={12} style={{flexDirection: "column", padding: 10, display: "flex", justifyContent: "flex-start", alignItems: "center"}} item >
                           

                           {orderExist ? <Grid container style={{ width: "100%" , height: "100%"}}>
                                
                                <Grid item md={9}>
                                <Button onClick={addProduct} color="primary" style={{ width: "100%" , height: "100%"}} 
                                startIcon={<AddCircleIcon/>} 
                                disableElevation  variant="contained">{"Product"}</Button>
                                </Grid>

                                <Grid item md={3} style={{ paddingLeft: 10 }} >
                                <Button style={{ width: "100%" , height: "100%" }} disabled={selected.length > 0 ? false : true } onClick={onClickClearProduct} variant="outlined" color="primary">
                                    Clear
                                </Button>
                                </Grid>
                            
                            </Grid> : null}

                            <Grid style={{ width: "100%", marginTop: 10 }} >
                        <TableContainer>
                            <Table style={{ width: "100%" }} aria-label="custom pagination table">
                                <EnhancedTableHead
                                    headCells={[
                                        { id: 'no', numeric: false, disablePadding: false, label: 'No.' },
                                        { id: 'image', numeric: false, disablePadding: false, label: 'img' },
                                        { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
                                        { id: 'quantity', numeric: false, disablePadding: false, label: 'Qty' },
                                        { id: 'price', numeric: false, disablePadding: false, label: 'Price' },
                                        { id: 'action', numeric: false, disablePadding: false, label: '' }]}
                                />

                                <TableBody>
                                    {(rowsPerPage2 > 0
                                        ? selected.slice(page2 * rowsPerPage2, page2 * rowsPerPage2 + rowsPerPage2)
                                        : selected
                                    ).map((row, index) => {
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow hover
                                                key={labelId}
                                                role="checkbox"
                                                tabIndex={-1} >

                                                <TableCell style={{ padding: 10, width: 10 }} align="left">
                                                    <Typography variant="body2">{(index + 1) + (page2 * 10) + "."}</Typography>
                                                </TableCell>
                                                <TableCell style={{ padding: 10, width: 10 }} align="left">
                                                <Avatar alt={"image"+index} src={row.image ? row.image[0].url : ""} />
                                                </TableCell>
                                                <TableCell style={{ padding: 3, overflow: "hidden", textOverflow: "ellipsis", maxWidth: 150 }} align="left">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell style={{ padding: 3, overflow: "hidden", textOverflow: "ellipsis", maxWidth: 150 }} align="left">
                                                    <Grid style={{  display: "flex", flexDirection: "row", alignItems: "center" }} >
                                                        <IconButton
                                                        style={{ opacity: .7, width: 20, height: 20 }}
                                                        size={"small"}
                                                        onClick={()=> addQuantity(index)}
                                                        >
                                                        <Add style={{ fontSize: 18 }} />
                                                        </IconButton>
                                                        <Grid style={{ height: 30, display: "flex", alignItems: "center" }} >
                                                        <Input 
                                                        type="text" 
                                                        inputProps={{min:1, style: { textAlign: 'center' }}}
                                                        value={selectedQuantity[index]}
                                                        onChange={(e)=>{

                                                            var isNumber = /^\d+$/.test(e.target.value)
                                                            const newQuantityItem = [...selectedQuantity]
                                                            if(isNumber){
                                                                if(Number(e.target.value) > 1){
                                                                    

                                                                    if(Number(e.target.value) > selected[index].ordered_quantity )
                                                                    {
                                                                        newQuantityItem[index] = Number(selected[index].ordered_quantity);
                                                                    }
                                                                    else
                                                                    {
                                                                        newQuantityItem[index] = Number(e.target.value);
                                                                    }
                                                                    setSelectedQuantity(newQuantityItem)
                                                                }
                                                                else{
                                                                    newQuantityItem[index] = 1;
                                                                    setSelectedQuantity(newQuantityItem)
                                                                }
                                                                
                                                            }
                                                        }}
                                                        style={{ fontSize: 14, width: 40, padding: 0, textAlign: "center"  }} />
                                                        </Grid>

                                                        <IconButton
                                                        style={{ opacity: .7, width: 20, height: 20 }}
                                                        size={"small"}
                                                        onClick={()=> minusQuantity(index)}
                                                        >
                                                        <Remove style={{ fontSize: 18 }} />
                                                        </IconButton>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell style={{ padding: 3, overflow: "hidden", textOverflow: "ellipsis", maxWidth: 150 }} align="left">
                                                    {row.retail_price}
                                                </TableCell>
                                                <TableCell style={{ padding: 3, overflow: "hidden", textOverflow: "ellipsis", maxWidth: 150 }} align="left">
                                                    <IconButton onClick={()=> removeProduct(index)}>
                                                        <DeleteIcon fontSize="small"/>
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}

                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            labelRowsPerPage={""}
                                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                            colSpan={6}
                                            count={selected.length}
                                            rowsPerPage={rowsPerPage2}
                                            page={page2}
                                            SelectProps={{
                                                inputProps: { 'aria-label': 'rows per page' },
                                                native: true,
                                            }}
                                            onPageChange={handleChangePage2}
                                            onRowsPerPageChange={handleChangeRowsPerPage2}
                                            //ActionsComponent={TablePaginationActions}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                        
                    </Grid>
                                
                            </Grid>)
                        }

                        if(cell.id === "customer"){
                            return(<Grid key={labelCellId} xs={12} md={12} style={{flexDirection: "column", padding: 10, display: "flex", justifyContent: "flex-start", alignItems: "center"}} item >
                            <Grid container style={{ width: "100%" , height: "100%"}}>
                                
                                <Grid item md={9}>
                                <Button onClick={addCustomer} color="primary" style={{ width: "100%" , height: "100%"}} 
                                startIcon={<AddCircleIcon/>} 
                                disableElevation  variant="contained">{"Customer"}</Button>
                                </Grid>

                                <Grid item md={3} style={{ paddingLeft: 10 }} >
                                <Button style={{ width: "100%" , height: "100%" }} disabled={selectedCustomer.length > 0 ? false : true } onClick={onClickClearCustomer} variant="outlined" color="primary">
                                    Clear
                                </Button>
                                </Grid>
                            
                            </Grid>

                                <Grid>
                                <aside style={thumbsContainer}>
                                    {thumbsCustomer}
                                </aside>
                                </Grid>
                                
                            </Grid>)
                        }

                        if(cell.id === "status_orders"){
                            return (<Grid key={labelCellId} xs={12} md={12} style={{  padding: 10, flexDirection: "column", display: "flex", justifyContent: "center", alignItems: "center"}} item >
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                    <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={orderStatus.length > 0 ? orderSerial : ""}
                                    label="Status"
                                    onChange={handleChangeStatusOrder}
                                    >
                                    {orderStatus.map((val, indexOrder)=>{
                                        return(
                                            <MenuItem key={'order_status_menu_item_' + indexOrder} value={parseInt(val.serial)}>{val.name}</MenuItem>
                                        )
                                    })}
                                    </Select>
                                </FormControl>
                                {orderSerial === 4 ? <FormControl style={{ marginTop : 10 }} fullWidth>
                                    <InputLabel id="demo-simple-select-label">Handle by</InputLabel>
                                    <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={orderHandleBy}
                                    label="Handle by"
                                    onChange={handleChangeOrderhandleBy}
                                    >
                                        <MenuItem value={"main_office"}>{"Main Office"}</MenuItem>
                                        <MenuItem value={"seri_petalling"}>{"Sri Petaling"}</MenuItem>
                                    </Select>
                                </FormControl> : null}

                                {orderSerial === 6 ? <Grid style={{ width: "100%" , flexDirection: "column", marginTop: 10, display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                                    <Grid container style={{ width: "100%" , height: "100%"}}>
                                    
                                        <Grid item md={9}>
                                        <Button onClick={addInvoice} color="primary" style={{ width: "100%" , height: "100%"}} 
                                        startIcon={<AddCircleIcon/>} 
                                        disableElevation  variant="contained">{"Invoice"}</Button>
                                        </Grid>

                                        <Grid item md={3} style={{ paddingLeft: 10 }} >
                                        <Button style={{ width: "100%" , height: "100%" }} disabled={selectedInvoice.length > 0 ? false : true } onClick={onClickClearInvoice} variant="outlined" color="primary">
                                            Clear
                                        </Button>
                                        </Grid>
                                    
                                        <FormControl style={{ marginTop : 10 }} fullWidth>
                                            <InputLabel id="demo-simple-select-label">Shipping</InputLabel>
                                            <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedShipping}
                                            label="Handle by"
                                            onChange={handleChangeShipping}
                                            >
                                                <MenuItem value={""}>{"None"}</MenuItem>
                                                {allShipping.map(val => {
                                                    return <MenuItem value={val.id}>{val.name + " (RM " + val.amount + ")"}</MenuItem>
                                                })}
    
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid>
                                    <aside style={thumbsContainer}>
                                        {thumbsInvoice}
                                    </aside>
                                    </Grid>
                                </Grid> : null}

                                {orderSerial === 7 ? <Grid style={{ width: "100%" , flexDirection: "column", marginTop: 10, display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                                    <Grid container style={{ width: "100%" , height: "100%"}}>
                                    
                                        <Grid item md={9}>
                                        <Button onClick={addReceipt} color="primary" style={{ width: "100%" , height: "100%"}} 
                                        startIcon={<AddCircleIcon/>} 
                                        disableElevation  variant="contained">{"Receipt"}</Button>
                                        </Grid>

                                        <Grid item md={3} style={{ paddingLeft: 10 }} >
                                        <Button style={{ width: "100%" , height: "100%" }} disabled={selectedReceipt.length > 0 ? false : true } onClick={onClickClearReceipt} variant="outlined" color="primary">
                                            Clear
                                        </Button>
                                        </Grid>
                                    
                                    </Grid>

                                    <Grid>
                                    <aside style={thumbsContainer}>
                                        {thumbsReceipt}
                                    </aside>
                                    </Grid>
                                </Grid> : null}

                                {orderSerial === 5 ? <Grid style={{ width: "100%" , flexDirection: "column", marginTop: 10, display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                                    <Grid container style={{ width: "100%" , height: "100%"}}>
                                    
                                        <Grid item md={9}>
                                        <Button onClick={addDeliveryOrder} color="primary" style={{ width: "100%" , height: "100%"}} 
                                        startIcon={<AddCircleIcon/>} 
                                        disableElevation  variant="contained">{"Delivery Order"}</Button>
                                        </Grid>

                                        <Grid item md={3} style={{ paddingLeft: 10 }} >
                                        <Button style={{ width: "100%" , height: "100%" }} disabled={selectedDeliveryOrder.length > 0 ? false : true } onClick={onClickClearDeliveryOrder} variant="outlined" color="primary">
                                            Clear
                                        </Button>
                                        </Grid>
                                    
                                    </Grid>

                                    <Grid>
                                    <aside style={thumbsContainer}>
                                        {thumbsDeliveryOrder}
                                    </aside>
                                    </Grid>
                                </Grid> : null}

                                {orderSerial === 9 ? <FormControl style={{ marginTop : 10 }} fullWidth>
                                    <InputLabel id="demo-simple-select-label">Courier</InputLabel>
                                    <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={orderCourier}
                                    label="Courier"
                                    onChange={handleChangeOrderCourier}
                                    >
                                        <MenuItem value={"jnt"}>{"JNT"}</MenuItem>
                                        <MenuItem value={"lalamove"}>{"Lalamove"}</MenuItem>
                                    </Select>
                                </FormControl> : null}
                            </Grid>)
                        }

                        if(cell.id === "credit_note_amount"){

                            var total_amount = 0;

                            selected.forEach((val,index)=> {
                                total_amount += (Number(val.retail_price)*selectedQuantity[index])
                            })

                            return(
                                <Grid key={labelCellId} xs={12} md={12} style={{  padding: 10, display: "flex", alignItems: "center"}} item >
                                <Typography style={{ color : "white"}} variant="h5" noWrap={true}>{"Total: RM " + total_amount.toFixed(2)}</Typography>
                                </Grid>
                            )
                        }

                        if(cell.id === "description"){
                            return (<Grid key={labelCellId} xs={12} md={12} style={{  padding: 10, display: "flex", justifyContent: "center", alignItems: "center"}} item >
                                     <TextField
                                        key={"textfield-input-"+index}
                                        label={cell.label}
                                        style={{ width: "100%"}}
                                        multiline
                                        minRows={3}
                                        value={values[cell.id] || ''}
                                        onChange={handleChange(cell.id)}
                                        variant="outlined"/></Grid>
                                )
                        }

                        if(cell.id === "credit_note_type"){
                            return (values[cell.id] !== "admin" ? <Grid key={labelCellId} xs={12} md={sizeMd} style={{  padding: 10, display: "flex", justifyContent: "center", alignItems: "center"}} item >
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                    <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={type}
                                    required
                                    label="Status"
                                    onChange={handleChangeType}
                                    >
                                    <MenuItem value={"refund"}>Refund</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid> :null)
                        }
                        
                        
                        return(   
                            <Grid key={labelCellId} xs={12} md={sizeMd} style={{  padding: 10, display: "flex", justifyContent: "center", alignItems: "center"}} item >
                                <TextField
                                    key={"textfield-input-"+index}
                                    label={cell.label}
                                    style={{ width: "100%"}}
                                    value={values[cell.id] || ''}
                                    onChange={handleChange(cell.id)}
                                    variant="outlined"/></Grid>
                                )}):null }
                                
                        </Grid>
                    <Grid style={{ padding: 10, marginBottom: 30, display: 'flex', justifyContent: "flex-end" }}>
                        <Button variant="outlined" style={{ fontSize: 14, fontWeight: 'bold'}} onClick={props.handleClose} color="primary">
                        {"No"}
                        </Button>
                        <Button disableElevation variant="contained" style={{ marginLeft: 10, fontSize: 14, fontWeight: 'bold'}} onClick={handleAgree} color="primary" autoFocus>
                        {"Yes"}
                        </Button>
                    </Grid>
                </Grid>

            </Grid>
            
            
        </Grid>
    )

}
  