import React, { useEffect } from 'react';
import Table from '../../../modules/components/Table'
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddEditCustomer from './AddEditCustomer';
import firebase from "firebase";

export default function Index(props) {

    const [headCells, setHeadCells] = React.useState([]);
    const [headMobileCells, setHeadMobileCells] = React.useState([]);
    const [searchValue, setSearchValue] = React.useState("");
    const [searchCell, setSearchCell] = React.useState([]);
    const [displayInfo, setDisplayInfo] = React.useState([]);
    const [editCells, setEditCells] = React.useState([]);
    const [addCells, setAddCells] = React.useState([]);
    const [addCellsName, setAddCellsName] = React.useState([]);
    const matches = useMediaQuery(theme => theme.breakpoints.up('sm'));

    useEffect(() => {

        setHeadCells([
            { id: 'customer_id', label: 'Customer ID' },
            { id: 'first_name', label: 'First Name' },
            { id: 'last_name', label: 'Last Name' },
            { id: 'customer_point', label: 'Point' },
            { id: 'gender', label: 'Gender' },
            { id: 'email', label: 'Email' },
            { id: 'phone', label: 'Phone' },
        ])

        setHeadMobileCells([
            { id: 'customer_id', label: 'Customer ID' },
        ])
    
        setSearchCell([
            { id: 'customer_id', label: 'Customer ID' },
            { id: 'phone', label: 'Phone' },
            { id: 'email', label: 'Email' },
        ])

        setDisplayInfo([
            { id: 'customer_id', label: 'Customer ID' },
            { id: 'first_name', label: 'First Name' },
            { id: 'last_name', label: 'Last Name' },
            { id: 'created', label: 'Registered' },
            { id: 'gender', label: 'Gender' },
            { id: 'customer_point', label: 'Point' },
            { id: 'email', label: 'Email' },
            { id: 'phone', label: 'Phone' },
            { id: 'ic', label: 'IC' },
            { id: 'address', label: 'Address' },
            { id: 'city', label: 'City' },
            { id: 'state', label: 'State' },
            { id: 'postcode', label: 'Postal Code' },
            { id: 'country', label: 'Country' },
            { id: 'comments', label: 'Comments' },
        ])

        setEditCells([
            { id: 'first_name', label: 'First Name' },
            { id: 'last_name', label: 'Last Name' },
            { id: 'gender', label: 'Gender' },
            { id: 'email', label: 'Email' },
            { id: 'phone', label: 'Phone' },
            { id: 'ic', label: 'IC' },
            { id: 'address', label: 'Address' },
            { id: 'city', label: 'City' },
            { id: 'state', label: 'State' },
            { id: 'postcode', label: 'Postal Code' },
            { id: 'country', label: 'Country' },
            { id: 'comments', label: 'Comments' },
        ])

        setAddCells([
            { id: 'first_name', label: 'First Name' },
            { id: 'last_name', label: 'Last Name' },
            { id: 'gender', label: 'Gender' },
            { id: 'email', label: 'Email' },
            { id: 'phone', label: 'Phone' },
            { id: 'ic', label: 'IC' },
            { id: 'address', label: 'Address' },
            { id: 'city', label: 'City' },
            { id: 'state', label: 'State' },
            { id: 'postcode', label: 'Postal Code' },
            { id: 'country', label: 'Country' },
            { id: 'comments', label: 'Comments' },
        ])

        setAddCellsName({ 
            first_name: '',
            last_name: '',
            created: firebase.database.ServerValue.TIMESTAMP,
            updated: firebase.database.ServerValue.TIMESTAMP,
            email: '',
            phone: '',
            address: '',
            city: '',
            state: '',
            postcode: '',
            country: '',
            comments: '',
         })
    
        setSearchValue("customer_id")
        
    }, []);

    return (
    <Grid>
        <Table 
            {...props}
            customizeDialog={AddEditCustomer}
            control={true}
            hideCheckBox={true}
            hideMove={true}
            orderBy="created" 
            data={"customer" }
            searchCustomer={true}
            addItemButton={true}
            editButton={true}
            addItemText="Add Customer"
            addItemIcon={<AddCircleIcon/>}
            addCells={addCells}
            addCellsName={addCellsName}
            folder_data={"customer_zone"}
            displayInfo={displayInfo}
            editCells={editCells}
            matches={matches}
            headCells={matches ? headCells : headMobileCells} 
            searchValue={searchValue} 
            searchCell={searchCell}/>
    </Grid>
    )

}
  