import React, { useEffect }  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import firebase from "firebase";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentStaff } from '../../redux/staff/staff.selector'

const useStyles = makeStyles((theme) => ({
  root: {
    display:"flex",
    flexGrow: 1,
    flexDirection: "column",
    alignItems: "center",
  },
}));

function Index (props){

  const classes = useStyles();
  const [ordersDetails, setOrdersDetails] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [allproducts, setAllproducts] = React.useState([]);
  const [totalQuantity, setTotalQuantity] = React.useState(0);


  useEffect(() => {

    if(props.ordersDetails){
        setLoading(false)
        setOrdersDetails(props.ordersDetails)
        
        if(props.ordersDetails.all_products)
        {
          let group = {};
          let totalQuantity = 0
          props.ordersDetails.all_products.forEach(item => {

            if(group[item.id] && group[item.id].id === item.id){
              group[item.id].quantity += Number(item.quantity);

              
            }
            else
            {
              item.quantity = Number(item.quantity);
              group[item.id] = {...item};

            }

            totalQuantity += Number(item.quantity);
          })
          setTotalQuantity(totalQuantity)
          setAllproducts(Object.values(group))

        }
        
    }
    
    
  }, [props]);

  const renderAmount = (id) => {
    var total_amount = 0;

    if(props.ordersDetails[id])
    {
      props.ordersDetails[id].forEach(val => {
        total_amount += Number(val)
        })
    }
   

    return "RM " + total_amount.toFixed(2)
  }


  if(loading){
    return (
    <Grid className={classes.root}>
      <Grid style={{ marginTop: 70 }}>
        <CircularProgress 
          disableShrink
          color="primary" />
      </Grid>
    </Grid>);
  }

  

  return (
    <ThemeProvider
    theme={theme}
    >
    <Paper elevation={0} style={{ marginBottom: 50 }}>
      <Grid style={{ width: 320, flexDirection: "row", paddingBottom: 20 }}>
        <Grid style={{ padding: 15, display: "flex", flexDirection: "column" }}>
          <Grid>
          
          
          <Grid style={{ display: "flex", flexDirection: "column", marginTop: 10 }}>
          <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
          <img height={120} src="/assets/logo/logo-dark.png" alt="Sp2s Logo"/>
          </Grid>
            
          </Grid>
         
          
          </Grid>

          <Grid style={{ paddingTop: 10, display: "flex", flexDirection: "column", justifyContent: "space-between" }} >
            <Grid>
              <Typography align='center' variant="body2" style={{ fontWeight: "bold", color: "black" }}>
                    {ordersDetails.store_name ? ordersDetails.store_name : ""}
                  </Typography>
            </Grid>
                  
           </Grid>

           {ordersDetails.company_name ? <Grid style={{ paddingTop: 10, display: "flex", flexDirection: "column", justifyContent: "space-between" }} >
            <Grid>
              <Typography align='center' variant="body2" style={{ fontWeight: "normal", color: "black" }}>
                    {ordersDetails.company_name ? ordersDetails.company_name : ""}
                  </Typography>
            </Grid>
                  
           </Grid>:null}

           {ordersDetails.ssm_id ? <Grid style={{ paddingTop: 10, display: "flex", flexDirection: "column", justifyContent: "space-between" }} >
            <Grid>
              <Typography align='center' variant="body2" style={{ fontWeight: "normal", color: "black" }}>
                    {ordersDetails.ssm_id ? ordersDetails.ssm_id : ""}
                  </Typography>
            </Grid>
                  
           </Grid>:null}

           {ordersDetails.store_address ? <Grid style={{ paddingTop: 10, display: "flex", flexDirection: "column", justifyContent: "space-between" }} >
            <Grid>
              <Typography align='center' variant="body2" style={{ fontWeight: "normal", color: "black" }}>
                    {ordersDetails.store_address ? ordersDetails.store_address : ""}
                  </Typography>
            </Grid>
            <Typography variant="h6" align='center' style={{ fontWeight: "bold", color: "black", marginTop: 10 }}>
                    {"DAILY CHECKOUT RECEIPT"}
          </Typography>
           </Grid>:null}

           <Grid style={{ paddingTop: 10, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <Grid style={{ display: "flex", flexDirection: "column" }} >
              <Grid>
                <Typography variant="caption"  style={{ fontWeight: "normal", color: "black" }}>
                  {"Print Date"}
                </Typography>
              </Grid>
              <Grid>
                <Typography variant="body2" style={{ fontWeight: "normal", color: "black" }}>
                      {new Intl.DateTimeFormat('en-GB', {year: 'numeric',day: '2-digit',  month: 'long', hour: '2-digit', minute: '2-digit', second: 'numeric' }).format(ordersDetails.created)}
                    </Typography>
              </Grid>
                    
            </Grid>

           </Grid>

           <Grid style={{ paddingTop: 10, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <Grid style={{ display: "flex", flexDirection: "column" }} >
              <Grid>
                <Typography variant="caption"  style={{ fontWeight: "normal", color: "black" }}>
                  {"Check Out Date"}
                </Typography>
              </Grid>
              <Grid>
                <Typography variant="body2" style={{ fontWeight: "normal", color: "black" }}>
                      {ordersDetails.checkout_created ? ordersDetails.checkout_created : ""}
                    </Typography>
              </Grid>
                    
            </Grid>

           </Grid>
           
        </Grid>

        <Divider light style={{ width: "100%"}} />

        <Grid style={{ padding: 15 }}>
          <Grid style={{ display: "flex", flexDirection: "column" }}>
              <Grid>
                <Grid>
                  <Typography variant="caption" style={{ fontWeight: "normal", color: "black" }}>
                    {"Cashier"}
                  </Typography>
                  <Typography variant="body2"  style={{ fontWeight: "normal", color: "black" }}>
                    {ordersDetails.cashier ? (ordersDetails.cashier.last_name + " " + ordersDetails.cashier.first_name)  : "N/A"}
                  </Typography>
                </Grid>
               
              </Grid>
              
             
          </Grid>
        </Grid>

        <Grid style={{ padding: 15, display: "flex", flexDirection: "column" }}>
          
          <Grid style={{ display: "flex", justifyContent: "flex-end", flexDirection: "row", alignItems: "flex-end" }} >
            <Grid container>
              <Grid item xs={8}>
                <Typography variant="body2" noWrap style={{ paddingTop: 10, fontWeight: "normal", color: "black" }}>
                  {"Total Sales"}
                </Typography>
                <Typography variant="body2" noWrap style={{ paddingTop: 10, fontWeight: "normal", color: "black" }}>
                  {"Total Discount"}
                </Typography>
                <Typography variant="body2" noWrap style={{ paddingTop: 10, fontWeight: "normal", color: "black" }}>
                  {"Total Credit Note"}
                </Typography>
                <Typography variant="body2" noWrap style={{ paddingTop: 10, fontWeight: "normal", color: "black" }}>
                  {"Net TurnOver"}
                </Typography>
                {props.currentStaff && props.currentStaff.role === "cashier" ? null : <Typography variant="body2" noWrap style={{ paddingTop: 10, fontWeight: "normal", color: "black" }}>
                  {"Total Cost of Goods Sold"}
                </Typography>}
                {props.currentStaff && props.currentStaff.role === "cashier" ? null : <Typography variant="body2" noWrap style={{ paddingTop: 10, fontWeight: "normal", color: "black" }}>
                  {"Total Credit Note Cost"}
                </Typography>}
                {props.currentStaff && props.currentStaff.role === "cashier" ? null : <Typography variant="body2" noWrap style={{ paddingTop: 10, fontWeight: "normal", color: "black" }}>
                  {"Gross Profit"}
                </Typography>}

                <Divider light style={{ width: "100%", marginTop: 20 }} />
                <Typography variant="body2" noWrap style={{ paddingTop: 20, fontWeight: "normal", color: "black" }}>
                  {"Cash"}
                </Typography>
                <Typography variant="body2" noWrap style={{ paddingTop: 10, fontWeight: "normal", color: "black" }}>
                  {"Debit Card"}
                </Typography>
                <Typography variant="body2" noWrap style={{ paddingTop: 10, fontWeight: "normal", color: "black" }}>
                  {"Credit Card"}
                </Typography>
                <Typography variant="body2" noWrap style={{ paddingTop: 10, fontWeight: "normal", color: "black" }}>
                  {"E-Wallet"}
                </Typography>
                <Typography variant="body2" noWrap style={{ paddingTop: 10, fontWeight: "normal", color: "black" }}>
                  {"Online Transfer"}
                </Typography>
                <Typography variant="body2" noWrap style={{ paddingTop: 10, fontWeight: "normal", color: "black" }}>
                  {"Gift Card"}
                </Typography>
              </Grid> 
              <Grid item xs={4} style={{  display: "flex", alignItems: "flex-end", flexDirection: "column" }}>
                <Typography noWrap variant="body2"   style={{ paddingTop: 10, fontWeight: "normal", color: "black" }}>
                  {renderAmount("checkout_sub_total")}
                </Typography>
                <Typography noWrap variant="body2"   style={{ paddingTop: 10, fontWeight: "normal", color: "black" }}>
                  {renderAmount("checkout_discount")}
                </Typography>
                <Typography noWrap variant="body2"   style={{ paddingTop: 10, fontWeight: "normal", color: "black" }}>
                  {renderAmount("total_credit_note")}
                </Typography>
                <Typography noWrap variant="body2"   style={{ paddingTop: 10, fontWeight: "normal", color: "black" }}>
                  {"RM " + (ordersDetails.net_turnover ? ordersDetails.net_turnover.toFixed(2): "0.00")}
                </Typography>
                {props.currentStaff && props.currentStaff.role === "cashier" ? null : <Typography noWrap variant="body2"   style={{ paddingTop: 10, fontWeight: "normal", color: "black" }}>
                  {renderAmount("total_cost_of_goods_sold")}
                </Typography>}
                {props.currentStaff && props.currentStaff.role === "cashier" ? null : <Typography noWrap variant="body2"   style={{ paddingTop: 10, fontWeight: "normal", color: "black" }}>
                  {renderAmount("total_credit_note_cost")}
                </Typography>}
                {props.currentStaff && props.currentStaff.role === "cashier" ? null : <Typography noWrap variant="body2"   style={{ paddingTop: 10, fontWeight: "normal", color: "black" }}>
                  {"RM " + (ordersDetails.gross_profit ? ordersDetails.gross_profit.toFixed(2): "0.00")}
                </Typography>}

                <Divider light style={{ width: "100%", marginTop: 20 }} />
                <Typography noWrap variant="body2"   style={{ paddingTop: 20, fontWeight: "normal", color: "black" }}>
                  {"RM " + (ordersDetails.total_cash ? ordersDetails.total_cash.toFixed(2): "0.00")}
                </Typography>
                <Typography noWrap variant="body2"   style={{ paddingTop: 10, fontWeight: "normal", color: "black" }}>
                  {"RM " + (ordersDetails.total_debit_card ? ordersDetails.total_debit_card.toFixed(2): "0.00")}
                </Typography>
                <Typography noWrap variant="body2"   style={{ paddingTop: 10, fontWeight: "normal", color: "black" }}>
                  {"RM " + (ordersDetails.total_credit_card ? ordersDetails.total_credit_card.toFixed(2): "0.00")}
                </Typography>
                <Typography noWrap variant="body2"   style={{ paddingTop: 10, fontWeight: "normal", color: "black" }}>
                  {"RM " + (ordersDetails.total_ewallet ? ordersDetails.total_ewallet.toFixed(2): "0.00")}
                </Typography>
                <Typography noWrap variant="body2"   style={{ paddingTop: 10, fontWeight: "normal", color: "black" }}>
                  {"RM " + (ordersDetails.total_online_transfer ? ordersDetails.total_online_transfer.toFixed(2): "0.00")}
                </Typography>
                <Typography noWrap variant="body2"   style={{ paddingTop: 10, fontWeight: "normal", color: "black" }}>
                  {"RM " + (ordersDetails.total_gift ? ordersDetails.total_gift.toFixed(2): "0.00")}
                </Typography>
              </Grid>

            </Grid>

          </Grid>
          
        </Grid>

        <Grid style={{ padding: 15, display: "flex", flexDirection: "column" }}>
          <Grid style={{ display: "flex", justifyContent: "flex-start", flexDirection: "column" }} >
            <Typography noWrap variant="body2"   style={{ paddingTop: 10, fontWeight: "normal", color: "black" }}>
              {"Sales Product List"}
            </Typography>
          </Grid>
        </Grid>

        <Divider light style={{ width: "100%"}} />

        <Grid style={{ padding: 15, paddingTop: 5, display: "flex", flexDirection: "column" }}>
          <Grid style={{ display: "flex", justifyContent: "flex-start", flexDirection: "column" }} >
            {allproducts.map(item=>{
              return <Typography noWrap variant="body2" style={{ paddingTop: 10, fontWeight: "normal", color: "black" }}>
              {item.name + " x " + item.quantity}
            </Typography>
            })}
          </Grid>
          <Typography noWrap variant="body2"   style={{ paddingTop: 10, fontWeight: "normal", color: "black" }}>
            {"Total Quantity Sold: " + totalQuantity}
          </Typography>
        </Grid>

        <Divider light style={{ width: "100%"}} />
            
            <Grid>
            <Typography variant="caption" style={{ paddingTop: 10, fontSize: 14, fontWeight: "bold", color: "black", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    {"- sp2smalaysia.com -"}
            </Typography>
            </Grid>
           
      </Grid>
    </Paper>
    </ThemeProvider>
  );
  
}

  
const mapStateToProps = createStructuredSelector({
  currentStaff: selectCurrentStaff,
})

export default connect(
  mapStateToProps
)(Index);
