import React, { useEffect } from 'react';
import Table from '../../modules/components/Table'
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import firebase from "firebase";

export default function Index(props) {

    const [headCells, setHeadCells] = React.useState([]);
    const [headMobileCells, setHeadMobileCells] = React.useState([]);
    const [searchValue, setSearchValue] = React.useState("");
    const [searchCell, setSearchCell] = React.useState([]);
    const [displayInfo, setDisplayInfo] = React.useState([]);
    const [editCells, setEditCells] = React.useState([]);
    const [addCells, setAddCells] = React.useState([]);
    const [addCellsName, setAddCellsName] = React.useState([]);
    const matches = useMediaQuery(theme => theme.breakpoints.up('sm'));

    useEffect(() => {

        setHeadCells([
            { id: 'checkout_created', label: 'Date' },
            { id: 'total_invoice', label: 'No. INV' },
            { id: 'checkout_amount', label: 'Total Sales' },
            { id: 'checkout_discount', label: 'Total Discount' },
            { id: 'total_credit_note', label: 'Total Credit Note' },
            { id: 'net_turnover', label: 'Net TurnOver' },
            { id: 'total_cost_of_goods_sold', label: 'Total Cost of Goods Sold' },
            { id: 'total_credit_note_cost', label: 'Total Credit Note Cost' },
            { id: 'gross_profit', label: 'Gross Profit' },
        ])

        setHeadMobileCells([
            { id: 'checkout_amount', label: 'Total' },
        ])
    
        setSearchCell([
            { id: 'checkout_created', label: 'Date' },
        ])

        setDisplayInfo([
            { id: 'checkout_created', label: 'Created Date' },
            { id: 'total_invoice', label: 'No. INV' },
            { id: 'checkout_amount', label: 'Total Sales' },
            { id: 'checkout_discount', label: 'Total Discount' },
            { id: 'total_credit_note', label: 'Total Credit Note' },
            { id: 'net_turnover', label: 'Net TurnOver' },
            { id: 'total_cost_of_goods_sold', label: 'Total Cost of Goods Sold' },
            { id: 'total_credit_note_cost', label: 'Total Credit Note Cost' },
            { id: 'gross_profit', label: 'Gross Profit' },
        ])

        setEditCells([
            { id: 'checkout_created', label: 'Created Date' },
            { id: 'checkout_amount', label: 'Total' },
        ])

        setAddCells([
            { id: 'checkout_created', label: 'Created Date' },
            { id: 'checkout_amount', label: 'Total' },
        ])

        setAddCellsName({ 
            checkout_created: "",
            total_amount: ""
         })
    
        setSearchValue("checkout_created")
        
    }, []);

    return (
    <Grid>
        <Table 
            user={props.user}
            control={false}
            orderBy="checkout_created" 
            data={"checkoutdaily"}
            addItemButton={false}
            addCells={addCells}
            addCellsName={addCellsName}
            displayInfo={displayInfo}
            editCells={editCells}
            matches={matches}
            headCells={matches ? headCells : headMobileCells} 
            searchValue={searchValue} 
            searchCell={searchCell}/>
    </Grid>
    )

}
  