import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import ListItem from '@material-ui/core/ListItem';
import Carousel from 'react-material-ui-carousel'

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { red } from '@material-ui/core/colors';

import Dialog from '../../../modules/components/Dialog';

import { connect } from 'react-redux';

import {
    addItem,
    removeItem,
    changeItemQuantity
} from '../../../redux/cart/cart.actions';

import { createStructuredSelector } from 'reselect';
import { selectCartItems } from '../../../redux/cart/cart.selectors';
import { selectProductItems } from '../../../redux/product/product.selectors';
import { selectCategoryItems } from '../../../redux/category/category.selectors';
import { selectCurrentUser } from '../../../redux/user/user.selector'

import Input from '@material-ui/core/Input';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  media: {
    height: 0,
    paddingTop: '100%', // 16:9
  },
  avatar: {
    backgroundColor: red[500],
  }
}));

const lightColor = 'rgba(255, 255, 255, 0.7)';
const maxPod = 20;
const maxDevice = 6;

function Index(props) {

    //Dialog
    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogLoading, setDialogLoading] = React.useState(false);
    const [fullScreenDialog, setFullScreenDialog] = React.useState(false);
    const [dialogTitle, setDialogTitle] = React.useState("");
    const [dialogDes, setDialogDes] = React.useState("");
    const [agreeButton, setAgreeButton] = React.useState(false);
    const [option, setOptions] = React.useState("");
    const [mediaOption, setMediaOption] = React.useState("products");
    const [dialogMaxWidth, setDialogMaxWidth] = React.useState(null);

    const classes = useStyles();
    const [loading, setLoading] = React.useState(true);
    const [currentCategory, setCurrentCategory] = React.useState(null);
    const [currentProduct, setCurrentProduct] = React.useState({});

    useEffect(() => {
      setLoading(false)
    }, [props]);

    const handleClose = () => {
      setOpenDialog(false)
    };

    const renderCategories = () => {
      let _categoryItems = [];

      var itemsCategoriesSet = new Set();

      renderItemsProducts().forEach(val =>{

        if(val.categories && val.categories.length > 0){
          val.categories.forEach(value => {
            itemsCategoriesSet.add(value.id);
          })
        }
        
      })

      let itemsCategories = Array.from(itemsCategoriesSet);

      itemsCategories.forEach(id =>{
        var currentIndex = props.categoryItems.map(val => val.id).indexOf(id);
        if(currentIndex !== -1){
          _categoryItems.push(props.categoryItems[currentIndex])
        }
      })

      return _categoryItems;
    }

    const renderItemsProducts = () => {
       
        let _productItems = [];

        props.productItems.forEach(val =>{
            if(val.status === "published" ){
              _productItems.push(val)
            }
        })

        return _productItems;
    }

    const renderStock = (product) => {
      var stockAvailable = true;

      if(product.in_stock && Number(product.in_stock) > 0){
        stockAvailable = false;
      }

      return stockAvailable;
    }

    const renderListProducts = () => {

      return(
        <Grid container style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "100%",padding: 10 }}>
            
            {renderItemsProducts().sort((a, b) => a.name.localeCompare(b.name)).map((val, index) => {
              var currentProductIndex = props.cartItems.map(val=> val.id).indexOf(val.id);

              var currentCategoriesIndex = 1;
              
              if(currentCategory){
                if(val.categories){
                  currentCategoriesIndex = val.categories.map(value => value.id).indexOf(currentCategory)
                }
                else
                {
                  currentCategoriesIndex = -1;
                }
              }
              
              return(
                <Grid style={{ minWidth: 180, maxWidth: 230, display: currentCategoriesIndex === -1 ? "none":"flex",  padding: 10 }} key={"product-card-"+index} item xs={6} sm={4} md={3} lg >
                  <Card className={classes.root}>
                      <CardActionArea disabled={renderStock(val)} style={{ opacity: renderStock(val) ? .5: 1}} onClick={()=> showProductDetails(val)}>
                        <CardMedia
                          component="div"
                          className={classes.media}
                          image={val.image && val.image[0] ? val.image[0].url : null}
                          title={val.name}
                        />
                        <CardContent>
                          <Grid style={{ display: "flex", flexDirection: "column" }}>
                            <Typography style={{ fontSize: 16, height: 20 }} variant="body2" color="textSecondary" component="p">
                              {props.selectedCustomer ? "RM " + Number(val.discount_price).toFixed(2) :  "RM " + Number(val.retail_price).toFixed(2) }
                            </Typography>
                          </Grid>
                          
                          <Typography style={{ opacity: .5, fontSize: 12, 
                          height: 40,
                          marginTop: 10,
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 2,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                          }} variant="body2" color="textSecondary" component="p">
                            {val.name}
                          </Typography>
                          <Grid style={{ display: "flex", flexDirection: "column" }}>
                            <Typography style={{ fontSize: 12, opacity: .8 }} variant="body2" color="textSecondary" component="p">
                              {val.in_stock && Number(val.in_stock) > 0 ? val.in_stock + " in stock" : "Out of stock"}
                            </Typography>
                          </Grid>
                        </CardContent>
                      </CardActionArea>
                      {renderStock(val) ? null : <CardActions style={{ display: "flex", justifyContent: "space-between", padding: 0}}>
                        <Grid container style={{ width: "100%" }}>
                       
                          {currentProductIndex !== -1 ? <Grid item xs={5}>
                            <Button onClick={()=>  removeFromCartHandle(val)} color="primary" style={{  width: "100%", height: 30, borderRadius: 0, opacity: .7 }} disableElevation variant="contained" size="small">
                              -
                            </Button>
                            </Grid>: null}

                            {currentProductIndex !== -1 ? 
                            <Grid item xs={2} style={{ backgroundColor: "#7faa14",  display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }} >
                              <Input 
                               inputProps={{min:1, style: { textAlign: 'center' }}}
                              style={{ fontSize: 14, width: 40, padding: 0, textAlign: "center", color: "black" }} 
                              onChange={(e)=> { 

                                var isNumber = /^\d+$/.test(e.target.value)

                                if(!isNumber){
                                  return props.changeItemQuantity(e.target.value.replace(/\D/g,''), props.cartItems[currentProductIndex], props.currentUser)
                                }
                                else
                                {
                                  return props.changeItemQuantity(e.target.value, props.cartItems[currentProductIndex], props.currentUser)
                                }

                                
                               }} value={props.cartItems[currentProductIndex].quantity >= val.in_stock ? val.in_stock : props.cartItems[currentProductIndex].quantity} />
                            </Grid>: null}

                            {currentProductIndex !== -1 ? 
                            <Grid item xs={5}>
                            <Button disabled={props.cartItems[currentProductIndex].quantity >= val.in_stock ? true :false} onClick={()=> addToCartHandle(val)} color="primary" style={{ width: "100%", height: 30, borderRadius: 0, opacity: .7 }} disableElevation variant="contained" size="small">
                              {"+"}
                            </Button>
                            </Grid>: null}

                            {currentProductIndex === -1 ?<Grid item xs={12}>
                            <Button onClick={()=> addToCartHandle(val)} style={{ width: "100%", height: 30, borderRadius: 0, opacity: .7 }} disableElevation variant="contained" size="small">
                              {"Add to Cart"}
                            </Button>
                            </Grid>: null}

                        </Grid>
                      </CardActions>}
                    
                  </Card>
                </Grid>
              )
            })}
            {renderItemsProducts().length === 0 ? 
            <Grid style={{ display: 'flex',  justifyContent:'center', alignItems:'center',padding: 70, width: "100%", flexDirection: "column" }} >
              <Typography variant="h4" color="textSecondary">
                No Products Found
              </Typography>
              <Typography variant="body2" style={{ opacity: .5 }} color="textSecondary">
                Your search did not match any products.
              </Typography>
            </Grid>
            
            : null}
          </Grid>
      )
    }

    const renderComponent = () => {

      if(mediaOption === "products"){
        var currentProductIndex = props.cartItems.map(val=> val.id ).indexOf(currentProduct.id);

        return(
          <Grid style={{ width: "100%", height: "100%" }}>
            <Grid container style={{ width: "100%"}}>
               <Grid item md={7} xs={12} style={{ display: "flex", justifyContent: "center", backgroundColor: "#333333", padding: 10 }}>

                {currentProduct.gallery ?
                <Carousel className={classes.root}>
                      {currentProduct.gallery.map((val, index) => <Card key={"gallery-" + (index+1)} className={classes.root}>
                        <CardMedia
                                  component="div"
                                  className={classes.media}
                                  image={val.url}
                                  title={val.name}
                                />
                      </Card> )}
                    
                </Carousel> : 
                <Carousel className={classes.root}>
                    <Card className={classes.root}>
                      <CardMedia
                                component="div"
                                className={classes.media}
                                image={currentProduct.image ? currentProduct.image[0].url : null}
                                title={currentProduct.name}
                              />
                    </Card>
              </Carousel>
                }

               </Grid>
               <Grid item md={5} xs={12} >
                 <Grid style={{ padding: 20 }}>
                   <Grid style={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
                     <Typography style={{ fontSize: 36 }} variant="body2" color="textSecondary" component="p">
                       {props.selectedCustomer ? "RM " + Number(currentProduct.discount_price).toFixed(2): "RM " + Number(currentProduct.retail_price).toFixed(2) }
                     </Typography>
                   </Grid>
                   
                   <Typography style={{ opacity: .7, fontSize: 16 }} variant="body2" color="textSecondary" component="p">
                     {currentProduct.name}
                   </Typography>
                   <Typography style={{ fontSize: 12, opacity: .8, marginTop: 10 }} variant="body2" color="textSecondary" component="p">
                      {currentProduct.in_stock && Number(currentProduct.in_stock) > 0 ? currentProduct.in_stock + " in stock" : "Out of stock"}
                    </Typography>
                   <Typography component="div" style={{ opacity: .5, fontSize: 12, marginTop: 10  }} variant="body2" color="textSecondary">
                            <pre style={{ fontFamily: 'inherit', display: 'inline' , whiteSpace: "pre-line"}}>
                            {currentProduct.description}
                            </pre>
                   </Typography>

                   

                   {currentProductIndex !== -1 ? <Grid container style={{ width: "100%", marginTop: 20, borderRadius: 5 }}>

                   <Grid item xs={3} >
                            <Button onClick={()=> removeFromCartHandle(currentProduct)} color="primary" style={{  width: "100%", height: 30, borderEndEndRadius: 0, borderStartEndRadius: 0, opacity: .7 }} disableElevation variant="contained" size="small">
                              -
                            </Button>
                            </Grid>

                            {currentProductIndex !== -1 ? 
                            <Grid item xs={6} style={{ backgroundColor: "#7faa14",  display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }} >
                              <Input 
                               inputProps={{min:1, style: { textAlign: 'center' }}}
                              style={{ fontSize: 14, width: 40, padding: 0, textAlign: "center", color: "black" }} 
                              onChange={(e)=> { 

                                var isNumber = /^\d+$/.test(e.target.value)
                                
                                if(!isNumber){
                                  return props.changeItemQuantity(e.target.value.replace(/\D/g,''), props.cartItems[currentProductIndex], props.currentUser)
                                }
                                else
                                {
                                  return props.changeItemQuantity(e.target.value, props.cartItems[currentProductIndex], props.currentUser)
                                }

                               }} value={props.cartItems[currentProductIndex].quantity >= currentProduct.in_stock ? currentProduct.in_stock : props.cartItems[currentProductIndex].quantity} />
                            </Grid>: null}

                            {currentProductIndex !== -1 ? 
                            <Grid item xs={3}>
                            <Button disabled={props.cartItems[currentProductIndex].quantity >= currentProduct.in_stock ? true :false}  onClick={()=> addToCartHandle(currentProduct)} color="primary" style={{ width: "100%", height: 30,borderEndStartRadius: 0, borderStartStartRadius: 0, opacity: .7 }} disableElevation variant="contained" size="small">
                              {"+"}
                            </Button>
                            </Grid>: null}

                            
                    </Grid>: null}

                    {currentProductIndex === -1 ?
                    <Grid container style={{ width: "100%", marginTop: 20 }}>
                      <Grid item xs={12}>
                            <Button onClick={()=> addToCartHandle(currentProduct)} style={{ width: "100%", borderRadius: 5, height: 30, opacity: .7 }} disableElevation variant="contained" size="small">
                            {"Add to Cart"}
                            </Button>
                            </Grid>
                            </Grid>: null}

                    {currentProduct.categories ? <Grid>
                      <Grid container style={{ marginTop : 20, display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Typography variant="body2" color="textSecondary" component="p">
                          {"Categories"}
                        </Typography>
                      </Grid>

                      <Grid container spacing={1} style={{ marginTop : 10, display: "flex", flexDirection: "row", alignItems: "center" }}>
                        {currentProduct.categories.map((val, index) => {

                          const currentIndex = renderCategories().map(val => val.id).indexOf(val.id);
                          return(
                            <Grid key={"category-button-" + index} item>
                              {renderCategories()[currentIndex] && renderCategories()[currentIndex].link ? 
                              <Button style={{ opacity:.7 }} onClick={()=> { 
                                setOpenDialog(false)
                                selectCategory(renderCategories()[currentIndex].id) } }
                              disableElevation variant="outlined" size="small">
                              {renderCategories()[currentIndex].name}
                              </Button> : null}
                            </Grid>
                          )
                        })}
                      </Grid>
                    </Grid>: null}
                 </Grid>
               </Grid>
            </Grid>
 
          </Grid>
        )

      }
       
    }
    const addToCartHandle = (product) => {
      props.addItem(product, props.currentUser)
    }

    const removeFromCartHandle = (product) => {
      props.removeItem(product, props.currentUser)
    }

    const showProductDetails = (val) => {
      setCurrentProduct(val)
      setMediaOption("products")
      setDialogLoading(false)
      setAgreeButton(false)
      setDialogTitle(val.name)
      setOptions("productDetails")
      setDialogDes("")
      setFullScreenDialog(false)
      setOpenDialog(true)
      setDialogMaxWidth("md")
    }

    const selectCategory = (val) => {
      setCurrentCategory(val)
    }

    if(loading){
      return (
      <Grid style={{ display: 'flex',  justifyContent:'center', alignItems:'center',padding: 70 }}>
        <CircularProgress 
          disableShrink
          color="primary" />
      </Grid>);
    }
    
    return (
     <Grid container style={{ width: "100%" }}>
        <Dialog 
          agreeTxt={"Yes"}
          disagreeTxt={"No"}
          description={dialogDes}
          title={dialogTitle}
          open={openDialog} 
          agreeButton={agreeButton}
          fullScreenDialog={fullScreenDialog}
          option={option}
          maxWidth={dialogMaxWidth}
          dialogLoading={dialogLoading}
          component={renderComponent()}
          handleClose={handleClose}/>

        <Grid item xs={12} sm={12} md={12} lg={12} >
          <Typography variant="h6" color="textSecondary" component="p" align="left" style={{ paddingLeft: 20, paddingTop: 10, fontSize: 18 }}>
            { "Categories"}
          </Typography> 
          <Grid style={{ display: "flex", flexDirection: 'column', width: "100%" }}>
                  <Typography variant="caption" style={{ fontSize: 14 }}/>
                  <Grid container style={{ paddingLeft: 20 }}>
                      <Grid item style={{ paddingRight: 10, paddingTop: 10 }}>
                        <Button onClick={()=> selectCategory(null)} variant="outlined" 
                          style={{ fontSize: 14, color: currentCategory === null ? "#99d700" : lightColor
                          }}>
                          {"All"}
                          </Button>
                      </Grid>
                      
                      {renderCategories().sort((a,b) => a.name.localeCompare(b.name)).map((val, index) => {

                          return(
                            <Grid item style={{ paddingRight: 10, paddingTop: 10}}>
                            <Button onClick={()=> selectCategory(val.id)}  key={"categories-list-"+index} variant="outlined" 
                            style={{ fontSize: 14, color: currentCategory === val.id ? "#99d700" : lightColor
                            }}>
                              {val.name}
                            </Button>
                            </Grid>
                          )
    
                      })}
                  </Grid>
                  
              </Grid>
         
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} >
          {renderListProducts()}
        </Grid>
     </Grid>
    );

}

const mapDispatchToProps = dispatch => ({
  addItem: (item, user) => dispatch(addItem(item, user)),
  removeItem: (item, user) => dispatch(removeItem(item, user)),
  changeItemQuantity: (quantity, item, user) => dispatch(changeItemQuantity(quantity, item, user)),
});

const mapStateToProps = createStructuredSelector({
  cartItems: selectCartItems,
  currentUser: selectCurrentUser,
  productItems: selectProductItems,
  categoryItems: selectCategoryItems,
})

const routeNavigator = withRouter(Index);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(routeNavigator);