import React, { useEffect } from 'react';
import Table from '../../../modules/components/Table'
import Dropzone from '../../../modules/components/DropzoneInvoice'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';

export default function Index(props) {

    const [headCells, setHeadCells] = React.useState([]);
    const [headMobileCells, setHeadMobileCells] = React.useState([]);
    const [searchValue, setSearchValue] = React.useState("");
    const [searchCell, setSearchCell] = React.useState([]);
    const [displayInfo, setDisplayInfo] = React.useState([]);
    const [editCells, setEditCells] = React.useState([]);
    const matches = useMediaQuery(theme => theme.breakpoints.up('sm'));

    useEffect(() => {

        setHeadCells([
            { id: 'name', label: 'Name' },
            { id: 'url', label: 'Thumbnail' },
            { id: 'size', label: 'Size' },
            { id: 'created', label: 'Created' },
            { id: 'type', label: 'Type' },
        ])

        setHeadMobileCells([
            { id: 'name', label: 'Name' },
            { id: 'size', label: 'Size' },
        ])
    
        setSearchCell([
            { id: 'id', label: 'Id' },
            { id: 'name', label: 'Name' },
        ])

        setDisplayInfo([
            { id: 'id', label: 'Id' },
            { id: 'name', label: 'Name' },
            { id: 'size', label: 'Size' },
            { id: 'created', label: 'Created' },
            { id: 'type', label: 'Type' },
        ])

        setEditCells([
            { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
        ])
    
        setSearchValue("name")
        
    }, []);

    return (
    <Grid>
        <Dropzone uploadTxt="Upload delivery order" data="delivery_orders" {...props}/>
        <Table 
            {...props}
            previewButton={true}
            storage={true}
            control={true}
            rowsPerPage={5}
            orderBy="created" 
            data="delivery_orders" 
            folder_data="delivery_orders_zone"
            displayInfo={displayInfo}
            matches={matches}
            editCells={editCells}
            headCells={matches ? headCells : headMobileCells} 
            searchValue={searchValue} 
            searchCell={searchCell}/>
    </Grid>
    )
    
}
  