import { createSelector } from 'reselect'

const selectCart = state => state.cart


export const selectCartItems = createSelector(
    [selectCart],
    cart => cart.cartItems
)


export const selectCartHidden = createSelector(
    [selectCart],
    (cart)=> cart.hidden
)

export const selectCartItemsCount = createSelector(
    [selectCartItems],
    cartItems =>
        (cartItems.reduce(
            (accumulatedQuantity, cartItem) =>
                accumulatedQuantity + cartItem.quantity,
            0
        )
        )
)

export const selectCartTotal = createSelector(
    [selectCartItems],
    cartItems =>
        (cartItems.reduce(
            (accumulatedQuantity, cartItem) =>
                accumulatedQuantity + cartItem.quantity * (cartItem.retail_price? cartItem.retail_price : 0),
            0
        )
        )
)

export const selectCartTotalCost = createSelector(
    [selectCartItems],
    cartItems =>
        (cartItems.reduce(
            (accumulatedQuantity, cartItem) =>
                accumulatedQuantity + cartItem.quantity * (cartItem.cost_price ? cartItem.cost_price : 0),
            0
        )
        )
)

export const selectCartTotalDiscount = createSelector(
    [selectCartItems],
    cartItems =>
        (cartItems.reduce(
            (accumulatedQuantity, cartItem) =>
                accumulatedQuantity + cartItem.quantity * cartItem.discount_price,
            0
        )
        )
)