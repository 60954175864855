import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import firebase from "firebase";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const styles = theme => ({
    root: {
      display:"flex",
      justifyContent: "center",
      padding: 20
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 3),
    },
    paper: {
      margin: theme.spacing(4, 4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }
});

function Index(props) {


    const classes = props.classes;

    const [customer, setCustomer] = React.useState("");
    const [selectedCustomer, setSelectedCustomer] = React.useState(null);
    const [errorText, setErrorText] = React.useState("");
    const [searchValue, setSearchValue] = React.useState("customer_id");
    const [checkBox, setCheckBox] = React.useState(false);

    const handleCheckChange = (event) => {
      setCheckBox(event.target.checked);
    };

    const handleCustomerSearch = (event) => {
      setCustomer(event.target.value)
    }

    //Search
    const onChangeSearchSelect = event => {
      setCustomer("")
      setErrorText("")
      setSelectedCustomer("")
      setCheckBox(false)
      setSearchValue(event.target.value)
    }

    const handleSubmit = () => {

      if(checkBox){
        props.handleSetRows(selectedCustomer)
      }
      else
      {
        setErrorText("")

        if(customer !== ""){
          let customerRef = firebase.database().ref("customer")
  
          customerRef.once('value', snapshot => {
            if(snapshot.exists()){

              var _customer = [];

              snapshot.forEach(child => {

                
                var itemsVal = child.val();
  
                if(searchValue === "customer_id"){
                  if(itemsVal.customer_id === customer){
                    _customer.push(itemsVal)
                  }
                }
                else if(searchValue === "phone"){
                  if(itemsVal.phone === customer){
                    _customer.push(itemsVal)
                  }
                }
                else{
                  if(itemsVal.email === customer){
                    _customer.push(itemsVal)
                  }
                }
              })
              
              if(_customer.length > 0){
                setSelectedCustomer(_customer)
                setErrorText("")
              }
              else{
                setSelectedCustomer(null)
                setErrorText("Customer Not Found")
              }
            }
            else
            {
              setErrorText("Customer Not Found")
            }
          })
  
        }
  
        else
        {
          setErrorText("Customer Not Found")
        }
  
      }
      
      
    }

    return (
        <Grid style={{ padding: 30 , maxWidth: 1000 }}>
            <FormControl style={{ width: "100%"}} >
                <InputLabel id="simple-select-outlined-label">Search by</InputLabel>
                <Select
                    labelId="simple-select-outlined-label"
                    id="simple-select-outlined"
                    value={searchValue}
                    label="Search by"
                    onChange={(e) => onChangeSearchSelect(e)}
                >
                  <MenuItem value={"customer_id"}>{"Customer ID"}</MenuItem>
                    <MenuItem value={"phone"}>{"Phone Number"}</MenuItem>
                    <MenuItem value={"email"}>{"Email"}</MenuItem>
                    
                </Select>
            </FormControl>
             <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  autoComplete='off'
                  fullWidth
                  id="searchcustomer"
                  name="searchcustomer"
                  size="small"
                  style={{ marginTop: 15}}
                  value={customer}
                  onChange={handleCustomerSearch}
              />
            {errorText ? <Typography style={{ ontSize: 12 }} variant="body2" color="textSecondary" component="p">
              {errorText}
            </Typography> : null }
            {selectedCustomer ? 
              <Grid>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkBox}
                      onChange={handleCheckChange}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label={selectedCustomer[0].last_name + " " + selectedCustomer[0].first_name}
                />
              </Grid>: null}
              {checkBox ? <Button
                  style={{textTransform: 'none', marginTop: 15}}
                  fullWidth
                  color="primary"
                  variant="contained"
                  disableElevation
                  onClick={handleSubmit}
              >
              Confirm
              </Button>
              :
              <Button
                  style={{textTransform: 'none', marginTop: 15}}
                  fullWidth
                  color="primary"
                  variant="contained"
                  disableElevation
                  onClick={handleSubmit}
              >
              Search
              </Button>}
        </Grid>
    )

}

Index.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Index);