import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import firebase from "firebase";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { array } from 'i/lib/util';

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);


const styles = theme => ({
    root: {
      display:"flex",
      justifyContent: "center",
      padding: 20
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 3),
    },
    paper: {
      margin: theme.spacing(4, 4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }
});

function Index(props) {


    const classes = props.classes;

    const [customer, setCustomer] = React.useState("");
    const [selectedCustomer, setSelectedCustomer] = React.useState(null);
    const [customerOrder, setCustomerOrder] = React.useState(null);
    const [errorText, setErrorText] = React.useState("");
    const [searchValue, setSearchValue] = React.useState("phone");
    const [checkBox, setCheckBox] = React.useState(false);
    const [expanded, setExpanded] = React.useState('panel0');

    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };

    const handleCheckChange = (event) => {
      setCheckBox(event.target.checked);
    };

    const handleCustomerSearch = (event) => {
      setCustomer(event.target.value.trim())
    }

    //Search
    const onChangeSearchSelect = event => {
      setCustomer("")
      setErrorText("")
      setSelectedCustomer("")
      setCheckBox(false)
      setSearchValue(event.target.value)
      setCustomerOrder(null)
    }

    const handleReset = () => {
      setCustomer("")
      setErrorText("")
      setSelectedCustomer("")
      setCheckBox(false)
      setSearchValue("phone")
      setCustomerOrder(null)
    }

    const handleSubmit = () => {

      setErrorText("")

        if(customer !== ""){
          let customerRef = firebase.database().ref("customer")
  
          customerRef.once('value', snapshot => {
            if(snapshot.exists()){

              var _customer = [];

              snapshot.forEach(child => {

                
                var itemsVal = child.val();
  
                if(searchValue === "customer_id"){
                  if(itemsVal.customer_id === customer){
                    _customer.push(itemsVal)
                  }
                }
                else if(searchValue === "phone"){
                  if(itemsVal.phone === customer){
                    _customer.push(itemsVal)
                  }
                }
                else{
                  if(itemsVal.email === customer){
                    _customer.push(itemsVal)
                  }
                }
              })
              
              if(_customer.length > 0){
                setSelectedCustomer(_customer)
                setErrorText("")

                let allOrders = []
                let _customerOrder = []

                let orderRef = firebase.database().ref("pos");
                
                orderRef.once('value', snapshot => {
                  if(snapshot.exists()){
                    
                    snapshot.forEach(child => {
                      var itemsVal = child.val();

                      if(itemsVal.orders){
                        var newArray = Object.values(itemsVal.orders);
                        allOrders.push(...newArray)
                      }
                      

                    })

                    allOrders.forEach(child => {
                      if(child.orders_customer && child.orders_customer[0].id === _customer[0].id){
                        _customerOrder.push(child);
                      }
                    })
                    
                    console.log(_customerOrder)
                    setCustomerOrder(_customerOrder)
                  }
                })

              }
              else{
                setSelectedCustomer(null)
                setErrorText("Customer Not Found")
              }

             
              

            }
            else
            {
              setErrorText("Customer Not Found")
            }
          })
  
        }
  
        else
        {
          setErrorText("Customer Not Found")
        }
      
      
    }

    console.log(selectedCustomer)

    return (
        <Grid style={{ padding: 30 , maxWidth: 1000 }}>
            <FormControl style={{ width: "100%"}} >
                <InputLabel id="simple-select-outlined-label">Search by</InputLabel>
                <Select
                    labelId="simple-select-outlined-label"
                    id="simple-select-outlined"
                    value={searchValue}
                    label="Search by"
                    onChange={(e) => onChangeSearchSelect(e)}
                >
                  <MenuItem value={"customer_id"}>{"Customer ID"}</MenuItem>
                    <MenuItem value={"phone"}>{"Phone Number"}</MenuItem>
                    <MenuItem value={"email"}>{"Email"}</MenuItem>
                    
                </Select>
            </FormControl>
             <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  autoComplete='off'
                  fullWidth
                  id="searchcustomer"
                  name="searchcustomer"
                  size="small"
                  style={{ marginTop: 15}}
                  value={customer}
                  onChange={handleCustomerSearch}
              />
            {errorText ? <Typography style={{ fontSize: 12 }} variant="body2" color="textSecondary" component="p">
              {errorText}
            </Typography> : null }
            
              <Button
                  style={{textTransform: 'none', marginTop: 15}}
                  fullWidth
                  color="primary"
                  variant="contained"
                  disableElevation
                  onClick={handleSubmit}
              >
              Search
              </Button>

              <Button
                  style={{textTransform: 'none', marginTop: 15}}
                  fullWidth
                  color="primary"
                  variant="contained"
                  disableElevation
                  onClick={handleReset}
              >
              Reset
              </Button>

              {selectedCustomer ? 
              <Grid container style={{ marginTop: 20 }}>
                  <Grid item xs={6}> 
                    <Typography variant="body2" style={{ opacity: .5 }} >First Name</Typography>
                  </Grid>
                  <Grid item xs={6}>
                  <Typography variant="body2" >{selectedCustomer[0].first_name}</Typography>
                  </Grid>

                  <Grid item xs={6}> 
                    <Typography variant="body2" style={{ opacity: .5 }} >Last Name</Typography>
                  </Grid>
                  <Grid item xs={6}>
                  <Typography variant="body2" >{selectedCustomer[0].last_name}</Typography>
                  </Grid>

                  <Grid item xs={6}> 
                    <Typography variant="body2" style={{ opacity: .5 }} >Phone</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" >{selectedCustomer[0].phone}</Typography>
                  </Grid>

                  <Grid item xs={6}> 
                    <Typography variant="body2" style={{ opacity: .5 }} >Email</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" >{selectedCustomer[0].email}</Typography>
                  </Grid>

                  <Grid item xs={6}> 
                    <Typography variant="body2" style={{ opacity: .5 }} >Point</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" >{(selectedCustomer[0].customer_point ? selectedCustomer[0].customer_point : 0) - (selectedCustomer[0].customer_use_point ? selectedCustomer[0].customer_use_point : 0)}</Typography>
                  </Grid>

                  <Grid item xs={6}> 
                    <Typography variant="body2" style={{ opacity: .5 }} >Used Point</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" >{selectedCustomer[0].customer_use_point ? selectedCustomer[0].customer_use_point : 0}</Typography>
                  </Grid>

              </Grid>: null}
              
              <Grid style={{ marginTop: 15 }}>
                <Typography variant="body2" style={{ fontWeight: "bold"}} >{"Orders"}</Typography>
              </Grid>

               {customerOrder ? <Grid>
                {customerOrder.map((val, index)=>{
                  return(
                    <Accordion style={{ marginTop: 15 }} square expanded={expanded === 'panel'+index} onChange={handleChange('panel'+index)}>
                      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <Typography variant="body2">{index +1 +". "  + val.user_uid + " - " + new Intl.DateTimeFormat('en-GB', {year: 'numeric',day: '2-digit',  
                        month: 'long' ,hour: 'numeric', 
                        minute: 'numeric', second: 'numeric', hour12: true }).format(val.created)}</Typography>
                      </AccordionSummary>

                      <AccordionDetails>
                        <Grid container style={{ marginTop: 15 }}>
                        <Grid item xs={12} style={{ opacity: .5 }}>
                        <Typography variant="body2" >{val.store_name}</Typography>
                        </Grid>
                          <Grid item xs={12} style={{ opacity: .5,  fontSize: 10, marginTop: 10 }}>
                            <Typography variant="body2" >Purchased Products</Typography>
                            </Grid>
                            <Grid item xs={12} style={{ opacity: .2, border: '1px solid white', marginBottom: 5, marginTop: 10 }}>
                              </Grid>
                            <Grid item xs={12} style={{ opacity: .5 }}>
                              {val.products.map((val2, index2)=>{
                                return(
                                  <Grid container style={{ marginTop: 5 }}>
                                      <Grid item xs={12} style={{ opacity: .5 }}>
                                        <Typography variant="body2" >{val2.name + " x " + val2.quantity}</Typography>
                                      </Grid>
                                  </Grid>
                                )
                              })}
                            </Grid>
                          </Grid>
                      </AccordionDetails>
                    </Accordion>
                  )
                })}
              </Grid> :null}
        </Grid>
    )

}

Index.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Index);