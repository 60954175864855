import React, { useEffect } from 'react';
import Table from '../../../modules/components/Table'
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddEditProducts from './AddEditProducts';
import firebase from "firebase";

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentStaff } from '../../../redux/staff/staff.selector'

function Index(props) {

    const [headCells, setHeadCells] = React.useState([]);
    const [headMobileCells, setHeadMobileCells] = React.useState([]);
    const [searchValue, setSearchValue] = React.useState("");
    const [searchCell, setSearchCell] = React.useState([]);
    const [displayInfo, setDisplayInfo] = React.useState([]);
    const [editCells, setEditCells] = React.useState([]);
    const [addCells, setAddCells] = React.useState([]);
    const [addCellsName, setAddCellsName] = React.useState([]);
    const matches = useMediaQuery(theme => theme.breakpoints.up('sm'));

    useEffect(() => {

        if(props.currentStaff && props.currentStaff.role === "cashier")
        {
            setHeadCells([
                { id: 'name', label: 'Name' },
                { id: 'image',  label: 'Feature Image' },
            ])
        }
        else
        {
            setHeadCells([
                { id: 'name', label: 'Name' },
                { id: 'image',  label: 'Feature Image' },
                { id: 'in_stock', label: 'Stock' },
                { id: 'cost_price',  label: 'Cost Price' },
                { id: 'retail_price', label: 'Retail Price' },
                { id: 'discount_price',  label: 'Sale Price' },
            ])
        }
       

        setHeadMobileCells([
            { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
        ])
    
        setSearchCell([
            { id: 'id', label: 'ID' },
            { id: 'name', label: 'Name' },
        ])

        if(props.currentStaff && props.currentStaff.role === "cashier")
        {
            setDisplayInfo([
                { id: 'id', label: 'ID' },
                { id: 'name', label: 'Name' },
                { id: 'created', label: 'Created' },
                { id: 'updated', label: 'Updated' },
            ])
        }
        else
        {
            setDisplayInfo([
                { id: 'id', label: 'ID' },
                { id: 'name', label: 'Name' },
                { id: 'created', label: 'Created' },
                { id: 'updated', label: 'Updated' },
                { id: 'type', label: 'Type' },
                { id: 'retail_price', label: 'Retail Price' },
                { id: 'discount_price',  label: 'Sale Price' },
                { id: 'cost_price',  label: 'Cost Price' },
                { id: 'in_stock', label: 'In Stock' },
                { id: 'status', label: 'Status' },
                { id: 'description', label: 'Description' }
            ])
        }
        

        setEditCells([
            { id: 'image',  label: 'Image' },
            { id: 'name', label: 'Name' },
            { id: 'retail_price', label: 'Retail Price' },
            { id: 'discount_price',  label: 'Sale Price' },
            { id: 'cost_price',  label: 'Cost Price' },
            { id: 'type', label: 'Type' },
            { id: 'status', label: 'Status' },
            { id: 'categories', label: 'Catogories' },
            { id: 'description', label: 'Description' }
        ])

        setAddCells([
            { id: 'image',  label: 'Image' },
            { id: 'name', label: 'Name' },
            { id: 'retail_price', label: 'Retail Price' },
            { id: 'discount_price',  label: 'Sale Price' },
            { id: 'cost_price',  label: 'Cost Price' },
            { id: 'type', label: 'Type' },
            { id: 'status', label: 'Status' },
            { id: 'categories', label: 'Catogories' },
            { id: 'description', label: 'Description' }
        ])

        setAddCellsName({ 
            name: '', 
            created: firebase.database.ServerValue.TIMESTAMP,
            updated: firebase.database.ServerValue.TIMESTAMP,
            price: '',
            sale_price: '',
            sale_price_2: '',
            quantity:'',
            in_stock:'0',
            status: 'published', 
            categories: [],
            unit: '', 
            description: '',
            image: [],
            gallery: [],
            type: ''
         })
    
        setSearchValue("name")
        
    }, []);

    return (
    <Grid>
        <Table 
            {...props}
            customizeDialog={AddEditProducts}
            control={true}
            orderBy="created" 
            hideMove={true}
            hideAllMove={true}
            data={"pos/" + firebase.auth().currentUser.displayName + "/products"}
            addItemButton={props.currentStaff && props.currentStaff.role === "cashier" ?false: true}
            editButton={props.currentStaff && props.currentStaff.role === "cashier" ?false: true}
            pullProductButton={props.currentStaff && props.currentStaff.role === "cashier" ?false: true}
            addItemText="Add product"
            addItemIcon={<AddCircleIcon/>}
            addCells={addCells}
            addCellsName={addCellsName}
            folder_data={"pos/" + firebase.auth().currentUser.displayName + "/products_zone"}
            displayInfo={displayInfo}
            editCells={editCells}
            matches={matches}
            headCells={matches ? headCells : headMobileCells} 
            searchValue={searchValue} 
            searchCell={searchCell}/>
    </Grid>
    )

}
 
const mapStateToProps = createStructuredSelector({
    currentStaff: selectCurrentStaff,
})

export default connect(
    mapStateToProps
)(Index);