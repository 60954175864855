import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Dialog from '../../../modules/components/Dialog';
import Media from '../Media';
import Typography from '@material-ui/core/Typography';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import firebase from "firebase";

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #6e6e6e',
    width: 70,
    height: 70,
    padding: 4,
    boxSizing: 'border-box',
    marginLeft: 8,
    marginRight: 8,
    marginBottom: 8 
};

 
const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};

export default function Index(props) {

    //Dialog
    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogLoading, setDialogLoading] = React.useState(false);
    const [fullScreenDialog, setFullScreenDialog] = React.useState(false);
    const [dialogTitle, setDialogTitle] = React.useState("");
    const [dialogDes, setDialogDes] = React.useState("");
    const [agreeButton, setAgreeButton] = React.useState(false);
    const [option, setOptions] = React.useState("");

    const [name, setName] = React.useState("");
    const [type, setType] = React.useState("");
    const [selected, setSelected] = React.useState([]);
    const [description, setDescription] = React.useState("");
    const [addItem, setAddItem] = React.useState([]);

    useEffect(() => {
        setAddItem(props.addCellsName ? props.addCellsName : [])
        
        if(props.option === "edit"){
            setName(props.selectedItem.name ? props.selectedItem.name : "")
            setDescription(props.selectedItem.description ? props.selectedItem.description : "")
            setSelected(props.selectedItem.image ? props.selectedItem.image : [])
            setType(props.selectedItem.type ? props.selectedItem.type : [])
        }

    }, [props]);

    const handleClose = () => {
        setOpenDialog(false)
    };

    const handleAgree = () => {

        var updates = {};

        if(props.option === "edit"){
            props.selectedItem.name = name;
            props.selectedItem.description = description;
            props.selectedItem.image = selected;
            props.selectedItem.type = type;

            updates[props.data + '/' + props.selectedItem.id] = props.selectedItem;
    
            firebase.database().ref().update(updates);
        }
        else{
            var newPostKey = firebase.database().ref().child(props.data).push().key;

            addItem['created'] = firebase.database.ServerValue.TIMESTAMP;
            addItem['id'] = newPostKey;
            addItem['user'] = firebase.auth().currentUser.email;
            addItem['name'] = name;
            addItem['description'] = description;
            addItem['type'] = type;
            addItem['image'] = selected;
            addItem['link'] = name.toLowerCase().replace(" ", "-");
    
            
            updates[props.data + '/' + newPostKey] = addItem;
    
            firebase.database().ref().update(updates);
        }
        

        props.handleClose()
    }

    const handleSetRows = (row) => {
        setSelected(row)
        setOpenDialog(false)
    }

    const renderComponent = () => {
        return(<Media mediaOption={"image"} handleSetRows={(rows) => handleSetRows(rows)}/>)
    }

    const onChangeName = (e) => {
        setName(e.target.value)
    }

    const onChangeType = (e) => {
        setType(e.target.value)
    }

    const onChangeDescription = (e) => {
        setDescription(e.target.value)
    }

    const addContent = () => {
        setDialogLoading(false)
        setAgreeButton(false)
        setDialogTitle("Add Image")
        setOptions("addItem")
        setDialogDes("")
        setFullScreenDialog(true)
        setOpenDialog(true)
    }

    const onClickClear = () => {
        setSelected([])
    }

    const thumbs = selected.map((file, index) => {

        if(file.type === "video/mp4"){
            
            return(
                <Grid key={file.name+index} style={{ display:"flex", flexDirection: "column"}}>
                    <Grid style={{overflow: "hidden", textOverflow: "ellipsis", width: 70, padding: 8}}>
                        <Typography style={{ color : "#6e6e6e"}} variant="caption" noWrap={true}>{file.name}</Typography>
                    </Grid>
                    
                    <Grid style={thumb} key={file.name}>
                        <Grid style={{ display: "flex", justifyContent:'center', alignItems: 'center', width: "100%"}}>
                            <VideoLibraryIcon color="primary" style={{ fontSize: 36}}/>
                        </Grid>
                    </Grid>
                </Grid>
               
            )
        }
        else{
            return(
                <Grid key={file.name+index} style={{ display:"flex", flexDirection: "column"}}>
                    <Grid style={{overflow: "hidden", textOverflow: "ellipsis", width: 70, padding: 8}}>
                        <Typography style={{ color : "#6e6e6e"}} variant="caption" noWrap={true}>{file.name}</Typography>
                    </Grid>
                    
                    <Grid style={thumb} key={file.name}>
                        <Grid style={{ display: "flex", justifyContent:'center', alignItems: 'center', width: "100%", overflow: 'hidden'}}>
                            <img
                            src={file.url}
                            style={img}
                            alt={file.name}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )
        }
    });

    return (
        <Grid style={{ maxWidth: 625  }}>
            <Dialog 
                agreeTxt={"Yes"}
                disagreeTxt={"No"}
                description={dialogDes}
                title={dialogTitle}
                open={openDialog} 
                agreeButton={agreeButton}
                fullScreenDialog={fullScreenDialog}
                option={option}
                dialogLoading={dialogLoading}
                component={renderComponent()}
                handleClose={handleClose}/>

            <Grid container style={{ paddingBottom: 10, display: "flex", flexDirection: "row" }}>
                {props[props.option === "edit" ? "editCells" : "addCells"] ? props[props.option === "edit" ? "editCells" : "addCells"].map((cell, index)=>{ 
                const labelCellId = `add-cell-${index}`;
                var sizeMd = 12;

                if(index === props.addCells.length - 1){
                    if((index+1) % 2 !== 0){
                    sizeMd = 12;
                    } 
                }

                if(cell.id === "image"){
                    return(<Grid key={labelCellId} xs={12} md={12} style={{flexDirection: "column", padding: 10, display: "flex", justifyContent: "flex-start", alignItems: "center"}} item >
                    <Grid container style={{ width: "100%" , height: "100%"}}>
                        
                        <Grid item md={9}>
                        <Button onClick={addContent} color="primary" style={{ width: "100%" , height: "100%"}} 
                        startIcon={<AddCircleIcon/>} 
                        disableElevation  variant="contained">{"Featured Image"}</Button>
                        </Grid>

                        <Grid item md={3} style={{ paddingLeft: 10 }} >
                        <Button style={{ width: "100%" , height: "100%" }} disabled={selected.length > 0 ? false : true } onClick={onClickClear} variant="outlined" color="primary">
                            Clear
                        </Button>
                        </Grid>
                    
                    </Grid>

                        <Grid>
                        <aside style={thumbsContainer}>
                            {thumbs}
                        </aside>
                        </Grid>
                        
                    </Grid>)
                }


                if(cell.id === "description"){
                    return (<Grid key={labelCellId} xs={12} md={sizeMd} style={{  padding: 10, display: "flex", justifyContent: "center", alignItems: "center"}} item >
                            <TextField
                                key={"textfield-input-"+index}
                                label={cell.label}
                                style={{ width: "100%"}}
                                multiline
                                rows={4}
                                value={description}
                                onChange={onChangeDescription}
                                variant="outlined"/></Grid>
                        )
                }

                if(cell.id === "type"){
                    return (<Grid key={labelCellId} xs={12} md={sizeMd} style={{  padding: 10, display: "flex", justifyContent: "center", alignItems: "center"}} item >
                    <TextField
                        key={"textfield-input-"+index}
                        label={cell.label}
                        style={{ width: "100%"}}
                        value={type}
                        onChange={onChangeType}
                        variant="outlined"/></Grid>
                    )
                }
                
                return(   
                    <Grid key={labelCellId} xs={12} md={sizeMd} style={{  padding: 10, display: "flex", justifyContent: "center", alignItems: "center"}} item >
                    <TextField
                        key={"textfield-input-"+index}
                        label={cell.label}
                        style={{ width: "100%"}}
                        value={name}
                        onChange={onChangeName}
                        variant="outlined"/></Grid>
                    )}):null }
            </Grid>
            
            <Grid style={{ padding: 10, display: 'flex', justifyContent: "flex-end" }}>
                <Button variant="outlined" style={{ fontSize: 14, fontWeight: 'bold'}} onClick={props.handleClose} color="primary">
                {"No"}
                </Button>
                <Button disableElevation variant="contained" style={{ marginLeft: 10, fontSize: 14, fontWeight: 'bold'}} onClick={handleAgree} color="primary" autoFocus>
                {"Yes"}
                </Button>
            </Grid>
        </Grid>
    )

}
  