import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ReactPlayer from 'react-player'
import Carousel from 'react-material-ui-carousel'
import Link from '@material-ui/core/Link';

import Contents from '../../views/pages/Content/Contents'
import Products from '../../views/pages/Products/Products';
import Orders from '../../views/pages/Orders/Orders';
import Users from '../../views/pages/Users/Users';
import Salesman from '../../views/pages/Salesman/Salesman';
import Invoices from '../../views/pages/Invoices/Invoices';
import Customers from '../../views/pages/Customer/Customer';

import CheckOutDetails from './CheckOutDetails';

import firebase from "firebase";
import moment from "moment";
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import QrReader from 'react-qr-reader'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import SearchCustomer from './SearchCustomer';

const bytesToSize = (bytes) => {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
};

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    display:"flex",
    width:"100%",
    justifyContent: "space-between",
  },
  closeButton: {
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, data, ...other } = props;
  return (
    <React.Fragment>
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography style={{ color: "black", textAlign: "center", marginTop: 5}} variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon  style={{ color: "black  "}}/>
          </IconButton>
        ) : null}
      </MuiDialogTitle>
      <Divider style={{ opacity: .2 }}/>
    </React.Fragment>
  );
});

export default function AlertDialog(props) {

  const renderPage = () => {
    if(props.data === "pos/" + firebase.auth().currentUser.displayName + "/content_zone"){
      return <Grid><Contents mediaOption={props.mediaOption} handleSetRows={props.handleSetRows} folder={props.selectedItem ? props.selectedItem.id : null} /></Grid>
    }
    else if(props.data === "pos/" + firebase.auth().currentUser.displayName + "/products_zone"){
      return <Grid><Products mediaOption={props.mediaOption} handleSetRows={props.handleSetRows} folder={props.selectedItem ? props.selectedItem.id : null} /></Grid>
    }
    else if(props.data === "pos/" + firebase.auth().currentUser.displayName + "/orders_zone"){
      return <Grid><Orders mediaOption={props.mediaOption} handleSetRows={props.handleSetRows} folder={props.selectedItem ? props.selectedItem.id : null} /></Grid>
    }
    else if(props.data === "pos/" + firebase.auth().currentUser.displayName + "/users_zone"){
      return <Grid><Users mediaOption={props.mediaOption} handleSetRows={props.handleSetRows} folder={props.selectedItem ? props.selectedItem.id : null} /></Grid>
    }
    else if(props.data === "pos/" + firebase.auth().currentUser.displayName + "/salesman_zone"){
      return <Grid><Salesman mediaOption={props.mediaOption} handleSetRows={props.handleSetRows} folder={props.selectedItem ? props.selectedItem.id : null} /></Grid>
    }
    else if(props.data === "pos/" + firebase.auth().currentUser.displayName + "/invoices_zone"){
      return <Grid><Invoices mediaOption={props.mediaOption} handleSetRows={props.handleSetRows} folder={props.selectedItem ? props.selectedItem.id : null} /></Grid>
    }
    else if(props.data === "pos/" + firebase.auth().currentUser.displayName + "/customer_zone"){
      return <Grid><Customers mediaOption={props.mediaOption} handleSetRows={props.handleSetRows} folder={props.selectedItem ? props.selectedItem.id : null} /></Grid>
    }
  }

  const renderPaymentMethod = (payment_method) => {

    let paymentMethod = "Cash";

    if(payment_method === "debit_card"){
      paymentMethod = "Debit Card"
    }
    else if(payment_method === "credit_card"){
      paymentMethod = "Credit Card"
    }
    else if(payment_method === "ewallet"){
      paymentMethod = "E-Wallet"
    }
    else if(payment_method === "check"){
      paymentMethod = "Cheque"
    }
    else if(payment_method === "gift"){
      paymentMethod = "Gift Card"
    }
    else if(payment_method === "online_transfer"){
      paymentMethod = "Online Transfer"
    }

    return paymentMethod
  }

  
  
  const [searchItem, setSearchItem] = React.useState("");
  const [warrantyInfo, setWarrantyInfo] = React.useState(null);
  const [notFoundID, setNotFoundID] = React.useState(false);
  const [isCamera, setIsCamera] = React.useState(false);
  const [selectedCamera, setSelectedCamera] = React.useState("environment");

  const [claimWarrantyPage, setClaimWarrantyPage] = React.useState(false);

  const handleChangeCamera = (e) => {
    setSelectedCamera(e.target.value)
  }

  useEffect(() => {

    if(props.option === "searchWarranty"){
      setWarrantyInfo(null)
      setSearchItem("")
      setNotFoundID(false)
      setIsCamera(false)
      setClaimWarrantyPage(false)
    }
    
    
  }, [props]);

  const handleSearchWarranty = () => {

    firebase.database().ref("warranty").once('value', snapshot => {
      var warrantyRegistered = null;
                
      snapshot.forEach(child => {
          var itemsVal = child.val();
          if(itemsVal.id === searchItem.replace(/\D/g,'')){
            warrantyRegistered = itemsVal;
          }

      })
      
      if(warrantyRegistered){
        setNotFoundID(false)
        setWarrantyInfo(warrantyRegistered)
      }
      else
      {
        setNotFoundID(true)
      }

    })

  }


  const handleScan = (data) => {
    if(data){
        if(data.startsWith("http://sbr.fangcuanhuoxitong.com/Home/Index/"))
        {
          const resultArray = data.split("/");
          setSearchItem(resultArray[5]);
          
          setIsCamera(false)
        }
    }
  }

  const handleError = (err) =>{
      console.error(err)
  }

  const handleAgreeWarranty = () => {
    firebase.database().ref("warranty/"+ warrantyInfo.id).update({ claimed: true, claimed_date: firebase.database.ServerValue.TIMESTAMP });
    setClaimWarrantyPage(false)
  }

  const renderCamera = () => {
    return(
      <Grid style={{ width: "100%", heigth: 300, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
          <Grid style={{ display: "flex", justifyContent: "space-between", marginTop: 20 }}>
            <Button
                  style={{textTransform: 'none', marginRight: 20}}
                  color="primary"
                  variant="contained"
                  disableElevation
                  onClick={()=> setIsCamera(false)}
              >
              Back
              </Button>
           <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedCamera}
                      onChange={handleChangeCamera}
                  >
                  <MenuItem value={"environment"}>Back Camera</MenuItem>
                  <MenuItem value={"user"}>Front Camera</MenuItem>
                  </Select>
          </Grid>
          
          <Grid style={{ width: "100%" }}>
          <QrReader
              delay={50}
              onError={handleError}
              onScan={handleScan}
          />
          </Grid>
      </Grid>
    )
  }

  if(props.dialogFullLoading){
    return (
    <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center', marginTop: 70  }}>
      <CircularProgress 
        disableShrink
        color="primary" />
    </div>);
  }

  return (
    <div >
      <Dialog
        fullScreen={props.fullScreenDialog}
        open={props.open}
        fullWidth={true}
        maxWidth={props.maxWidth ? props.maxWidth : "xs"}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            backgroundColor: '#242424',
            boxShadow: 'none',

          },
        }}
      >

        <DialogTitle style={{ minWidth: 200 }} id="customized-dialog-title" onClose={props.handleClose} data={props.data}>
          {props.title}
        </DialogTitle>

        {props.description !== "" ?
          <Typography style={{ padding: 18 }} id="alert-dialog-description">
            {props.description}
          </Typography>
        :null}
        {props.option=== "info" ? 
        <Table>
            <TableBody>
              {props.displayInfo.map((cell, index)=>{
                const labelCellId = `enhanced-table-cell-${index}`;
                
                if(cell.id === "created" || cell.id === "updated"){
                  return(
                    <TableRow key={labelCellId} >
                      <TableCell component="th" scope="row">
                        {cell.label}
                      </TableCell>
                      {props.selectedItem[cell.id] ? <TableCell component="th" scope="row">
                      {new Intl.DateTimeFormat('en-GB', {year: 'numeric',day: '2-digit',  month: 'long' ,hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true })
                      .format(props.selectedItem[cell.id])}
                      </TableCell> : <TableCell component="th" scope="row"></TableCell>}
                    </TableRow>)
                }
                else if(cell.id === "expired_date"){
                    return(
                    <TableRow key={labelCellId} >
                      <TableCell component="th" scope="row">
                        {cell.label}
                      </TableCell>
                      {props.selectedItem["created"] ? <TableCell component="th" scope="row">
                      {moment(props.selectedItem["created"]).add(365, "days").format("DD-MM-YYYY")}
                      </TableCell> : <TableCell component="th" scope="row"></TableCell>}
                    </TableRow>)
                }
                else if(cell.id === "size"){
                  return(
                    <TableRow key={labelCellId} >
                      <TableCell component="th" scope="row">
                        {cell.label}
                      </TableCell>
                      <TableCell key={labelCellId}  component="th" scope="row">
                      {bytesToSize(props.selectedItem[cell.id])}
                      </TableCell>
                    </TableRow>
                    )
                }

                else if(cell.id === "shipping_address"){
                  return(
                    <TableRow key={labelCellId} >
                      <TableCell component="th" scope="row">
                        {cell.label}
                      </TableCell>
                      <TableCell key={labelCellId}  component="th" scope="row">
                        <Typography style={{ wordWrap: "break-word" }} variant="body2">
                          {props.selectedItem[cell.id] ? props.selectedItem[cell.id].street_address + ", " + 
                  props.selectedItem[cell.id].city + ", " + 
                  props.selectedItem[cell.id].zip + " " + 
                  props.selectedItem[cell.id].state + ", " + 
                  props.selectedItem[cell.id].country : ""}</Typography>
                      </TableCell>
                    </TableRow>
                    )
                }

                else if(cell.id === "billing_address"){
                  return(
                    <TableRow key={labelCellId} >
                      <TableCell component="th" scope="row">
                        {cell.label}
                      </TableCell>
                      <TableCell key={labelCellId}  component="th" scope="row">
                        <Typography style={{ wordWrap: "break-word" }} variant="body2">
                          {props.selectedItem[cell.id] ? props.selectedItem[cell.id].street_address + ", " + 
                  props.selectedItem[cell.id].city + ", " + 
                  props.selectedItem[cell.id].zip + " " + 
                  props.selectedItem[cell.id].state + ", " + 
                  props.selectedItem[cell.id].country : ""}</Typography>
                      </TableCell>
                    </TableRow>
                    )
                }

                else if(cell.id === "amount"){
                  return(
                    <TableRow key={labelCellId} >
                      <TableCell component="th" scope="row">
                        {cell.label}
                      </TableCell>
                      <TableCell key={labelCellId}  component="th" scope="row">
                        <Typography style={{ wordWrap: "break-word" }} variant="body2">{"RM " + props.selectedItem[cell.id]}</Typography>
                      </TableCell>
                    </TableRow>
                    )
                }

                else if(cell.id === "user_id"){
                  return(
                    <TableRow key={labelCellId} >
                      <TableCell component="th" scope="row">
                        {cell.label}
                      </TableCell>
                      <TableCell key={labelCellId}  component="th" scope="row">
                        <Typography style={{ wordWrap: "break-word" }} variant="body2">{props.selectedItem["user"].id}</Typography>
                      </TableCell>
                    </TableRow>
                    )
                }

                else if(cell.id === "user_name"){
                  return(
                    <TableRow key={labelCellId} >
                      <TableCell component="th" scope="row">
                        {cell.label}
                      </TableCell>
                      <TableCell key={labelCellId}  component="th" scope="row">
                        <Typography style={{ wordWrap: "break-word" }} variant="body2">{props.selectedItem["user"].last_name + " " + props.selectedItem["user"].first_name}</Typography>
                      </TableCell>
                    </TableRow>
                    )
                }

                else if(cell.id === "orders_customer"){
                  return(
                    <TableRow key={labelCellId} >
                      <TableCell component="th" scope="row">
                        {cell.label}
                      </TableCell>
                      <TableCell key={labelCellId}  component="th" scope="row">
                        <Typography style={{ wordWrap: "break-word" }} variant="body2">{props.selectedItem[cell.id] ? props.selectedItem[cell.id][0].last_name + " " + props.selectedItem[cell.id][0].first_name : "Walk In"}</Typography>
                      </TableCell>
                    </TableRow>
                    )
                }

                else if(cell.id === "user_phone"){
                  return(
                    <TableRow key={labelCellId} >
                      <TableCell component="th" scope="row">
                        {cell.label}
                      </TableCell>
                      <TableCell key={labelCellId}  component="th" scope="row">
                        <Typography style={{ wordWrap: "break-word" }} variant="body2">{props.selectedItem["user"].phone}</Typography>
                      </TableCell>
                    </TableRow>
                    )
                }
                else if(cell.id === "redeem"){
                  return(
                    <TableRow key={labelCellId} >
                      <TableCell component="th" scope="row">
                        {cell.label}
                      </TableCell>
                      <TableCell key={labelCellId}  component="th" scope="row">
                        <Typography style={{ wordWrap: "break-word" }} variant="body2">{props.selectedItem[cell.id] ? "Enabled": "Disabled"}</Typography>
                      </TableCell>
                    </TableRow>
                    )
                }
                else if(cell.id === "payment_method"){
                  return(
                    <TableRow key={labelCellId} >
                      <TableCell component="th" scope="row">
                        {cell.label}
                      </TableCell>
                      <TableCell key={labelCellId}  component="th" scope="row">
                        <Typography style={{ wordWrap: "break-word" }} variant="body2">{props.selectedItem[cell.id] ? renderPaymentMethod(props.selectedItem[cell.id]): "none"}</Typography>
                      </TableCell>
                    </TableRow>
                    )
                }
                else if(cell.id === "cashier"){
                  return(
                    <TableRow key={labelCellId} >
                      <TableCell component="th" scope="row">
                        {cell.label}
                      </TableCell>
                      <TableCell key={labelCellId}  component="th" scope="row">
                        <Typography style={{ wordWrap: "break-word" }} variant="body2">{props.selectedItem[cell.id] ? props.selectedItem[cell.id].id: ""}</Typography>
                      </TableCell>
                    </TableRow>
                    )
                }
                else if(cell.id === "checkout_created"){
                  return(
                    <TableRow key={labelCellId} >
                      <TableCell component="th" scope="row">
                        {cell.label}
                      </TableCell>
                      <TableCell key={labelCellId}  component="th" scope="row">
                      <Typography variant="body2">{moment.unix(props.selectedItem[cell.id]).format("DD/MM/YYYY")}</Typography>
                      </TableCell>
                    </TableRow>
                    )
                }
                else if(cell.id === "document"){

                  let document = [];

                  if(props.selectedItem[cell.id]){
                    props.selectedItem[cell.id].forEach(val =>{
                      document.push(<Grid><Link target="_blank" href={val.url} >
                      {val.name}
                    </Link></Grid>)
                    })
                  }
                  
                  
                  return(
                    <TableRow key={labelCellId} >
                      <TableCell component="th" scope="row">
                        {cell.label}
                      </TableCell>
                      <TableCell key={labelCellId}  component="th" scope="row">
                      {document}
                      </TableCell>
                    </TableRow>
                    )
                }

                else if(cell.id === "customer_point"){
                  return(
                    <TableRow key={labelCellId} >
                      <TableCell component="th" scope="row">
                        {cell.label}
                      </TableCell>
                      <TableCell key={labelCellId}  component="th" scope="row">
                        <Typography style={{ wordWrap: "break-word" }} variant="body2">{props.selectedItem[cell.id] ? props.selectedItem[cell.id]: "0"}</Typography>
                      </TableCell>
                    </TableRow>
                    )
                }

                else if(cell.id === "claimed"){
                  return(
                    <TableRow key={labelCellId} >
                      <TableCell component="th" scope="row">
                        {cell.label}
                      </TableCell>
                      <TableCell key={labelCellId}  component="th" scope="row">
                        <Typography style={{ wordWrap: "break-word" }} variant="body2">{props.selectedItem[cell.id] ? "Yes": "No"}</Typography>
                      </TableCell>
                    </TableRow>
                    )
                }

                else if(cell.id === "credit_note_amount" || cell.id === "credit_note_amount_cost"){
                  return(
                    <TableRow key={labelCellId} >
                      <TableCell component="th" scope="row">
                        {cell.label}
                      </TableCell>
                      <TableCell key={labelCellId}  component="th" scope="row">
                        <Typography style={{ wordWrap: "break-word" }} variant="body2">{props.selectedItem[cell.id] ? props.selectedItem[cell.id]: 0}</Typography>
                      </TableCell>
                    </TableRow>
                    )
                }

                else if(cell.id === "products"){

                  if(props.selectedItem[cell.id]){
                    var _products = [];

                    

                    return(
                    <TableRow key={labelCellId} >
                      <TableCell component="th" scope="row">
                        {cell.label}
                      </TableCell>
                      <TableCell key={labelCellId}  component="th" scope="row">
                        {props.selectedItem[cell.id].map(val => {
                          return(
                            <Typography style={{ wordWrap: "break-word" }} variant="body2">{val.name + " x " + val.products_quantity}</Typography>
                          )
                        })}
                      </TableCell>
                    </TableRow>
                    )
                  }
                  
                  return(
                    <TableRow key={labelCellId} >
                      <TableCell component="th" scope="row">
                        {cell.label}
                      </TableCell>
                      <TableCell key={labelCellId}  component="th" scope="row">
                        <Typography style={{ wordWrap: "break-word" }} variant="body2">{"-"}</Typography>
                      </TableCell>
                    </TableRow>
                    )
                }

                else if(cell.id === "checkout_amount" || 
                cell.id === "checkout_discount" || 
                cell.id === "total_cost_of_goods_sold" ||  cell.id === "total_credit_note"
                ||  cell.id === "total_credit_note_cost"){

                  var total_amount = 0;

                  props.selectedItem[cell.id].forEach(val => {
                    total_amount += Number(val)
                  })
                  
                  return(
                    <TableRow key={labelCellId} >
                      <TableCell component="th" scope="row">
                        {cell.label}
                      </TableCell>
                      <TableCell key={labelCellId}  component="th" scope="row">
                      <Typography variant="body2">{"RM " + total_amount.toFixed(2)}</Typography>
                      </TableCell>
                    </TableRow>
                    )
                }

                else if(cell.id === "net_turnover" || cell.id === "gross_profit"){

                  return(
                    <TableRow key={labelCellId} >
                      <TableCell component="th" scope="row">
                        {cell.label}
                      </TableCell>
                      <TableCell key={labelCellId}  component="th" scope="row">
                      <Typography variant="body2">{props.selectedItem[cell.id] ? "RM " + props.selectedItem[cell.id].toFixed(2) : "RM 0"}</Typography>
                      </TableCell>
                    </TableRow>
                    )
                }

                return(
                  <TableRow key={labelCellId} >
                    <TableCell component="th" scope="row">
                      {cell.label}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Grid style={{overflow: "hidden", maxWidth: 200}} >
                        <Typography style={{ wordWrap: "break-word" }} variant="body2">{props.selectedItem[cell.id]}</Typography>
                      </Grid>
                    </TableCell>
                  </TableRow>
                  
                )
              })}
            </TableBody>
        </Table> : null}

        {props.option === "openFolder" ? renderPage() : null}

        {props.option === "preview" && props.selectedItem.type === "video/mp4" ? 
            <Grid style={{ backgroundColor: "black", padding: 10, display: props.dialogLoading ? "none":"flex", justifyContent: "center", alignItems: "center" }}>
              <ReactPlayer onReady={props.onMediaReady} controls url={props.selectedItem.url}/>
            </Grid>:null}

        {props.option === "preview" && props.selectedItem.type === "image/jpeg" ? 
        <Grid key={props.selectedItem.id}  style={{ height: "100%", width: props.matches ? 450 : 300, display: props.dialogLoading ? "none":"flex", justifyContent: "center", alignItems: "center" }}>
            <img onLoad={props.onMediaReady} style={{ width: "100%", height: "100%", objectFit: 'contain'}} alt={props.selectedItem.name} src={props.selectedItem.url}/>
        </Grid>:null}

        {props.option === "preview_proof_of_payment" ? 
        <Grid key={props.selectedItem.id}  style={{ height: "100%", display: props.dialogLoading ? "none":"flex", justifyContent: "center", alignItems: "center", padding: 10 }}>
            {props.selectedItem.proof_of_payment ? <Carousel style={{ width: "100%" }}>
                  {Object.keys(props.selectedItem.proof_of_payment).map((val, index) => {
                  return <img onLoad={props.onMediaReady} style={{ width: "100%", height: "100%", objectFit: 'contain'}} 
                  alt={props.selectedItem.proof_of_payment[val].name} src={props.selectedItem.proof_of_payment[val].url}/>} )}
                
            </Carousel> : null}
        </Grid>:null}

        {props.option=== "check_out_receipt" ? <Grid>
            <CheckOutDetails content={props.selectedItem} currentStaff={props.currentStaff}/>
        </Grid>: null}

        
        

        {props.option=== "searchWarranty" ? <Grid >
            {warrantyInfo ? claimWarrantyPage ?
            <Grid style={{ padding: 20, minWidth: 800 }}>
              <Typography color="primary" style={{ fontWeight: 'bold' }}>{"Are you sure want to Claim now?"}</Typography>
              <Grid style={{ marginTop: 20 }}>
                <Button variant="outlined" style={{ fontSize: 14, fontWeight: 'bold'}} onClick={()=> setClaimWarrantyPage(false)} color="primary">
                {"No"}
                </Button>
                <Button disableElevation variant="contained" style={{ fontSize: 14, fontWeight: 'bold', marginLeft: 10 }} onClick={()=> handleAgreeWarranty()} color="primary" autoFocus>
                {"Yes"}
                </Button>
              </Grid>
            </Grid>
              :
            <Grid container style={{ padding: 20, minWidth: 800 }}>
              <Grid item xs={6}>
                <Typography color="primary" style={{ fontWeight: 'bold' }}>{"Warranty ID "}</Typography>
                <Typography color="primary" style={{ fontWeight: 'bold', marginTop: 10 }}>{"Registered Date "}</Typography>
                <Typography color="primary" style={{ fontWeight: 'bold', marginTop: 10 }}>{"Expired Date "}</Typography>
                <Typography color="primary" style={{ fontWeight: 'bold', marginTop: 10 }}>{"Customer ID "}</Typography>
                <Typography color="primary" style={{ fontWeight: 'bold', marginTop: 10 }}>{"Customer Phone "}</Typography>
                <Typography color="primary" style={{ fontWeight: 'bold', marginTop: 10 }}>{"Store ID "}</Typography>
                <Typography color="primary" style={{ fontWeight: 'bold', marginTop: 10 }}>{"Claimed"}</Typography>
                <Typography color="primary" style={{ fontWeight: 'bold', marginTop: 10 }}>{"Claimed Date"}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography style={{ fontWeight: 'bold' }}>{warrantyInfo.id}</Typography>
                <Typography style={{ fontWeight: 'bold', marginTop: 10 }}>{new Intl.DateTimeFormat('en-GB', {year: 'numeric',day: '2-digit',  month: 'long' ,hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true })
                        .format(warrantyInfo.created)}</Typography>
                <Typography style={{ fontWeight: 'bold', marginTop: 10 }}>{moment(warrantyInfo.created).add(365, "days").format("DD-MM-YYYY")}</Typography>
                <Typography style={{ fontWeight: 'bold', marginTop: 10 }}>{warrantyInfo.customer_id}</Typography>
                <Typography style={{ fontWeight: 'bold', marginTop: 10 }}>{warrantyInfo.phone}</Typography>
                <Typography style={{ fontWeight: 'bold', marginTop: 10 }}>{warrantyInfo.store_id}</Typography>
                <Typography style={{ fontWeight: 'bold', marginTop: 10 }}>{warrantyInfo.claimed ? "Yes": "No"}</Typography>
                <Typography style={{ fontWeight: 'bold', marginTop: 10 }}>{warrantyInfo.claimed_date ? new Intl.DateTimeFormat('en-GB', {year: 'numeric',day: '2-digit',  month: 'long' ,hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true })
                        .format(warrantyInfo.claimed_date) : "-"}</Typography>
              </Grid>
              <Grid item xs={12}>
              <Button disableElevation  onClick={()=> setClaimWarrantyPage(true)}
              disabled={warrantyInfo.claimed}
              fullWidth variant="contained" style={{ marginTop: 20, fontSize: 14, fontWeight: 'bold'}} color="primary" autoFocus>
                    {"Claim Now"}
              </Button>
                </Grid>
            </Grid>
            :
            isCamera ?<Grid style={{ width: "100%"}}>
              {renderCamera()}
            </Grid>
              :
            <Grid style={{ padding: 20 }}>
             <Grid style={{ display: "flex", flexDirection: "row", width: "100%" }} >
                <Grid  style={{ padding: 10 }}>
                    <IconButton onClick={()=> setIsCamera(true)} color="primary" aria-label="scan qr" component="span">
                        <PhotoCamera />
                    </IconButton>
                </Grid>
                <TextField
                  label={"Warranty ID"}
                  type={'number'}
                  style={{ width: "100%" }}
                  value={searchItem}
                  onChange={(e)=> setSearchItem(e.target.value)}
                  variant="outlined"/>
              </Grid>

              <Button disableElevation onClick={()=> handleSearchWarranty()}
              fullWidth variant="contained" style={{ marginTop: 20, fontSize: 14, fontWeight: 'bold'}} color="primary" autoFocus>
                    {"Search"}
              </Button>
              {notFoundID? <Typography color="secondary" variant='body2' style={{ marginTop: 10 }}>{"ID Not Found"}</Typography> : null}
            </Grid>}

        </Grid>: null}

        {props.option=== "searchCustomer" ? 
        <Grid style={{ display: "flex", justifyContent: "center"}}>
              <SearchCustomer/>
        </Grid>:null }
        
        {props.option === "edit" && !props.customizeDialog ? 
          <Grid container style={{  paddingBottom: 10, display: "flex", flexDirection: "row" }}>
            {props.editCells.map((cell, index)=>{ 
              const labelCellId = `edit-cell-${index}`;
              var sizeMd = 6;

              if(index === props.editCells.length - 1){
                if((index+1) % 2 !== 0){
                  sizeMd = 12;
                } 
              }

              return(
                <Grid key={labelCellId} xs={12} md={sizeMd} style={{  padding: 10, display: "flex", justifyContent: "center", alignItems: "center"}} item >
                  <TextField
                    key={"textfield-input-"+index}
                    label={cell.label}
                    style={{ width: "100%"}}
                    value={props.selectedItem[cell.id]}
                    onChange={(e)=>props.onChangeHandle(e, cell.id)}
                    variant="outlined"/></Grid>
                    
                  )}) }
          </Grid>
        : null}

        {props.option === "addItem" && !props.customizeDialog ? 
          <Grid container style={{ paddingBottom: 10, display: "flex", flexDirection: "row" }}>
            {props.addCells ? props.addCells.map((cell, index)=>{ 
              const labelCellId = `add-cell-${index}`;
              var sizeMd = 6;

              if(index === props.addCells.length - 1){
                if((index+1) % 2 !== 0){
                  sizeMd = 12;
                } 
              }

              if(cell.id === "description"){
                return (<Grid key={labelCellId} xs={12} md={sizeMd} style={{  padding: 10, display: "flex", justifyContent: "center", alignItems: "center"}} item >
                          <TextField
                            key={"textfield-input-"+index}
                            label={cell.label}
                            style={{ width: "100%"}}
                            multiline
                            rows={4}
                            value={props.addItem[cell.id]}
                            onChange={(e)=>props.onChangeAddItemHandle(e, cell.id)}
                            variant="outlined"/></Grid>
                    )
              }
              
              return(   
                <Grid key={labelCellId} xs={12} md={sizeMd} style={{  padding: 10, display: "flex", justifyContent: "center", alignItems: "center"}} item >
                  <TextField
                    key={"textfield-input-"+index}
                    label={cell.label}
                    style={{ width: "100%"}}
                    value={props.addItem[cell.id]}
                    onChange={(e)=>props.onChangeAddItemHandle(e, cell.id)}
                    variant="outlined"/></Grid>
                  )}):null }
                </Grid>
        : null}

        {props.option === "addItem" && props.customizeDialog ? <Grid style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><props.customizeDialog {...props}/></Grid> : null }
        {props.option === "edit" && props.customizeDialog ? <Grid style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><props.customizeDialog {...props}/></Grid> : null }
        {props.dialogLoading ? 
        <Grid style={{ padding: 40, display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress 
              disableShrink
              color="primary" />
        </Grid> : null}

        

        {!props.dialogLoading && props.option === "move"? props.folderData ? 
        <Grid>
        <ListItem button style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)', borderTop: '1px solid rgba(0, 0, 0, 0.1)', width: "100%" }} key={"ListItem-null"} onClick={()=>props.selectFolder(null)} >
          <Typography color="primary" style={{ fontWeight: 'bold' }}>Default</Typography>
        </ListItem>
        {props.folderData.sort((a, b) => (a.name < b.name ? -1 : 1)).map((cell, index)=>{
          return(
            <ListItem button style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)',  width: "100%"}} key={"ListItem-"+index} onClick={()=>props.selectFolder(cell)} >
              <ListItemText primary={cell.name} />
            </ListItem>
          )
        })}
        </Grid>
        :null : null }

        {!props.dialogLoading && props.option === "moveAll"? props.folderData ? 
        <Grid>
        <ListItem button style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)', borderTop: '1px solid rgba(0, 0, 0, 0.1)', width: "100%"}} key={"ListItem-null"} onClick={()=>props.selectFolderMoveAll(null)} >
          <Typography color="primary" style={{ fontWeight: 'bold' }}>Default</Typography>
        </ListItem>
        {props.folderData.sort((a, b) => (a.name < b.name ? -1 : 1)).map((cell, index)=>{
          return(
            <ListItem button style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)', width: "100%"}} key={"ListItem-"+index} onClick={()=>props.selectFolderMoveAll(cell)} >
              <ListItemText primary={cell.name} />
            </ListItem>
          )
        })}
        </Grid>
        :null : null }

        {props.component ? props.component : null}

        {props.customizeDialog && (props.option === "addItem" || props.option === "edit") ? null : props.agreeButton ? <DialogActions>
          <Button variant="outlined" style={{ fontSize: 14, fontWeight: 'bold'}} onClick={props.handleClose} color="primary">
          {props.disagreeTxt}
          </Button>
          <Button disableElevation variant="contained" style={{ fontSize: 14, fontWeight: 'bold'}} onClick={props.handleAgree} color="primary" autoFocus>
          {props.agreeTxt}
          </Button>
          
        </DialogActions> : null} 

      </Dialog>
    </div>
  );
}