import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ReactExport from 'react-data-export';
import { selectProductItems } from '../../redux/product/product.selectors'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import firebase from "firebase";
import moment from "moment";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

//tabs
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  datepicker:{
    color: "black",
    background: "white",
  }

})

function Index(props) {

  const [value, setValue] = React.useState(0);
  const [multiDataSet, setMultiDataSet] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [option, setOption] = React.useState("stock");
  const excelInput = React.useRef(null);
  const [dateRange, setDateRange] = React.useState([null, null]);
  const [rowsOriginal, setRowsOriginal] = React.useState([]);

  const handleDownloadStock = () => {
    setOpen(true);
    setOption("stock")
  };

  const handleDownloadSale = () => {
    setOpen(true);
    setOption("sale")
  };

  const handleDownloadClaim = () => {
    setOpen(true);
    setOption("claim")
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleDownloadReport = () => {

    if(option === "stock"){
      var headCell = ["No.", "Product Name", "Stock", "Price","Cost Price"]

      var listItems = {
        columns: [],
        data: [[]]
      }
  
      headCell.forEach(val => {
        listItems.columns.push({ title: "", width: { wpx: val.length * 12 } })
  
        listItems.data[0].push({
          value: val,
          style: {
            alignment: { horizontal: "center" },
            font: { sz: "11", bold: true },
            border: {
              top: { style: "thin", color: { rgb: "FF000000" } },
              bottom: { style: "thin", color: { rgb: "FF000000" } },
              left: { style: "thin", color: { rgb: "FF000000" } },
              right: { style: "thin", color: { rgb: "FF000000" } }
            },
            fill: { patternType: "solid", fgColor: { rgb: "FFffd200" } }
          }
        })
      })
  
      props.productItems.forEach((val, index) => {
        var items_cell = []
  
        headCell.forEach((val2, index2) => {
  
          if(val2 === "No."){
            items_cell.push(
              {
                value: index+1,
                style: {
                  alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                  font: { sz: "11" },
                  border: {
                    top: { style: "thin", color: { rgb: "FF000000" } },
                    bottom: { style: "thin", color: { rgb: "FF000000" } },
                    left: { style: "thin", color: { rgb: "FF000000" } },
                    right: { style: "thin", color: { rgb: "FF000000" } }
                  }
                }
              }
            )
          }
          else if(val2 === "Product Name")
          {
            items_cell.push(
              {
                value: val.name ? val.name : "",
                style: {
                  alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                  font: { sz: "11" },
                  border: {
                    top: { style: "thin", color: { rgb: "FF000000" } },
                    bottom: { style: "thin", color: { rgb: "FF000000" } },
                    left: { style: "thin", color: { rgb: "FF000000" } },
                    right: { style: "thin", color: { rgb: "FF000000" } }
                  }
                }
              }
            )
          }
          else if(val2 === "Stock")
          {
            items_cell.push(
              {
                value: val.in_stock ? val.in_stock : "",
                style: {
                  alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                  font: { sz: "11" },
                  border: {
                    top: { style: "thin", color: { rgb: "FF000000" } },
                    bottom: { style: "thin", color: { rgb: "FF000000" } },
                    left: { style: "thin", color: { rgb: "FF000000" } },
                    right: { style: "thin", color: { rgb: "FF000000" } }
                  }
                }
              }
            )
          }
          else if(val2 === "Cost Price")
          {
            items_cell.push(
              {
                value: val.cost_price ? val.cost_price : "",
                style: {
                  alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                  font: { sz: "11" },
                  border: {
                    top: { style: "thin", color: { rgb: "FF000000" } },
                    bottom: { style: "thin", color: { rgb: "FF000000" } },
                    left: { style: "thin", color: { rgb: "FF000000" } },
                    right: { style: "thin", color: { rgb: "FF000000" } }
                  }
                }
              }
            )
          }
          else{
            items_cell.push(
              {
                value: val.retail_price ? val.retail_price : "",
                style: {
                  alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                  font: { sz: "11" },
                  border: {
                    top: { style: "thin", color: { rgb: "FF000000" } },
                    bottom: { style: "thin", color: { rgb: "FF000000" } },
                    left: { style: "thin", color: { rgb: "FF000000" } },
                    right: { style: "thin", color: { rgb: "FF000000" } }
                  }
                }
              }
            )
          }
          
        })
        
  
        listItems.data.push(items_cell)
      })
  
      setMultiDataSet([listItems])
    }
    else if(option === "sale"){

      var indexNum = 1;
      var headCell = ["No.", "ID", "Product Name", "QTY SOLD", "Total Retail Price", "Total Cost Price", "Gross Profit"]

      var listItems = {
        columns: [],
        data: [[]]
      }
  
      headCell.forEach(val => {

        if(val === "ID"){
          listItems.columns.push({ title: "", width: { wpx: 200 } })
        }
        else if(val === "Product Name"){
          listItems.columns.push({ title: "", width: { wpx: 300 } })
        }
        else{
          listItems.columns.push({ title: "", width: { wpx: val.length * 12 } })
        }
        
  
        listItems.data[0].push({
          value: val,
          style: {
            alignment: { horizontal: "center" },
            font: { sz: "11", bold: true },
            border: {
              top: { style: "thin", color: { rgb: "FF000000" } },
              bottom: { style: "thin", color: { rgb: "FF000000" } },
              left: { style: "thin", color: { rgb: "FF000000" } },
              right: { style: "thin", color: { rgb: "FF000000" } }
            },
            fill: { patternType: "solid", fgColor: { rgb: "FFffd200" } }
          }
        })
      })

      let group = {};

      rowsOriginal.forEach(val=>{
       
        if(!val.hide){
          if(val.all_products)
          {
            val.all_products.forEach(item => {
              
              if(group[item.id] && group[item.id].id === item.id){
                  group[item.id].quantity += Number(item.quantity);
                  group[item.id].total_cost_price += (Number(item.cost_price)*Number(item.quantity));
                  group[item.id].total_retail_price += (Number(item.retail_price)*Number(item.quantity));
                  group[item.id].product_gross_profit = group[item.id].total_retail_price - group[item.id].total_cost_price;
              }
              else
              {
                  group[item.id] = {...item};
                  group[item.id].quantity = Number(item.quantity);
                  group[item.id].total_cost_price = (Number(item.cost_price)*Number(item.quantity));
                  group[item.id].total_retail_price =   (Number(item.retail_price)*Number(item.quantity));
                  group[item.id].product_gross_profit = group[item.id].total_retail_price - group[item.id].total_cost_price;
              }
      
              })
          }
          
        }

      })

     
      const _rowsOriginal = Object.values(group);
      //console.log(_rowsOriginal)
      _rowsOriginal.forEach((val, index) => {

        var items_cell = []
  
        headCell.forEach((val2, index2) => {
  
          if(val2 === "No."){
            items_cell.push(
              {
                value: indexNum++,
                style: {
                  alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                  font: { sz: "11" },
                  border: {
                    top: { style: "thin", color: { rgb: "FF000000" } },
                    bottom: { style: "thin", color: { rgb: "FF000000" } },
                    left: { style: "thin", color: { rgb: "FF000000" } },
                    right: { style: "thin", color: { rgb: "FF000000" } }
                  }
                }
              }
            )
          }
          else if(val2 === "ID")
          {
            items_cell.push(
              {
                value: val.id,
                style: {
                  alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                  font: { sz: "11" },
                  border: {
                    top: { style: "thin", color: { rgb: "FF000000" } },
                    bottom: { style: "thin", color: { rgb: "FF000000" } },
                    left: { style: "thin", color: { rgb: "FF000000" } },
                    right: { style: "thin", color: { rgb: "FF000000" } }
                  }
                }
              }
            )
          }

          else if(val2 === "Product Name")
          {
            items_cell.push(
              {
                value: val.name,
                style: {
                  alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                  font: { sz: "11" },
                  border: {
                    top: { style: "thin", color: { rgb: "FF000000" } },
                    bottom: { style: "thin", color: { rgb: "FF000000" } },
                    left: { style: "thin", color: { rgb: "FF000000" } },
                    right: { style: "thin", color: { rgb: "FF000000" } }
                  }
                }
              }
            )
          }
          else if(val2 === "QTY SOLD")
          {
            items_cell.push(
              {
                value: val.quantity,
                style: {
                  alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                  font: { sz: "11" },
                  border: {
                    top: { style: "thin", color: { rgb: "FF000000" } },
                    bottom: { style: "thin", color: { rgb: "FF000000" } },
                    left: { style: "thin", color: { rgb: "FF000000" } },
                    right: { style: "thin", color: { rgb: "FF000000" } }
                  }
                }
              }
            )
          }
          else if(val2 === "Total Retail Price")
          {
            items_cell.push(
              {
                value: val.total_retail_price,
                style: {
                  alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                  font: { sz: "11" },
                  border: {
                    top: { style: "thin", color: { rgb: "FF000000" } },
                    bottom: { style: "thin", color: { rgb: "FF000000" } },
                    left: { style: "thin", color: { rgb: "FF000000" } },
                    right: { style: "thin", color: { rgb: "FF000000" } }
                  }
                }
              }
            )
          }
          else if(val2 === "Total Cost Price")
          {
            items_cell.push(
              {
                value: val.total_cost_price,
                style: {
                  alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                  font: { sz: "11" },
                  border: {
                    top: { style: "thin", color: { rgb: "FF000000" } },
                    bottom: { style: "thin", color: { rgb: "FF000000" } },
                    left: { style: "thin", color: { rgb: "FF000000" } },
                    right: { style: "thin", color: { rgb: "FF000000" } }
                  }
                }
              }
            )
          }
          else if(val2 === "Gross Profit")
          {
            items_cell.push(
              {
                value: val.product_gross_profit,
                style: {
                  alignment: { horizontal: "center", vertical: "center", wrapText: "true" },
                  font: { sz: "11" },
                  border: {
                    top: { style: "thin", color: { rgb: "FF000000" } },
                    bottom: { style: "thin", color: { rgb: "FF000000" } },
                    left: { style: "thin", color: { rgb: "FF000000" } },
                    right: { style: "thin", color: { rgb: "FF000000" } }
                  }
                }
              }
            )
          }
          
        })
        
  
        listItems.data.push(items_cell)

        
        
      })
  
      setMultiDataSet([listItems])

    }

    setTimeout(
      () => excelInput.current.click(),
      1000
    );

    setOpen(false);
  }

  const onChangeRangePicker = (e) => {
    if(e){
      rowsOriginal.forEach(val => {

        if(val.checkout_created_timestamp){
          if(val.checkout_created_timestamp >= moment(e[0]).unix() && val.checkout_created_timestamp <= moment(e[1]).unix()){
            val.hide = false
          }
          else{
            val.hide = true
          }
        }
        
      })
      
    }
    else{
      rowsOriginal.forEach(val => {

        val.hide = false
        
      })
    }
    setDateRange(e)
  }

  useEffect(() => {

    
    let itemRef = firebase.database().ref("pos/" + firebase.auth().currentUser.displayName + "/orders");

      itemRef.on('value', snapshot => {
        if(snapshot.exists()){

          let items = [];
          
          snapshot.forEach(child => {
            var itemsVal = child.val();

            var itemDate = new Intl.DateTimeFormat('en-GB', {year: 'numeric',day: '2-digit',  month: 'long' }).format(itemsVal.created)
            itemsVal.created = itemDate;

            items.push(itemsVal)

          })

          let creditNoteRef = firebase.database().ref("pos/" + firebase.auth().currentUser.displayName + "/credit_note");

          creditNoteRef.on('value', snapshot => {

            let items_cn = [];

            snapshot.forEach(child => {
              var itemsVal = child.val();
              var itemDate = new Intl.DateTimeFormat('en-GB', {year: 'numeric',day: '2-digit',  month: 'long' }).format(itemsVal.created)
              itemsVal.created = itemDate;
              items_cn.push(itemsVal)
            })

            // Create a map for the dates
          let dataMap = {};

          
          
          items.forEach((el, index) => {
            
            
            let tempDate = el.created;
            if (tempDate in dataMap) {
              
              dataMap[tempDate].checkout_amount.push(el.amount);
              dataMap[tempDate].checkout_sub_total.push(el.subtotal);
              dataMap[tempDate].payment_method.push(el.payment_method);
              dataMap[tempDate].cash.push(el.payment_method === "cash" ? el.amount : 0);
              dataMap[tempDate].debit_card.push(el.payment_method === "debit_card" ? el.amount : 0);
              dataMap[tempDate].credit_card.push(el.payment_method === "credit_card" ? el.amount : 0);
              dataMap[tempDate].ewallet.push(el.payment_method === "ewallet" ? el.amount : 0);
              dataMap[tempDate].online_transfer.push(el.payment_method === "online_transfer" ? el.amount : 0);
              dataMap[tempDate].gift.push(el.payment_method === "gift" ? el.amount : 0);

              dataMap[tempDate].total_invoice =  dataMap[tempDate].total_invoice ? dataMap[tempDate].total_invoice+1 : 1;
              dataMap[tempDate].checkout_discount.push(el.waiver ? Number(el.waiver) : 0);
              dataMap[tempDate].total_cost_of_goods_sold.push(el.cost_amount ? Number(el.cost_amount) : 0);
              dataMap[tempDate].redeemed_point.push(el.product_use_point? Number(el.product_use_point) : 0)

              if(el.products){
                el.products.forEach((item)=>{
                  dataMap[tempDate].all_products.push(item)
                })
              }
              
            } else {

              dataMap[tempDate] = {
                checkout_created_timestamp: moment(tempDate).unix(),
                checkout_created: moment(tempDate).unix(),
                checkout_amount: [el.amount],
                checkout_sub_total: [el.subtotal],
                total_invoice: 1,
                checkout_discount: [Number(el.waiver)],
                total_credit_note: [],
                net_turnover: 0,
                total_cost_of_goods_sold: [el.cost_amount ? Number(el.cost_amount) : 0],
                total_credit_note_cost: [],
                gross_profit: 0,
                all_products: [],
                redeemed_point: [el.product_use_point ? Number(el.product_use_point) : 0],
                store_name: el.store_name ? el.store_name : "",
                company_name: el.company_name ? el.company_name : "",
                ssm_id: el.ssm_id ? el.ssm_id : "",
                store_address: el.store_address ? el.store_address : "",
                payment_method: [el.payment_method],
                cash: [el.payment_method === "cash" ? el.amount : 0],
                debit_card: [el.payment_method === "debit_card" ? el.amount : 0],
                credit_card: [el.payment_method === "credit_card" ? el.amount : 0],
                ewallet: [el.payment_method === "ewallet" ? el.amount : 0],
                online_transfer: [el.payment_method === "online_transfer" ? el.amount : 0],
                gift: [el.payment_method === "gift" ? el.amount : 0],
              };

              if(el.products){
                el.products.forEach((item)=>{
                  dataMap[tempDate].all_products.push(item)
                })
              }

            }
            
            var total_amount = 0;

              dataMap[tempDate].checkout_sub_total.forEach(val => {
                total_amount += Number(val)
              })

              var total_discount = 0;

              dataMap[tempDate].checkout_discount.forEach(val => {
                total_discount += Number(val)
              })

              var total_cost = 0;

              dataMap[tempDate].total_cost_of_goods_sold.forEach(val => {
                total_cost += Number(val)
              })

              var _total_point = 0;

              dataMap[tempDate].redeemed_point.forEach(val => {
                _total_point += Number(val)
              })

              dataMap[tempDate].net_turnover = total_amount - total_discount;

              
              dataMap[tempDate].gross_profit = dataMap[tempDate].net_turnover - total_cost;
              dataMap[tempDate].total_point = _total_point;
              
          });

          items_cn.forEach((el, index) => {
            
            let tempDate = el.created;
            if (tempDate in dataMap) {
              dataMap[tempDate].total_credit_note.push(el.credit_note_amount);
              dataMap[tempDate].total_credit_note_cost.push(el.credit_note_amount_cost);
            }
            else
            {
              dataMap[tempDate] = {
                checkout_created_timestamp: moment(tempDate).unix(),
                checkout_created: moment(tempDate).unix(),
                checkout_amount: el.checkout_amount ?  el.checkout_amount : [],
                total_invoice: el.total_invoice ?  el.total_invoice : 0,
                checkout_discount: el.checkout_discount ?  el.checkout_discount : [],
                total_credit_note: [el.credit_note_amount ? Number(el.credit_note_amount) : 0],
                net_turnover:  el.net_turnover ?  el.net_turnover : 0,
                total_cost_of_goods_sold: el.total_cost_of_goods_sold ?  el.total_cost_of_goods_sold : [],
                total_credit_note_cost: [el.credit_note_amount_cost ? Number(el.credit_note_amount_cost) : 0],
                gross_profit: el.gross_profit ?  el.gross_profit : 0
              };
            }

            
           
          })

          let data = [];

          // Fill the data
          Object.keys(dataMap).forEach((el) => data.push(dataMap[el]));

          data.forEach((el, index) => {

           
            var total_credit_note_cost = 0;
            el.total_credit_note_cost.forEach(val => {
              total_credit_note_cost += Number(val)
            })
            el.gross_profit = el.gross_profit - total_credit_note_cost
            
            var total_credit_note = 0;
            el.total_credit_note.forEach(val => {
              total_credit_note += Number(val)
            })

            el.net_turnover = el.net_turnover - total_credit_note;
            

            var total_cash = 0;
            var total_debit_card = 0;
            var total_credit_card = 0;
            var total_ewallet = 0;
            var total_online_transfer = 0;
            var total_gift = 0;

            if(el.cash)
            {
              el.cash.forEach(val => {
                total_cash += Number(val)
              })
              el.total_cash = total_cash;
            }
            
            if(el.debit_card)
            {
              el.debit_card.forEach(val => {
                total_debit_card += Number(val)
              })
              el.total_debit_card = total_debit_card;
            }

            if(el.credit_card)
            {
              el.credit_card.forEach(val => {
                total_credit_card += Number(val)
              })
              el.total_credit_card = total_credit_card;
            }
            
            if(el.ewallet)
            {
              el.ewallet.forEach(val => {
                total_ewallet += Number(val)
              })
              el.total_ewallet = total_ewallet;
            }
            
            if(el.online_transfer)
            {
              el.online_transfer.forEach(val => {
                total_online_transfer += Number(val)
              })
              el.total_online_transfer = total_online_transfer;
            }
            

            if(el.gift)
            {
              el.gift.forEach(val => {
                total_gift += Number(val)
              })
              el.total_gift = total_gift;
            }

            

          })
            setRowsOriginal(data)

          })
          
          
          
        }
        else
        {
          setRowsOriginal([])
        }
      })


  }, []);

    return (
      <div className={props.classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Download Report"}</DialogTitle>
        <DialogContent style={{ height: 300 }}>
          {option === "sale" ? <Grid>
          <DialogContentText id="alert-dialog-description" style={{ marginTop: 10 }}>
            Select Date
          </DialogContentText>
          <DateRangePicker className={props.classes.datepicker} onChange={onChangeRangePicker} value={dateRange} />
          </Grid> : null}
          <DialogContentText id="alert-dialog-description" style={{ marginTop: 10 }}>
            Are you sure you want to download this report?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button onClick={handleDownloadReport} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <ExcelFile element={<Button style={{ display: "none" }} ref={excelInput} >download</Button>} filename={"Report_"+new Date()}>
        <ExcelSheet dataSet={multiDataSet} name={"stock"} />
      </ExcelFile>
      
        <AppBar
            component="div"
            className={props.classes.secondaryBar}
            color="primary"
            position="static"
            elevation={0}
            style={{ marginTop: 0 }}
        >
            <Tabs value={value} onChange={handleChange} textColor="inherit">
                <Tab textColor="inherit" label="Report"  {...a11yProps(0)}/>
            </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <Grid style={{ padding: 20 }}>
         
            <Grid container>
                <Grid item xs={12} md={12} style={{ marginTop: 10 }}>
                    <Button
                      style={{ textTransform: 'none' }}
                      color="primary"
                      variant="contained"
                      disableElevation
                      onClick={handleDownloadStock}
                    >
                    Stock Report
                    </Button>
                </Grid>
                <Grid item xs={12} md={12} style={{ marginTop: 10 }}>
                  <Button
                      style={{ textTransform: 'none' }}
                      color="primary"
                      variant="contained"
                      disableElevation
                      onClick={handleDownloadSale}
                    >
                    Sale Report
                    </Button>
                </Grid>
                {/*<Grid item xs={12} md={12} style={{ marginTop: 10 }}>
                  <Button
                      style={{ textTransform: 'none' }}
                      color="primary"
                      variant="contained"
                      disableElevation
                      onClick={handleDownloadClaim}
                    >
                    Claim Report
                    </Button>
                  </Grid>*/}
            </Grid>
            
              
          </Grid>
            
        </TabPanel>
        
      </div>
    );
  
}

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  productItems: selectProductItems,
})

const reportIndex = withStyles(styles, { withTheme: true })(Index);



export default connect(
  mapStateToProps
)(reportIndex);