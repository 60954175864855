import React, { useEffect } from 'react';
import Table from '../../../modules/components/Table'
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddEditOrders from './AddEditOrders';
import firebase from "firebase";

export default function Index(props) {

    const [headCells, setHeadCells] = React.useState([]);
    const [headMobileCells, setHeadMobileCells] = React.useState([]);
    const [searchValue, setSearchValue] = React.useState("");
    const [searchCell, setSearchCell] = React.useState([]);
    const [displayInfo, setDisplayInfo] = React.useState([]);
    const [editCells, setEditCells] = React.useState([]);
    const [addCells, setAddCells] = React.useState([]);
    const [addCellsName, setAddCellsName] = React.useState([]);
    const matches = useMediaQuery(theme => theme.breakpoints.up('sm'));

    useEffect(() => {

        setHeadCells([
            { id: 'uuid', label: 'Tracking Number' },
            { id: 'amount', label: 'Amount' },
            { id: 'payment_method', label: 'Payment Method' },
            { id: 'orders_customer', label: 'Customer' },
            { id: 'created', label: 'Order Date' },
        ])

        setHeadMobileCells([
            { id: 'uuid', label: 'Tracking Number' },
        ])
    
        setSearchCell([
            { id: 'uuid', label: 'Tracking Number' },
            { id: 'card_number', label: 'Card last 4 digits' },
            { id: 'approval_code', label: 'Approval Code' },
        ])

        setDisplayInfo([
            { id: 'id', label: 'ID' },
            { id: 'uuid', label: 'Tracking Number' },
            { id: 'created', label: 'Order Date' },
            { id: 'payment_method', label: 'Payment Method' },
            { id: 'orders_customer', label: 'Customer' },
            { id: 'cashier', label: 'Cashier' },
            { id: 'amount', label: 'Amount' },
            { id: 'card_number', label: 'Card last 4 digits' },
            { id: 'approval_code', label: 'Approval Code' },
        ])

        setEditCells([
            { id: 'payment_method', label: 'Payment Method' },
        ])

        setAddCells([
            { id: 'uuid', label: 'Tracking Number' },
            { id: 'customer', label: 'Customer' },
            { id: 'products', label: 'Products' },
            { id: 'amount', label: 'Amount' },
            { id: 'paid_total', label: 'Total Paid' },
        ])

        setAddCellsName({ 
            created: firebase.database.ServerValue.TIMESTAMP,
            updated: firebase.database.ServerValue.TIMESTAMP,
            status_orders: '',
            orders: {},
            status_orders_serial: 1,
            shipping_address: {},
            billing_address: {},
            payment_gateway: 'cod',
            customer: {},
            products: [],
            amount: '',
            paid_total: '',
            delivery_fee: '',
            handle_by_orders: null
         })
    
        setSearchValue("uuid")
        
    }, []);

    return (
    <Grid>
        <Table 
            {...props}
            customizeDialog={AddEditOrders}
            control={true}
            orderBy="created" 
            hideMove={true}
            hideAllMove={true}
            warrantyButton={false}
            data={"pos/" + firebase.auth().currentUser.displayName + "/orders"}
            addItemButton={false}
            orderReceiptButton={true}
            addItemText="Add Orders"
            addItemIcon={<AddCircleIcon/>}
            addCells={addCells}
            addCellsName={addCellsName}
            folder_data={"pos/" + firebase.auth().currentUser.displayName + "/orders_zone"}
            displayInfo={displayInfo}
            editCells={editCells}
            matches={matches}
            headCells={matches ? headCells : headMobileCells} 
            searchValue={searchValue} 
            searchCell={searchCell}/>
    </Grid>
    )

}
  